import React from 'react'
import { connect } from 'react-redux'
;
import Checkbox from '../../../common/Checkbox'
import SearchField from '../../../common/SearchField'
import Grid from '@mui/material/Grid'
import L from 'leaflet'
import axios from 'axios'
import {
  MapContainer as Map,

  TileLayer,
  FeatureGroup,
  Circle,
  Polygon,
  Polyline,
  ZoomControl
} from 'react-leaflet'
import Scrollbar from 'react-scrollbars-custom'
import { PaginationConfig } from '../../../../Helpers'
import instance from '../../../../axios'

class geofences extends React.Component {
  constructor () {
    super()
    this.state = {
      validatedForm: true,
      groFeneList: {},
      searchText: '',
      pagination: { ...PaginationConfig, pagesize: -1 },
      fitBounds: {},
      minZoom: 3,
      lat: 25.097919,
      lng: 55.236683,
      zoom: 3,
      bounds: null
    }
    this.ajaxRequest = null
  }

  componentWillMount () {
    if (this.props.geoFences && this.props.geoFences.length) {
      this.setState({
        groFeneList: this.props.geoFences
      })
    } else {
      this.fetchData(
        this.props.logInUser.id,
        this.state.pagination.page,
        this.state.pagination.pagesize
      )
    }
  }
  componentWillUnmount () {
    if (this.ajaxRequest) {
      this.ajaxRequest.cancel()
    }
  }
  setBoundOptions () {
    return {}
  }
  searchItem = e => {
    if (this.ajaxRequest) {
      this.ajaxRequest.cancel()
    }

    if (e.target) {
      this.setState(
        {
          searchText: e.target.value
        },
        () => {
          this.fetchData(
            this.props.logInUser.id,
            1,
            this.state.pagination.pagesize
          )
        }
      )
    }
  }

  fetchData = (userId, page, perPage) => {
    this.ajaxRequest = axios.CancelToken.source()
    let searchText = {}
    if (this.state.searchText) {
      searchText = { search: this.state.searchText }
    }

    // axios
    //   .get(`/api/geofences/get`, {
    //     params: { userId, all: true, page, limit: perPage, ...searchText },
    //     cancelToken: this.ajaxRequest.token
    //   })
      instance({
        url: `/api/geofences/get`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params:{
          userId,
          all: true,
          page, 
          limit: perPage, 
          ...searchText
        }
      })
      .then(response => {
        // if (response.status === 200) {
          let { data } = response
          if (data.length) {
            data.map(item => {
              item.visible = false
              return null
            })
          }
          this.setState({
            groFeneList: data,
            page: response.hasNext
              ? response.page + 1
              : response.page
          })
        // }
      })
  }
  handleChange = (event, id) => {
    let { groFeneList } = this.state
    let checkedForAtleastOne = false
    groFeneList.map(item => {
      if (this.props.onlyOneSelection && event.target.checked) {
        if (item.id === id) {
          item.visible = event.target.checked
        } else {
          item.visible = false
        }
      } else {
        if (item.id === id) {
          item.visible = event.target.checked
        }
      }
      if (item.visible) {
        checkedForAtleastOne = true
      }
    })
    this.setState(
      {
        groFeneList: groFeneList
      },
      () => {
        this.updateVisible()
        if (this.props.geofencesHandelChange) {
          this.props.geofencesHandelChange(
            checkedForAtleastOne ? groFeneList : []
          )
        }
      }
    )
  }

  updateVisible () {
    setTimeout(() => {
      let bounds = []
      this.state.groFeneList.map(g => {
        if (g.visible === true && this.state.fitBounds[g.id]) {
          bounds.push(this.state.fitBounds[g.id])
        }
        return null
      })
      if (bounds.length) {
        this.setState({ bounds: bounds })
      }
    }, 50)
  }
  onFeatureGroupAdd = (e, b) => {
    this.setState({
      fitBounds: {
        ...this.state.fitBounds,
        [b]: e.target.getBounds()
      }
    })
  }
  render () {
    let listClassname = this.props.showTwoInList ? 'list-row-1' : 'list-row'
    let { groFeneList } = this.state
    const position = [this.state.lat, this.state.lng]
    let geofences = ''
    if (groFeneList.length) {
      geofences = groFeneList.map(obj => {
        if (obj.attributes.type === 'circle' && obj.visible === true) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              key={obj.id}
            >
              <Circle
                id={obj.id}
                radius={obj.attributes.radius}
                center={obj.attributes.latlng}
                color={obj.attributes.color}
              ></Circle>
            </FeatureGroup>
          )
        } else if (obj.attributes.type === 'polygon' && obj.visible === true) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              key={obj.id}
            >
              <Polygon
                id={obj.id}
                key={obj.id + '__1'}
                positions={obj.attributes.latlng}
                color={obj.attributes.color}
              ></Polygon>
            </FeatureGroup>
          )
        } else if (obj.attributes.type === 'polyline' && obj.visible === true) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              key={obj.id}
            >
              <Polyline
                id={obj.id}
                key={obj.id + '__1'}
                positions={obj.attributes.latlng}
                color={obj.attributes.color}
              ></Polyline>
            </FeatureGroup>
          )
        }

        return ''
      })
    }

    const { columns } = this.props
    let crs = {}
    if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }

    const thisMap = [
      <Map
        key={1}
        className='custom-map-notification'
        ref={this.myRef}
        onZoom={this.checkZoom}
        boundsOptions={this.setBoundOptions}
        bounds={
          this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
        style={{ height: 240, width: '100%' }}
        center={position}
        zoom={this.state.zoom}
        onClick={this.mapEvent}
        {...crs}
      >
        <TileLayer {...this.props.mapLayer} minZoom={this.state.minZoom} />
        {geofences}
      </Map>
    ]

    return (
      <Grid
        container
        spacing={1}
        className={columns ? 'geofences-columns-wise' : ''}
      >
        <Grid item md={4} style={{ minWidth: 300 }} xs={12}>
          <div className='section-head clearfix search-form'>
            <SearchField
              label={this.props.translate('searchGeofence')}
              type='search'
              placeholder='exp: ID, Name'
              value={this.state.searchText}
              onChange={this.searchItem}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </div>
          {groFeneList.length > 0 && (
            <Scrollbar
              style={{ height: this.props.height || 200 }}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableDiv2222115' />
                  )
                }
              }}
            >
              <ul className='geoList'>
                {groFeneList.map(item => {
                  return (
                    <li className={listClassname} key={item.id}>
                      <Checkbox
                        label={item.name}
                        checked={item.visible}
                        onChange={e => this.handleChange(e, item.id)}
                        color='primary'
                      />
                    </li>
                  )
                })}
              </ul>
            </Scrollbar>
          )}
        </Grid>
        <Grid item lg xs={12}>
          <div>{thisMap}</div>
        </Grid>
      </Grid>
    )
  }
}

const mapState = state => {
  return {
    mapLayer: state.mapLayer
  }
}
const mapStateToProps = connect(mapState)
export const GeofencesModal = mapStateToProps(
  ((geofences))
)
