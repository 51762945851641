import React, { Component } from 'react'
import { connect } from 'react-redux'
import { EditItem } from './EditItem'
import CustomDialog from './../common/Dialog'
import { Api, checkPrivileges, errorHandler, selectAll } from './../../Helpers'
;

import Loader from '../../Layout/Loader'
import isEqual from 'react-fast-compare'
import { addDriver, removeDriver, updateDriver } from './../../Actions/Drivers'
import {
  updateDeviceUserWiseSelectedList,
  RemoveDeviceUserWise,
  addDeviceUserWise
} from './../../Actions/Notifications'
import AddDrivers from './addDrivers'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { toast } from 'react-toastify';
import CommonTableSection from './../../Pages/Units/Components/commonTableSection'
import axios from 'axios'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import instance from '../../axios'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations';
import withTranslationWrapper from '../../HOC/withTranslation';

const driverDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId || 0,
  attributes: { ...extAttriubtes },
  name: data.name && data.name.trim(),
  uniqueId: data.uniqueId && data.uniqueId.trim(),
  expirationTime: data.expirationTime || '',
})

const formDefault = {
  name: '',
  uniqueId: '',
  attributes: {},
  driverAddress: ''
}
let uploadFilesArray = []
class driversModal extends Component {
  constructor () {
    super()
    this.state = {
      dm_isVisable: false,
      selectedDriver: '',
      addOption: false,
      editOption: false,
      dr_attriubtes: '',
      isVisableUserBtn: false,
      isAttChanged: false,
      isVisableTrackerModal: false,
      driverId: '',
      form: formDefault,
      itemAttributes: {},
      attributeChangesMessage: '',
      removedIdPropcess: '',
      waslStatus: '',
      driverStatus: '',
      driverFilesData: [],
      uploadDoc: [],
      filesData: []
    }
    this.onCloseSubModal = this.onCloseSubModal.bind(this)
    this.filesDataPrepare = this.filesDataPrepare.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
  }
  componentWillMount () {
    if(this.props.activeOperation === ''){
      this.setState({
        editOption: false,
        addOption: false,
      })
    }
    if (this.props.showItemDetail && this.props.trackersApiResponce) {
      let formData = this.setFormData(this.props.selecteditem)
      let attributes = this.props.selecteditem.attributes
      let trackers = this.props.multiTrackers
      formData = { ...formData, attributes, trackers }
      this.setState({
        selectedDriver: formData,
        driverId: this.props.selecteditem.id
      })
    } else {
      if (this.props.activeOperation === 'edit') {
        this.getDriverFilesData(this.props.selecteditem.id)
        let formData = this.setFormData(this.props.selecteditem)
        let attributes = this.props.selecteditem.attributes
        formData = { ...formData, attributes }
        if (
          !this.state.editOption ||
          !isEqual(this.state.selectedDriver, formData)
        ) {
          this.setState(
            {
              driverId: this.props.selecteditem.id,
              selectedDriver: formData,
              itemAttributes: this.props.selecteditem.attributes,
              editOption: true,
              addOption: false,
              dm_isVisable: true,
              attributeChangesMessage: '',
              isVisableUserBtn: false,
              isAttChanged: false,
              dr_attriubtes: this.props.selecteditem.attributes,
              form: { ...formData },
            },
            () => this.checkRequiredFields()
          )
        }
      }
      if (this.props.activeOperation === 'add' && !this.state.addOption) {
        this.setState({
          driverId: this.props.selecteditem.id,
          selectedDriver: '',
          addOption: true,
          editOption: false,
          dm_isVisable: true,
          form: formDefault,
          isVisableUserBtn: false,
          isAttChanged: false,
          attributeChangesMessage: ''
        })
      }
    }
    if (
      this.props.selectedAddress !== '' &&
      this.props.selectedAddress !== undefined
    ) {
      this.setState({
        form: {
          ...this.state.form,
          driverAddress: this.props.selectedAddress
        }
      })
    }
    uploadFilesArray = []
        this.setState({
          driverFilesData: [],
          uploadDoc: [],
          filesData: [],
        })
  }
  componentWillReceiveProps (nextprops) {
    if (nextprops.selecteditem.id !== this.state.driverId) {
      if (nextprops.showItemDetail && nextprops.trackersApiResponce) {
        let formData = this.setFormData(nextprops.selecteditem)
        let attributes = nextprops.selecteditem.attributes
        let trackers = nextprops.multiTrackers
        formData = { ...formData, attributes, trackers }
        this.setState({
          selectedDriver: formData,
          driverId: nextprops.selecteditem.id
        })
      } else {
        if (nextprops.activeOperation === 'edit') {
          let formData = this.setFormData(nextprops.selecteditem)
          let attributes = nextprops.selecteditem.attributes
          formData = { ...formData, attributes }
          if (
            !this.state.editOption ||
            !isEqual(this.state.selectedDriver, formData)
          ) {
            this.setState(
              {
                driverId: nextprops.selecteditem.id,
                selectedDriver: formData,
                editOption: true,
                addOption: false,
                dm_isVisable: true,
                attributeChangesMessage: '',
                isVisableUserBtn: false,
                isAttChanged: false,
                itemAttributes: nextprops.selecteditem.attributes,
                dr_attriubtes: nextprops.selecteditem.attributes,
                form: { ...formData }
              },
              () => this.checkRequiredFields()
            )
          }
        }
        if (nextprops.activeOperation === 'add' && !this.state.addOption) {
          this.setState(
            {
              driverId: nextprops.selecteditem.id,
              selectedDriver: '',
              addOption: true,
              editOption: false,
              dm_isVisable: true,
              form: formDefault,
              isVisableUserBtn: false,
              isAttChanged: false,
              attributeChangesMessage: ''
            },
            () => this.checkRequiredFields()
          )
          if (
            nextprops.selectedAddress !== '' &&
            nextprops.selectedAddress !== undefined
          ) {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  driverAddress: nextprops.selectedAddress
                }
              },
              () => this.checkRequiredFields()
            )
          }
        }
      }
    }
    if (
      nextprops.selectedAddress !== '' &&
      nextprops.selectedAddress !== undefined
    ) {
      this.setState(
        {
          form: {
            ...this.state.form,
            driverAddress: nextprops.selectedAddress
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  setFormData = data => {
    let formData = {
      name: data.name,
      uniqueId: data.uniqueId,
      tracker: data.tracker,
      expirationTime: data.expirationTime || '',
      identityNumber: data.attributes.identityNumber || '',
      dateOfBirthGregorian: data.attributes.dateOfBirthGregorian || '',
      WASLKey: data.attributes.WASLKey || '',
      driverAddress: data.attributes.driverAddress || '',
      email: data.attributes.email || '',
      licenseClass: data.attributes.licenseClass || '',
      licenseNumb: data.attributes.licenseNumb || '',
      licenseExpDate: data.attributes.licenseExpDate || '',
      phone: data.attributes.phone || '',
      department: data.attributes.department || '',
      driverImg: data.attributes.driverImg || ''
    }
    return formData
  }

  submitDataPepration = () => {
    const {form } = this.state
    const att = ["WASLKey","dateOfBirthGregorian","identityNumber","tag_1","tag_2","tag_3","tag_4","tag_5","email","licenseClass","licenseNumb","licenseExpDate","phone","department","driverImg"]
    const attributes = {}
    att.map((key) => {
      if(form[key]){
        attributes[key]= form[key] 
      }
    })
    if (this.props.driverLat) {
      attributes.driverLat= this.props.driverLat
    }
    if (this.props.driverLon) {
      attributes.driverLon= this.props.driverLon
    }
    if (form.driverAddress) {
      attributes.driverAddress= form.driverAddress.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    return attributes
  }
  
  onCloseModal = () => {
    this.setState({
      isVisableTrackerModal: false,
      enableImagePopup: false
    })
  }
  removeDriverFile = (mediaId) => {
    fetch(` /api/uploads/${mediaId}`, {
      method: 'DELETE',
    })
      .then((response) => {
        response.json().then((res) => {
          this.setState({ driverFilesData: res.data }, () => this.getDriverFilesData(this.props.selecteditem.id))
        });
      })
      .catch((e) => {
        this.props.dispatch(
          notifyError("somthingWentWrongMessage")
        );
    });
  }
  getDriverFilesData = (selecteditem) => {
    fetch(`/api/uploads/get?entityId=${selecteditem}&entity=Driver`)
      .then((response) => {
        response.json().then((res) => {
          this.setState({ driverFilesData: res.data })
        });
      })
      .catch((e) => {
        this.props.dispatch(
          // Notifications.error({
          //   message: this.props.translate("somthingWentWrongMessage"),
          //   autoDismiss: 5,
          // })
          notifyError("somthingWentWrongMessage")
        );
    });
  }
  filesDataPrepare = async () => {
    const { waslEnabled } = this.props?.serversettings?.attributes || {};
    if(waslEnabled) {
      this.setState({
        waslStatus: <span className="loading-status-text"> <LoadingIndicator /> Processing</span>,
      })
    }
    this.setState({
      driverStatus: <span className="loading-status-text"><LoadingIndicator /> Processing</span>,
    })
    let { filesData } = this.state

    if (filesData && filesData.length > 0) {
      for(let i=0; i<filesData.length; i++) {
        await instance({
          url: `/api/uploads`,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data: {
            ...filesData[i]
          }
        })
        .then(res => {
          console.log("uploads ===", res)
        }).catch(error => {errorHandler(error, this.props.dispatch)})
      }
      this.ud_submit(this.state.driverId)
    } else {
      if (this.state.editOption) {
        this.ud_submit(this.state.driverId)
      } else {
        this.ud_submit()
      }
    }
  }

  submitWasl = (form, driverResponse, data) => {
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    if(waslCompanyRefKey) {
      const { identityNumber, phone, dateOfBirthGregorian } = form?.attributes || {};
      // console.log(form);
      if(identityNumber && phone && dateOfBirthGregorian) {
        const formData = {
          identityNumber,
          phone,
          activity: 'DEFAULT'
        }
        if(form.id) {
          formData.dateOfBirthGregorian = dateOfBirthGregorian
        }
        axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
          hook: 'drivers',
          account_id: this.props.serversettings.id,
          data: JSON.stringify(formData),
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          if(response.status === 200 && response.data && response.data.success) {
            if(response.data.success) {
              this.setState({
                waslStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>,
              })
              if(response.data.result && response.data.result.referenceKey) {
                this.handleChange('WASLKey')({ target: { value: response.data.result.referenceKey}});
                this.ud_submit(form.id, true);
              }
              else {
                this.handleAfterResponse(driverResponse, data)
              }
            }
          }    
        }).catch(error => {
          this.setState({
            waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> invalid form</span>,
          })
          let res = error?.response?.data || {}
          let check = res&&res.data&&res.data.result
          if(check&& check.referenceKey && check.isValid){
                this.handleChange('WASLKey')({ target: { value: check.referenceKey}});
                this.ud_submit(form.id, true);
          }
          this.handleAfterResponse(driverResponse, data)
          console.log(error);
        })
      }
      else {
        this.setState({
          waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> incomplete form</span>,
        })
        this.handleAfterResponse(driverResponse, data)
      }
    }
    else {
      this.handleAfterResponse(driverResponse, data)
    }
  } 

  ud_submit = (driverId,disableWaslCall) => {
    const { waslEnabled } = this.props?.serversettings?.attributes || {};
    const id = driverId ? driverId : 0;
    const { form: { name, uniqueId, expirationTime } } = this.state
    let preperData = { name, uniqueId, expirationTime }
    let attriubtes = this.submitDataPepration()
    const obj = driverDataFormat(preperData, attriubtes, id)
    delete obj.attributes.lat
    delete obj.attributes.lng
    delete obj.attributes.driverLngwaslStatus
    if(!obj.attributes.WASLKey) {
      delete obj.attributes.WASLKey;
    }

    if (!obj.attributes.tag_1) delete obj.attributes.tag_1
    if (!obj.attributes.tag_2) delete obj.attributes.tag_2
    if (!obj.attributes.tag_3) delete obj.attributes.tag_3
    if (!obj.attributes.tag_4) delete obj.attributes.tag_4
    if (!obj.attributes.tag_5) delete obj.attributes.tag_5

    const data = {
      id,
      url: id ? `/api/drivers/${id}` : `/api/drivers/`,
      method: id ? `PUT` : `POST`,
      successMsg: id ? `driverIsUpdated` : `driverIsCreated`,
    };

    instance({
      url: data.url,
      method: data.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data :{
        ...obj
      }
    })
        .then(driver => {
          
          if(!disableWaslCall && waslEnabled) {
            this.submitWasl(obj, driver, data);
          }
          else {
            this.handleAfterResponse(driver, data);
          }
    })
    .catch(error => {
      errorHandler(error, this.props.dispatch)
      // if (e && e.text) {
      //   e.text().then(err => {
      //     if (err.includes('Duplicate entry')) {
      //       //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      //       this.props.dispatch(
      //         Notifications.error({
      //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
      //           autoDismiss: 10
      //         })
      //       )
      //     } else {
      //       this.props.dispatch(
      //         Notifications.error({
      //           message: this.props.translate('somthingWentWrongMessage'),
      //           autoDismiss: 10
      //         })
      //       )
      //     }
      //   })
      // }
    })
  }
  
  handleAfterResponse = (response, data) => {
    if(data.id){
      this.props.dispatch(updateDriver(response))
    }else{
      this.props.dispatch(addDriver(response))
    }
    uploadFilesArray = []
    this.props.onCloseModal()
    this.props.fetchMoreItems()
    this.setState({
      itemAttributes: response.attributes,
      dr_attriubtes: response.attributes,
      dm_isVisable: !this.state.dm_isVisable,
      selectedDriver: response,
      attributeChangesMessage: '',
      isVisableUserBtn: false,
      isAttChanged: false,
      driverFilesData: [],
      uploadDoc: [],
      filesData: [],
      driverStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>,
    },
      this.props.dispatch(
        // Notifications.success({
        //   message: this.props.translate(data.successMsg),
        //   autoDismiss: 10
        // })
        notifyError(data.successMsg)

      )
    )
  }

  onCloseSubModal () {
    this.setState({
      dm_isVisable: false,
      addOption: false,
      editOption: false
    })
    this.props.onCloseModal()
  }

  changeAttribute (name, value, selectedOption, updateAtt) {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      this.setState(
        {
          dr_attriubtes: { ...updateAtt }
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      )
    } else {
      this.setState(
        {
          dr_attriubtes: {
            ...this.state.selectedDriver.attributes,
            [name]: value
          }
        },
        () => this.checkForAttrubutesChanged()
      )
    }
  }

  checkForAttrubutesChanged = selectedOption => {
    let changed = false
    if (this.state.selectedDriver) {
      changed = !isEqual(
        this.state.dr_attriubtes,
        this.state.selectedDriver.attributes
      )
    } else {
      changed = !this.isEmpty(this.state.dr_attriubtes)
    }
    let { name, uniqueId } = this.state.form
    let selectedDriver = {}
    if ((name && uniqueId) || (name && uniqueId && changed)) {
      selectedDriver = this.state.dr_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedDriver }
        })
      } else {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedDriver }
        })
      }
    } else {
      selectedDriver = this.state.dr_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState(
          {
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedDriver }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedDriver }
          },
          () => this.checkRequiredFields()
        )
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: 'Change in attributes is pending'
      })
    } else {
      this.setState(
        {
          attributeChangesMessage: ''
        },
        () => this.checkRequiredFields()
      )
    }
  }
  isEmpty = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  handleChange = name => event => {
    const { target } = event
    
    let value = "";

    if (name === 'licenseExpDate' || name === 'dateOfBirthGregorian' || name === 'expirationTime') {
      value = event.toISOString();
      
    } else {
      value = target.value;
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value
        }
      },
      () => this.checkRequiredFields()
    )
  }


  checkRequiredFields () {
    let { name, uniqueId } = this.state.form
    let value = !isEqual(this.state.form, this.state.selectedDriver)
    if (name && uniqueId) {
      this.setState({
        isVisableUserBtn: true
      })
    } else {
      this.setState({
        isVisableUserBtn: this.state.isAttChanged
      })
    }
  }
  setExpirationTime = (e,name) => {
    e.stopPropagation()
    if(name === 'licenseExpDate'){
      this.setState(
        {
          form: {
            ...this.state.form,
              licenseExpDate: null
          }
        },
        () => this.checkRequiredFields()
      )
    }else if(name === 'expirationTime'){
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  deleteWASLKey = () => {
    const id = this.state.driverId ? this.state.driverId : 0;
    const { form: { name, uniqueId, expirationTime } } = this.state
    let preperData = { name, uniqueId, expirationTime }
    let attriubtes = this.submitDataPepration()
    const obj = driverDataFormat(preperData, attriubtes, id)
    delete obj.attributes.WASLKey;
    const data = {
      id,
      url: id ? `/api/drivers/${id}` : `/api/drivers/`,
      method: id ? `PUT` : `POST`,
      successMsg: id ? `driverIsUpdated` : `driverIsCreated`,
    };

    fetch(data.url, {
      method: data.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(driver => {
          this.handleAfterResponse(driver, data);
        })
      } else {
        throw response
      }
    })
  }

  removeFiles = (key) => {
    let updatedFilesData = [...this.state.filesData];
    updatedFilesData.splice(key, 1);
    uploadFilesArray.splice(key, 1);
      this.setState({
        filesData: updatedFilesData,
        uploadDoc: [...uploadFilesArray],
      }, () => this.checkRequiredFields())
  };
  uploadFiles = (e) => {
    const files = e.target.files;
    let prevSavedImages = this.state.driverFilesData ? this.state.driverFilesData : [];
    let prevUploadedImages = this.state.uploadDoc ? this.state.uploadDoc : [];

    if (prevSavedImages.length + prevUploadedImages.length + files.length <= 5) {
        Object.values(files).forEach((item) => {
            if (item.size <= 5 * 1024 * 1024) {
                let reader = new FileReader();

                reader.onload = (e) => {
                    let fileData = e.target.result;

                    if (fileData.split(':')[1].split('/')[0] === 'image' || 
                        fileData.split(':')[1].split('/')[1].toLowerCase().substring(0, 3) === 'pdf' ||
                        fileData.split(':')[1].split('/')[1].toLowerCase().substring(0, 3) === 'vnd') 
                    {
                      uploadFilesArray.push(item.name)
                        let obj = {
                            name: `${item.name}`,
                            contentType: `${item.type}`,
                            oldUrl: fileData,
                            entity: "Driver",
                            entityId: this.props.selecteditem.id,
                            data: `${fileData.split('base64')[1].substring(1)}`,
                        };

                        this.setState((prevState) => ({
                            filesData: [...prevState.filesData, obj],
                            uploadDoc: [...uploadFilesArray],
                        }), () => this.checkRequiredFields());
                    } else {
                        this.props.dispatch(
                            // Notifications.error({
                            //     message: this.props.translate('onlyAcceptImagesDocs'),
                            //     autoDismiss: 10,
                            // })
                            notifyError('onlyAcceptImagesDocs')
                        );
                    }
                };

                reader.readAsDataURL(item);
            } else {
                this.props.dispatch(
                    // Notifications.error({
                    //     message: this.props.translate('fileSize5MB'),
                    //     autoDismiss: 10,
                    // })
                    notifyError("fileSize5MB")
                );
            }
        });
    } else {
        this.props.dispatch(
            // Notifications.error({
            //     message: this.props.translate('fileLimit'),
            //     autoDismiss: 10,
            // })
            notifyError("fileLimit")
        );
    }
  };
  
  // uploadImage = event => {
  //   let files = event.target.files
  //   let imgData = ''
  //   let reader = new FileReader()
  //   if (files && files[0]) {
  //     reader.readAsDataURL(files[0])
  //     reader.onload = e => {
  //       imgData = e.target.result
  //       if (imgData.split(':')[1].split('/')[0] === 'image') {
  //         let obj = {
  //           name: `${this.state.form.uniqueId + new Date().getTime()}`,
  //           contentType: `${files[0].type}`,
  //           oldUrl: `${this.state.form.driverImg}`,
  //           data: `${imgData.split('base64')[1].substring(1)}`
  //         }
  //         this.setState(
  //           {
  //             imagesData: obj,
  //             form: {
  //               ...this.state.form,
  //               driverImg: imgData
  //             }
  //           },
  //           () => {
  //             let { name, uniqueId } = this.state.form
  //             if (name && uniqueId) {
  //               this.setState({
  //                 isVisableUserBtn: true
  //               })
  //             } else {
  //               this.setState({
  //                 isVisableUserBtn: this.state.isAttChanged
  //               })
  //             }
  //           }
  //         )
  //       } else {
  //         //this.props.enqueueSnackbar(this.props.translate('onlyAcceptImage'));
  //         this.props.dispatch(
  //           Notifications.error({
  //             message: this.props.translate('onlyAcceptImage'),
  //             autoDismiss: 10
  //           })
  //         )
  //       }
  //     }
  //   } else {
  //     this.setState(
  //       {
  //         imagesData: '',
  //         form: {
  //           ...this.state.form,
  //           driverImg: this.props.selecteditem.attributes
  //             ? this.props.selecteditem.attributes.driverImg
  //             : ''
  //         }
  //       },
  //       () => {
  //         this.setState({
  //           isVisableUserBtn: this.state.isAttChanged
  //         })
  //       }
  //     )
  //   }
  // }
  imagePopup = (image) => {
    this.setState({
      enableImagePopup: true,
      previewImage: image
    })
  }

  unit_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddevices]
    let obj = {
      deviceId: item.id,
      driverId: this.props.selecteditem.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data :{
        ...obj
      }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(RemoveDeviceUserWise(item))
          this.props.dispatch(
            // Notifications.success({
            //   message: this.props.translate('trackerIsUnAssignedSuccessfully'),
            //   autoDismiss: 10
            // })
            notifyError("trackerIsUnAssignedSuccessfully")

          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(addDeviceUserWise(item))
          this.props.dispatch(
            // Notifications.success({
            //   message: this.props.translate('trackerIsAssignedSuccessfully'),
            //   autoDismiss: 10
            // })
            notifySuccess("trackerIsAssignedSuccessfully")
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  render () {
    const { selecteddevices } = this.props
    return (
      <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
        {this.state.dm_isVisable && this.state.addOption && (
          <AddDrivers
            onClose={this.onCloseSubModal}
            selectedDrivers={this.state.selectedDriver}
            formSubmit={this.filesDataPrepare}
            deleteWASLKey={this.deleteWASLKey}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            buttonText='sharedCreate'
            translate={this.props.translate}
            isVisableUserBtn={this.state.isVisableUserBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            isHaveAccess='driverCreate'
            imagePopup={this.imagePopup}
          />
        )}
        {this.state.selectedDriver && this.state.editOption && (
          <AddDrivers
            onClose={this.onCloseSubModal}
            selectedDrivers={this.state.selectedDriver}
            formSubmit={this.filesDataPrepare}
            deleteWASLKey={this.deleteWASLKey}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            buttonText='update'
            translate={this.props.translate}
            isVisableUserBtn={this.state.isVisableUserBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            serversettings={this.props.serversettings}
            isHaveAccess='driverUpdate'
            uploadFiles={this.uploadFiles}
            imagePopup={this.imagePopup}
            removeFiles={this.removeFiles}
            editOption={this.state.editOption}
            removeDriverFile={this.removeDriverFile}
            uploadDoc={this.state.uploadDoc}
            driverFilesData={this.state.driverFilesData}
          />
        )}
        {this.state.selectedDriver &&
        this.props.showItemDetail &&
        this.props.trackersApiResponce ? (
          <EditItem
            devices={this.props.devices}
            currentLocation={this.props.currentLocation}
            selectedItem={this.state.selectedDriver}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
          />
        ) : !this.state.addOption && !this.state.editOption ? (
          <Loader />
        ) : null}
        {this.state.isVisableTrackerModal && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable
            title={this.props.translate('deviceTitle')}
            onClose={this.onCloseModal}
            isButtonVisable
            bodyPadding={0}
          >
            <div style={{ minHeight: 200 }}>
              <CommonTableSection
                component_Selection={this.unit_Selection}
                themecolors={this.props.themecolors}
                componentData={selecteddevices}
                showCheckbox
                onDelete={this.onRemovedDevice}
                label={this.props.translate('sensorUnits')}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                canUpdate={false}
                rowDefinition={[
                  {
                    id: 'label',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sensorUnits')
                  }
                ]}
                canDelete={checkPrivileges('deviceDelete')}
                canRemove={checkPrivileges('deviceLinkDriver')}
                canAssign={checkPrivileges('deviceUnlinkDriver')}
                selectAll={e =>
                  selectAll(
                    e,
                    'deviceId',
                    'driverId',
                    this.props.selecteditem.id,
                    this.props.selecteddevices,
                    this.props.devices2,
                    this.props.dispatch,
                    'deviceAlt',
                    this.props.translate
                  )
                }
              />
            </div>
          </CustomDialog>
        )}
        {this.state.enableImagePopup && this.state.form.driverImg && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            bodyPadding={10}
            cancelText={this.props.translate('sharedCancel')}
            noFullScreen
          >
            <div style={{ minHeight: 200, margin: 0, textAlign: 'center' }}>
              <img
                style={{ width: '100%', height: 'auto' }}
                alt='driverImage'
                src={this.state.previewImage}
              />
            </div>
          </CustomDialog>
        )}
      </div>
    )
  }
}

const mapState = state => ({
  devices2: state.devices2,
  drivers: state.drivers,
  devices: state.devices.data,
  themecolors: state.themeColors,
  selecteddevices: state.selecteddevices,
  serversettings: state.ServerSetting,
  devices:state.devices
})
const mapStateToProps = connect(mapState)
// export const DriversModal = mapStateToProps(
//   ((driversModal))
// )
export const DriversModal = mapStateToProps(
  (withTranslationWrapper(driversModal))
)

const LoadingIndicator = () => {
return <span className="status-icon">
    <span className="loading-dot" style={{ animationDelay: '0ms' }} />
    <span className="loading-dot" style={{ animationDelay: '160ms' }} />
    <span className="loading-dot" style={{ animationDelay: '320ms' }} />
  </span>
}