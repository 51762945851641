import React from 'react'
import { connect } from 'react-redux';
import { EditItem } from './EditItem'

import AddGroupModal from './addGroupModal2'
import MarkerMap from "../common/Maps"
import CustomDialog from '../common/Dialog'
import { getGroups, addGroup, removeGroup, updateGroup } from '../../Actions/Groups'
import Button from '../common/Button';
import { Trans as Translate} from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css'
import "./groupsModal.scss";
import isEqual from 'react-fast-compare';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import {checkPrivileges, errorHandler} from '../../Helpers'
import { toast } from 'react-toastify';
import instance from '../../axios';
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations';

const userDataFormat = (data, extAttriubtes, itemId) => {
  return {
    'attributes': extAttriubtes ? extAttriubtes : {},
    'id': itemId ? itemId : 0,
    'groupId': data.groupId ? data.groupId : "",
    'name': data.name ? data.name.trim() : ""
  }
}

const formDefaultState = {
  id: "",
  name: '',
  groupId: "",
  attributes: {}
}

class usersModal extends React.Component {
  constructor() {
    super();
    this.state = {
      deleteProcess: false,
      selectedDevice: '',
      addOption: false,
      editOption: false,
      u_attriubtes: '',
      defaultUserList: false,
      selectedOption: false,
      passwordOption: false,
      selectedDeviceTrackesList: '',
      isSelectedDevice: false,
      localTime: new Date(),
      directOption: false,
      isVisableUserBtn: false,
      isAttChanged: false,
      usesId: '',
      isMapModal: false,
      duplicateEntry: false,
      isVisableAddlocBtn: false,
      localPostionObj: '',
      selectedDevices: '',
      usersArray: [],
      itemAttributes: {},
      attributeChangesMessage: '',
      // all fields of form 
      form: {
        ...formDefaultState
      }

    }
    this.au_submit = this.au_submit.bind(this);
    this.uu_submit = this.uu_submit.bind(this);
    // this.editUser = this.editUser.bind(this);
    // this.removeUser = this.removeUser.bind(this);
    this.generateToken = this.generateToken.bind(this)
    this.modalControle = this.modalControle.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.timeDateOnchange = this.timeDateOnchange.bind(this)
    this.userSelection = this.userSelection.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this)
    this.changeAttribute2 = this.changeAttribute2.bind(this)

  }

  componentWillMount() {
    if (this.props.showItemDetail) {
      this.setState({
        selectedDevice: this.props.selecteditem,
      })
    } else {
      if (this.props.activeOperation === 'edit' && (!this.state.editOption || !isEqual(this.state.selectedDevice,this.props.selecteditem))) {
        this.setState({
          selectedDevice: this.props.selecteditem,
          editOption: true,
          addOption: false,
          attributeChangesMessage: '',
          itemAttributes: this.props.selecteditem.attributes,
          isVisableUserBtn: false,
          form: { ...this.props.selecteditem }
        }, () => this.checkRequiredFields())
        this.updateUser(this.props.selecteditem)

      }
      if (this.props.activeOperation === 'delete') {
        this.deleteGroup(this.props.selecteditem)
      }
      if (this.props.activeOperation === 'add' && !this.state.addOption) {
        this.setState({
          selectedDevice: '',
          addOption: true,
          editOption: false,
          attributeChangesMessage: '',
          isVisableUserBtn: false,
          itemAttributes: {},
          form: { ...formDefaultState }
        }, () => this.checkRequiredFields())
      }
    }


  }
  componentWillReceiveProps(nextprops) {

    if (nextprops.showItemDetail) {
      this.setState({
        selectedDevice: nextprops.selecteditem,
      })
    } else {
      if (nextprops.activeOperation === 'edit' && (!this.state.editOption || !isEqual(this.state.selectedDevice,nextprops.selecteditem))) {
        this.setState({
          selectedDevice: nextprops.selecteditem,
          editOption: true,
          addOption: false,
          attributeChangesMessage: '',
          itemAttributes: nextprops.selecteditem.attributes,
          isVisableUserBtn: false,
          form: { ...nextprops.selecteditem }
        }, () => this.checkRequiredFields())
        this.updateUser(nextprops.selecteditem)
      }
      if (nextprops.activeOperation === 'delete' && this.state.deleteProcess === false) {

        this.setState({deleteProcess: true}, ()=> {
          this.deleteGroup(nextprops.selecteditem)          
        })


        
      }
      if (nextprops.activeOperation === 'add' && !this.state.addOption) {
        this.setState({
          selectedDevice: '',
          addOption: true,
          editOption: false,
          attributeChangesMessage: '',
          isVisableUserBtn: false,
          itemAttributes: {},
          form: { ...formDefaultState }
        }, () => this.checkRequiredFields())
      }


    }
  }

  au_submit() {
    const { itemAttributes } = this.state
    let { name } = this.state.form
    if (name) {
      this.setState({
        selectedDevice: '',
        isVisableUserBtn: false,
      });
      const obj = userDataFormat(this.state.form, itemAttributes)
      // fetch(`/api/groups/`, {
      //   method: 'POST',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/groups/`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data :{
          ...obj
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(group => {
              this.props.dispatch(addGroup(group));
              //this.props.enqueueSnackbar(this.props.translate('groupAdded'), { autoHideDuration: 1000 });
              this.props.dispatch(
              //   Notifications.success({
              //   message: this.props.translate('resourceAdded'),
              //   autoDismiss: 10
              // })
            notifySuccess('resourceAdded')

            );
              this.setState({
                addOption: false,
                u_attriubtes: '',
                selectedDevice: '',
                // isVisableUserBtn: true,
                isAttChanged: false,
                attributeChangesMessage: '',
                defaultUserList: true,
                form: {
                  ...formDefaultState
                }
              });

              this.props.onCloseModal();
          //   });

          // }
          // else {
          //   throw response
          // }
        }).catch(error => {errorHandler(error, this.props.dispatch)
          /*e.text().then(err => {
            if (err.includes("Duplicate entry")) {
              this.setState({
                duplicateEntry: true,
                isVisableUserBtn: false
              })
              alert("Unit is Allready in Used")
            }
          })*/
        })

    } else {
      this.setState({
        isVisableUserBtn: false,   isAttChanged: false,
      }, () => {
        //this.props.enqueueSnackbar(this.props.translate('emptyField'), { autoHideDuration: 1000 });
        this.props.dispatch(
        //   Notifications.error({
        //   message: this.props.translate('emptyField'),
        //   autoDismiss: 10
        // })
        notifyError("emptyField")

      );
      })
      
    }

  }
  uu_submit() {
    const { itemAttributes, selectedDevice } = this.state
    let { name } = this.state.form
    if (name.trim().length > 0) {
      let itemId = selectedDevice.id
      const obj = userDataFormat(this.state.form, itemAttributes, itemId)

      // fetch(`/api/groups/${itemId}`, {
      //   method: 'PUT',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/groups/${itemId}`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data :{
          ...obj
        }
      })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(group => {
            this.props.dispatch(updateGroup(group));
            this.userSelection(group)
            if(this.props.setSelectedItem){
              this.props.setSelectedItem(group)
            }
            //this.props.enqueueSnackbar(this.props.translate('groupUpdated'), { autoHideDuration: 1000 });
            this.props.dispatch(
            //   Notifications.success({
            //   message: this.props.translate('resourceUpdated'),
            //   autoDismiss: 10
            // })
            notifySuccess('resourceUpdated')
          
          );
            this.setState({
              selectedDevice: group,
              itemAttributes: itemAttributes,
              u_attriubtes: itemAttributes,
              //editOption: false,
              //u_attriubtes: '',
              //defaultUserList: true,
              //form: '',
              isVisableUserBtn: false,
              isAttChanged: false,
              attributeChangesMessage: ''
            });
            
        //   });
        // }
        // else {
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)
        /*e.text().then(err => {
          if (err.includes("Duplicate entry")) {
            this.setState({
              duplicateEntry: true
            })
            alert("Unit is Allready in Used")
          }
        })*/
      })

    } else {
      this.setState({
        isVisableUserBtn: false,isAttChanged: false,
      })
      //this.props.enqueueSnackbar(this.props.translate('emptyField'), { autoHideDuration: 1000 });
      this.props.dispatch(
      //   Notifications.error({
      //   message: this.props.translate('emptyField'),
      //   autoDismiss: 10
      // })
      notifyError("emptyField")

    );
    }

  }
  deleteGroup(selectedDevice) {

    if (window.confirm(this.props.translate('areYouWantToDelete'))) {

        if (selectedDevice.id) {

          // fetch(`/api/groups/${selectedDevice.id}`, {
          //   method: 'DELETE',
          //   headers: {
          //     'Accept': 'application/json',
          //     'Content-Type': 'application/json',
          //   },
          //   body: JSON.stringify({
          //     ...selectedDevice
          //   })
          // })
          instance({
            url: `/api/groups/${selectedDevice.id}`,
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data :{
              ...selectedDevice
            }
          })
          .then(response => {
            // if (response.ok) {
              this.props.dispatch(removeGroup(selectedDevice));
              //this.props.enqueueSnackbar(this.props.translate('groupDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
              //   Notifications.success({
              //   message: this.props.translate('resourceDeleted'),
              //   autoDismiss: 10
              // })
            notifySuccess('resourceDeleted')

            );
              this.props.onCloseModal();
              this.setState({
                deleteProcess: false,
                selectedDevice: '',
                attributeChangesMessage: ''
              })
            // }
            // else {
            //   throw response
            // }
          }).catch(error => {errorHandler(error, this.props.dispatch)})
          
        }
      }


  }

  changeAttribute(name, value, selectedOption, updateAtt) {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      this.setState({
        u_attriubtes: { ...updateAtt }
      }, () => this.checkForAttrubutesChanged(selectedOption))
    }
    else {
      this.setState({
        u_attriubtes: { ...this.state.selectedDevice.attributes, [name]: value }
      }, () => this.checkForAttrubutesChanged())
    }

  }

  timezone_handleChange = (name, value) => {
    this.setState({
      u_attriubtes: {
        ...this.state.itemAttributes,
          [name]: value.value
        }
    }, () => this.checkForAttrubutesChanged('updateAtt'))
  }

  changeAttribute2 = (name, event) => {

    let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
    this.setState({
      u_attriubtes: {
          ...this.state.itemAttributes,
          [name]: value
        }
      }, () => this.checkForAttrubutesChanged('updateAtt'))

  }


  checkForAttrubutesChanged = (selectedOption) => {
    let changed = false
    if (this.state.selectedDevice) {
      changed = !isEqual(this.state.u_attriubtes, this.state.selectedDevice.attributes)
    } else {
      changed = !this.isEmpty(this.state.u_attriubtes)
    }
    let { name, email } = this.state.form
    let selectedDevice = {}
    if ((name && email) || (name && email && changed)) {
      selectedDevice = this.state.u_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedDevice }
        })
      } else {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedDevice }
        })
      }

    } else {
      selectedDevice = this.state.u_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState({
          isVisableUserBtn: false,
          isAttChanged: false,
          itemAttributes: { ...selectedDevice }
        },() => {
          this.checkRequiredFields()
        })
      } else {
        this.setState({
          isVisableUserBtn: false,
          isAttChanged: false,
          itemAttributes: { ...this.state.itemAttributes, ...selectedDevice }
        },() => {
          this.checkRequiredFields()
        })
      }
    }
    if (changed) {

      this.setState({
          isVisableUserBtn: true,
          isAttChanged: true,
        attributeChangesMessage: 'Change in attributes is pending'
      })
    } else {
      this.setState({
        isVisableUserBtn: false,
        isAttChanged: false,
        attributeChangesMessage: ''
      },() => {
        this.checkRequiredFields()
      })
    }
  }
  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  modalControle() {
    if (this.state.isVisableUserBtn) {
      this.fatchGroups(this.props.logInUser.id)
    }
    this.setState({
      addOption: false,
      editOption: false,
      form: '',
      isVisableUserBtn: false,
      isAttChanged: false,
      attributeChangesMessage: ''
    });
    this.props.onCloseModal()
  }
  addUser() {
    this.setState({
      selectedDevice: '',
      itemAttributes: {},
      addOption: true
    })
  }
  updateUser() {
    this.setState({
      editOption: true,
    })
  }

  userSelection(item) {
    this.setState({
      selectedDevice: item,
      u_attriubtes: item.attributes,
      form: { ...item }
    })
  }
  setExpirationTime = (e) => {
    e.stopPropagation()
    this.setState({
      form: {
        ...this.state.form,
        'expirationTime': null
      }
    }, () => this.checkRequiredFields());
  }
  setNoToken =(e) => {
    e.stopPropagation()
    this.setState({
      form: {
        ...this.state.form,
        'token': null
      }
    }, () => this.checkRequiredFields());
  }
  generateToken() {
    let symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i, newToken = '';
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.setState(preState => ({
      ...preState,
      form: {
        ...preState.form,
        token: newToken
      }
    }), () => this.checkRequiredFields());
  }
  timeDateOnchange(date) {
    this.setState(preState => ({
      ...preState,
      form: {
        ...preState.form,
        timeDate: date
      }
    }));
  }
  handleChange2 = (target, option) => {
    if(option.action==='clear'){
      this.setState({
        form: {
          ...this.state.form,
          [option.name]: 0
        }
      }, () => {
        this.checkRequiredFields();
      });
    }else{
      this.setState({
        form: {
          ...this.state.form,
          [option.name]: target.value
        }
      }, () => {
        this.checkRequiredFields();
      });
    }
  }

  handleChange = name => event => {
    const { target } = event;
   
    let form = {};

    if (target && target.type === "checkbox") { 
        form[name] =  target.checked;
    }
    else if (name === "expirationTime") {

        form[name] =  event.toISOString()

    } else {

      form[name] =  target.value;
    }


    this.setState({
      form: {
        ...this.state.form,
        ...form
      }
    },() => {
      this.checkRequiredFields()
    });

  }

  checkRequiredFields() {
    let { name } = this.state.form
    let value = !isEqual(this.state.form, this.state.selectedDevice)
    if (this.state.editOption) {
      if (name.trim().length > 0 && value) {
        this.setState({
          isVisableUserBtn: true,
          duplicateEntry: false
        })
      } else {
        this.setState({
          isVisableUserBtn: this.state.isAttChanged,
          duplicateEntry: false
        })
      }
    } else {
      if (name.trim().length > 0) {
        this.setState({
          isVisableUserBtn: true,
          duplicateEntry: false
        })
      } else {
        this.setState({
          isVisableUserBtn: this.state.isAttChanged,
          duplicateEntry: false
        })
      }
    }     

  }

  fatchGroups = (id) => {
    if(id){
      // fetch(`/api/groups?userId=${id}`)
      instance({
        url: `/api/groups`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params :{
          userId: id
        }
      })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(groups => {
            this.props.dispatch(getGroups(groups));
            this.setState({
              usesId: this.props.logInUser.id,
              superUser: false
            })
        //   });
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {
        // errorHandler(error, this.props.dispatch)
      })
    }

  }

  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false
    })
  }
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      })
    }

  }
  setSelectedLocation = () => {
    const { localPostionObj } = this.state
    if (localPostionObj) {
      this.setState({
        form: {
          ...this.state.form
        },
        isMapModal: false
      }, () => this.checkRequiredFields()
      );
    }

  }
  onCloseModal = () => {
    this.setState({
      isMapModal: false
    })
  }

  render() {
    const { selectedDevice } = this.state
    //const { logInUser } = this.props
    return (
      <section style={{ width: '100%'}}>
        {this.props.viewScreen === 'add' ?
          <AddGroupModal
            viewScreen={this.props.viewScreen}
            selectedDevice={selectedDevice}
            formSubmit={this.au_submit}
            token={this.state.token}
            timeDate={this.state.timeDate}
            timeDateOnchange={this.timeDateOnchange}
            generateToken={this.generateToken}
            modalControle={this.modalControle}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChange2={this.handleChange2}
            form={this.state.form}
            buttonText={this.props.translate('sharedCreate')}
            isVisableUserBtn={checkPrivileges('groupCreate') && this.state.isVisableUserBtn}
            handleClickShowPostion={this.handleClickShowPostion}
            duplicateEntry={this.state.duplicateEntry}
            setExpirationTime={this.setExpirationTime}
            setNoToken={this.setNoToken}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            btnCancelNotShow={false}
            timezone_handleChange = {this.timezone_handleChange}
            changeAttribute2={this.changeAttribute2}
            isHaveAccess={'groupCreate'}

          />
          : null}
        {this.props.viewScreen === 'edit' ?
          <AddGroupModal
            viewScreen={this.props.viewScreen}
            selectedDevice={selectedDevice}
            formSubmit={this.uu_submit}
            token={this.state.token}
            timeDate={this.state.timeDate}
            timeDateOnchange={this.timeDateOnchange}
            generateToken={this.generateToken}
            modalControle={this.modalControle}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChange2={this.handleChange2}
            form={this.state.form}
            editOption={this.state.editOption}
            buttonText={this.props.translate('sharedUpdate')}
            isVisableUserBtn={checkPrivileges('groupUpdate') && this.state.isVisableUserBtn}
            handleClickShowPostion={this.handleClickShowPostion}
            duplicateEntry={this.state.duplicateEntry}
            setExpirationTime={this.setExpirationTime}
            setNoToken={this.setNoToken}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            btnCancelNotShow={true}
            timezone_handleChange = {this.timezone_handleChange}
            changeAttribute2={this.changeAttribute2}
            isHaveAccess={'groupUpdate'}
          />
          : null}
        {this.state.viewScreen === 'view' ?
          <EditItem
            selectedItem={this.state.selectedDevice}
            handleClose={this.modalControle}
            form={this.state.form} />
          : null}
        {this.state.isMapModal &&
          <CustomDialog
            title={this.props.translate('setLocation')}
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            CancelText={this.props.translate('sharedBack')}
            headerActions={<Button size="small" variant="contained" color="secondary" disabled={!this.state.isVisableAddlocBtn} onClick={() => this.setSelectedLocation()} > {this.props.translate('sharedAdd')}</Button>}
            bodyPadding={0}>
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.selectedDevice.zoom || 0}
              lat={this.state.selectedDevice.latitude || 0}
              lng={this.state.selectedDevice.longitude || 0}
              height={'100%'} />
          </CustomDialog>
        }
      </section>
    );
  }
};

const mapState = state => {
  return {
    users: state.users,
    logInUser: state.logInUsers,
  }
}
const mapStateToProps = connect(mapState)
export const GroupsModal = mapStateToProps(((usersModal)))




