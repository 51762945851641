import React, { Component } from 'react'
import { logInUserInfo, getRoles } from './../../Actions/Users'
import { ServerInfo } from './../../Actions/serverSetting'
import { getLayer } from './../../Actions/Maps'
import {
  getDevices2,
  fetchDevicesList,
  fetchDevices
} from './../../Actions/Devices'
import { connect } from 'react-redux'
import Loader from './../../Layout/Loader'
import {
  displayErrorMessages,
  checkedDevelopmentType,
  getCookie,
  checkPrivileges
} from '../../Helpers/index'
;
import instance from '../../axios'
import withTranslationWrapper from '../../HOC/withTranslation'

class loginAsUser extends Component {
  constructor (props) {
    super(props)
    this.state = { loading: false, loginErrorMessage: '' }
  }
  componentWillMount () {
    this.setState({
      loading: true,
      loginErrorMessage: ''
    })
    this.loginAPiCall()
    // if already have session
    // fetch('/api/session', {
    //   headers: { Accept: 'application/json; odata=verbose' }
    // })
    // instance({
    //   url: `/api/session`,
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json; odata=verbose'
    //   }
    // })
    // .then(response => {
    //   // if (response.ok) {
    //     // then delete session
    //     // fetch('/api/session', {
    //     //   method: 'DELETE'
    //     // })
    //     instance({
    //       url: `/api/session`,
    //       method: 'DELETE',
    //       headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json'
    //       }
    //     })
    //     .then(response => {
    //       // if (response.ok) {
    //         setCookie('JSESSIONID', 0)
    //         this.loginAPiCall()
    //       // } else {
    //       //   this.props.history.push('/login')
    //       //     throw response
    //       //   }
    //       }).catch(error => {
    //         errorHandler(error, this.props.dispatch)
    //         this.props.history.push('/login')
    //       })
    //   // } else {
    //   //   // if session not found then login
    //   //   this.loginAPiCall()
    //   //     throw response
    //   //   }
    //   }).catch(error => {
    //     errorHandler(error, this.props.dispatch)
    //     this.loginAPiCall()
    //   })
  }
  loginAPiCall = () => {
    if (this.props.location.search) {
      let params = this.props.location.search.toString().split('&')
      if (params[2]) {
        localStorage.setItem('backtoAdmin', params[2])
      }
      let fetchUrl = checkedDevelopmentType()
      // fetch(`/api/session/user?token=${atob(params[1])}&app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05${fetchUrl}`,
      //   {
      //     method: 'Get',
      //     headers: {
      //       UIP: getCookie('UIP')
      //     }
      //   }
      // )
      instance({
        url: `/api/session/user`,
        method: 'GET',
        headers: {
          UIP: getCookie('UIP')
        },
        params: {
          token: atob(params[1]),
          app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
          host: fetchUrl
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(userInfoFront => {
              if (userInfoFront) {
                let lang = userInfoFront.attributes.lang
                this.props.setActiveLanguage(lang || 'en')
                let nToken = this.generateToken()
                let obj = userInfoFront
                obj.token = nToken
                // fetch(`/api/users/${userInfoFront.id}`, {
                //   method: 'PUT',
                //   headers: {
                //     Accept: 'application/json',
                //     'Content-Type': 'application/json'
                //   },
                //   body: JSON.stringify({
                //     ...obj
                //   })
                // })
                instance({
                  url: `/api/users/${userInfoFront.id}`,
                  method: 'PUT',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  data: {
                    ...obj
                  }
                })
                // .then(response => {
                //   if (response.ok) {
                //     response.json()
                    .then(user => {
                      this.props.dispatch(logInUserInfo(user))
                  //   })
                  // }
                  // else{
                  //   throw response
                  // }
                }).catch(error => {
                  // errorHandler(error, this.props.dispatch)
                })
                // fetch(`/api/devices/list?userId=${userInfoFront.id}&all=true&limit=-1`)
                instance({
                  url: `/api/devices/list`,
                  method: 'GET',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  params: {
                    userId: userInfoFront.id,
                    all: true,
                    limit: -1
                  }
                })
                // .then(response => {
                //   if (response.ok) {
                //     response.json()
                    .then(devices => {
                      this.props.dispatch(getDevices2(devices.data))
                  //   })
                  // }
                  // else{
                  //   throw response
                  // }
                }).catch(error => {
                  // errorHandler(error, this.props.dispatch)
                })
                if (userInfoFront.accountId && userInfoFront.accountId !== 0) {
                  // fetch(`/api/accounts?accountId=${userInfoFront.accountId}`)
                  instance({
                    url: `/api/accounts`,
                    method: 'GET',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                    },
                    params: {
                      accountId: userInfoFront.accountId
                    }
                  })
                    // .then(response => {
                    //   if (response.ok) {
                    //     response.json()
                        .then(server => {
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            this.props.dispatch(
                              getLayer({
                                id: userInfoFront.map || server[0].map || 'osm'
                              })
                            )
                            // fetch call for Api  privileges
                            // fetch(`/api/privileges?roleId=${userInfoFront.roleId}`,
                            //   {
                            //     method: 'Get'
                            //   })
                            instance({
                              url: `/api/privileges`,
                              method: 'GET',
                              headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                              },
                              params: {
                                roleId: userInfoFront.roleId
                              }
                            })
                              // .then(response => {
                              // if (response.ok) {
                              //   response.json()
                                .then(privileges => {
                                  let privilegeKeys = []
                                  privileges.map(item => {
                                    privilegeKeys.push(item.key)
                                    return null
                                  })
                                  localStorage.setItem(
                                    'frontPrivileges',
                                    JSON.stringify({ ...privilegeKeys })
                                  )
                                  if (privileges.length) {
                                    if(userInfoFront.customerType === 1 || userInfoFront.customerType === 2){
                                      this.props.history.push("/maintenance");
                                    }else{
                                    this.props.history.push('/maintenance')
                                    }
                                    this.props.dispatch(
                                      fetchDevicesList(userInfoFront)
                                    )
                                    this.props.dispatch(
                                      fetchDevices(userInfoFront)
                                    )
                                   if (checkPrivileges('role')) {
                                      instance({
                                            url: `/api/roles`,
                                            method: 'GET',
                                            params:{
                                              userId: userInfoFront.id
                                            }
                                          })
                                          .then(role => {
                                            this.props.dispatch(getRoles(role))
                                          }).catch(error => {
                                          // errorHandler(error, this.props.dispatch)
                                        })
                                    }

                                  } else {
                                    this.setState(
                                      {
                                        loading: false,
                                        loginErrorMessage: 'Lack Of Privileges'
                                      },
                                      () => {
                                        this.someThingWendWrong(
                                          'Lack Of Privileges'
                                        )
                                      }
                                    )
                                  }
                            //     })
                            //   }
                            // else{
                            //   throw response
                            // }
                          }).catch(error => {
                            // errorHandler(error, this.props.dispatch)
                          })
                          } else {
                            this.someThingWendWrong('Account Data Not Found')
                          }
                      //   })
                      // } else {
                      //   throw response
                      // }
                    })
                    .catch(error => {
                      // errorHandler(error, this.props.dispatch,this.errorCallBack)
                      this.errorMessage(error)
                    })
                } else {
                  this.someThingWendWrong('User Account is not set')
                }
              } else {
                this.someThingWendWrong('user not Found')
              }
            })
        //   } else {
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch, this.errorCallBack)
          this.errorMessage(error)
        })
    }
  }
  someThingWendWrong = mgs => {
    //redirect to admin if he come from admin
    if (localStorage.getItem('backtoAdmin')) {
      localStorage.setItem('backtoAdmin', '')
      this.props.history.push('/logIn', mgs)
    } else {
      this.props.history.push('/logIn', mgs)
    }
  }
  errorMessage = err => {
    // if (e && e.text) {
    //   e.text().then(err => {
        this.setState(
          {
            loading: false,
            loginErrorMessage: displayErrorMessages(err.message)
          },
          () => {
            //redirect to admin if he come from admin
            if (localStorage.getItem('backtoAdmin')) {
              localStorage.setItem('backtoAdmin', '')
              this.props.history.push(
                '/logIn',
                this.props.translate(this.state.loginErrorMessage)
              )
            } else {
              this.props.history.push(
                '/logIn',
                this.props.translate(this.state.loginErrorMessage)
              )
            }
          }
        )
    //   })
    // }
  }

  generateToken = () => {
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i,
      newToken = ''
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    return newToken
  }

  render () {
    if (this.state.loading) {
      return <Loader />
    } else {
      return null
    }
  }
}
const mapState = state => {
  return {}
}
const mapStateToProps = connect(mapState)

export const LoginAsUser = mapStateToProps(withTranslationWrapper(loginAsUser))
