import React, { Component } from "react";
import SimpleModalWrapped from "../common/Modal";
import { Trans as Translate} from 'react-i18next';
import Grid from "@mui/material/Grid";
import TextField from "../common/TextField";
import FormHelperText from '@mui/material/FormHelperText';
import SearchDevices from "../Devices/searchItems";
import { withStyles } from '@mui/styles'
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "./../common/Tooltip";
import IconButton from "@mui/material/IconButton";
import CrossIcons from "@mui/icons-material/Remove";
import moment from "moment";
import { Api, checkPrivileges, plateTypes } from "../../Helpers";
import Selector from "../common/Select";

import axios from "axios";
import Button from "../common/Button";
import SingleSelect from "../common/SingleSelect";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import Checkbox from "../common/Checkbox";
import InfoIcon from '@mui/icons-material/Info';
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../HOC/withTranslation";

const allTypes = ["Leasing"];

const poolTypes = [
  {
    key: "contractual",
    name: "Contractual",
  },
  {
    key: "exclusive",
    name: "Exclusive",
  },
  {
    key: "common",
    name: "Common",
  } ,
  {
    key: "otherVertical",
    name: "Other Vertical",
  },
  {
    key: "cooperateService",
    name: "Cooperate Service",
  },
];
const vehicleEntitlementList= [
  {
    key: 1,
    name: "Company Car",
  },
  {
    key: 2,
    name: "New Policy Company Car - Upgrade",
  },
  {
    key: 3,
    name: "New Policy Job Car",
  }
  ,
  {
    key: 4,
    name: "New Policy Job Car - Upgrade",
  },
  {
    key: 5,
    name: "Old Policy Job Car",
  },
  {
    key: 6,
    name: " Old Policy Upgrade - Paid",
  },
  {
    key: 7,
    name: "Old Policy Upgrade",
  },
  {
    key: 8,
    name: "Department Assets",
  },
];
const styles = (theme) => ({
  root: {
    background: "blue",
    marginBottom: "10px",
  },
  root2: {
    background: "grey",
    marginBottom: "10px",
  },
  whiteColor: {
    color: "white",
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


class addVehicle extends Component {
  constructor() {
    super();
    this.state = {
      attm_isVisable: false,
      inquiryResponse: "",
      errorResponse: "",
      typeName: ["Maintenance"],
      open: false,
    };
    this.addAttributes = this.addAttributes.bind(this);
  }
  componentWillMount() {
    if (this.props.editOption) {
      this.setState({
        typeName: this.props.form?.attributes?.typeName || ["Maintenance"],
      });
    }
  }

  addAttributes() {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable,
    });
  }

  uploadFile = (e) => {
    console.log("image =", e.target.files[0]);
  };

  handleChange = (a, b) => {
    this.setState({ open: false });
    this.props.handleChangeForAttributesValues(a, { target: b });
  };

  verify = () => {
    this.setState({ errorResponse: "", inquiryResponse: "" });
    if (this.props.form.attributes.sequenceNumber) {
      axios
        .post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
          hook: "getVehicle",
          account_id: this.props.serversettings.id,
          data: JSON.stringify({
            sequenceNumber: this.props.form.attributes.sequenceNumber,
            activity: "DEFAULT",
          }),
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.referenceKey
          ) {
            this.props.handleChangeForAttributesValues("WASLKey", {
              target: { value: response.data.referenceKey },
            });
            this.props.formSubmit();
            this.props.dispatch(
             notifySuccess("Integrated With Wasl"
            ))
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data && error.response.data.message) {
              const { message, data } = error.response.data;
              this.setState({
                inquiryResponse:
                  data?.resultCode ||
                  (message.toLowerCase() === "bad request"
                    ? "Not Registered"
                    : message),
              });
            } else {
              const {
                data: { resultCode, resultMsg, errorMsg, errorCode },
              } = error.response.data;
              this.setState({
                inquiryResponse:
                  resultMsg || resultCode || errorMsg || errorCode,
              });
            }
          }
        });
    } else {
      this.props.dispatch(
        notifyError("Please enter sequence number first")
      );
    }
  };

  submitWasl = () => {
    this.setState({ errorResponse: "", inquiryResponse: "" });
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    if (waslCompanyRefKey) {
      const {
        number,
        leftLetter,
        rightLetter,
        plateType,
        middleLetter,
        sequenceNumber,
      } = this.props.form.attributes;
      const { uniqueId } = this.props.vehicleTracker;
      if (
        number &&
        leftLetter &&
        rightLetter &&
        plateType &&
        middleLetter &&
        sequenceNumber &&
        uniqueId 
      ) {
        axios
          .post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
            hook: "registerVehicle",
            account_id: this.props.serversettings.id,
            data: JSON.stringify({
              uniqueId,
              sequenceNumber,
              activity: "DEFAULT",
              attributes: {
                rightLetter,
                number,
                leftLetter,
                middleLetter,
                plateType,
              },
            }),
            hook_prefix: waslCompanyRefKey,
          })
          .then((response) => {
            axios
              .get(`${Api}/refreshCache`)
              .then((response) => {})
              .catch((error) => {});
            if (
              response.status === 200 &&
              response.data &&
              response.data.success
            ) {
              if (
                response.data &&
                response.data.result &&
                response.data.success &&
                response.data.result.referenceKey
              ) {
                this.props.handleChangeForAttributesValues("WASLKey", {
                  target: { value: response.data.result.referenceKey },
                });
                this.props.formSubmit();
              }
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              const {
                data: { resultCode, resultMsg, errorMsg, errorCode },
              } = error.response.data;
              this.setState({
                errorResponse: resultMsg || resultCode || errorMsg || errorCode,
              });
              // this.props.handleChangeForAttributesValues('WASLKey', { target: {value:error.response.data&&error.response.data.data&&error.response.data.data.result.referenceKey} } );
              // this.props.formSubmit();
            }
          });
      } else {
        this.setState({ errorResponse: "integration form incomplete" });
      }
    } else {
      this.props.dispatch(
        notifyError("Please Register your company first")
      );
    }
  };

  changeIMEI = () => {
    this.setState({ errorResponse: "", inquiryResponse: "" });
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    const { WASLKey, sequenceNumber } = this.props.form.attributes;
    const { uniqueId } = this.props.vehicleTracker;
    if (waslCompanyRefKey && WASLKey) {
      axios
        .post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
          hook: "updateVehicle",
          account_id: this.props.serversettings.id,
          data: JSON.stringify({
            sequenceNumber,
            imeiNumber: uniqueId,
            activity: "DEFAULT",
          }),
        })
        .then((response) => {
          if (response && response.data && response.data.resultCode);
          this.setState({ inquiryResponse: response.data.resultCode }, () =>
            this.props.formSubmit()
          );
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            const {
              data: { resultCode, resultMsg, errorMsg, errorCode },
            } = error.response.data;
            this.setState({
              errorResponse: resultMsg || resultCode || errorMsg || errorCode,
            });
          }
          let errMsg =
            (error.response &&
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.resultCode) ||
            "somethingWentWrong";
          this.props.dispatch(
            notifyError(errMsg)
          );
        });
    }
  };

  unlinkWasl = () => {
    this.setState({ errorResponse: "", inquiryResponse: "" });
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    const { WASLKey } = this.props.form.attributes;

    if (waslCompanyRefKey && WASLKey) {
      axios
        .post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
          hook: "deleteVehicle",
          account_id: this.props.serversettings.id,
          hook_prefix: waslCompanyRefKey,
          hook_postfix: WASLKey,
          data: {
            activity: "DEFAULT",
          },
        })
        .then((response) => {
          this.props.handleChangeForAttributesValues(
            "WASLKey",
            { target: { value: "" } },
            false
          );
          this.props.formSubmit();
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.data) {
            const {
              data: { resultCode, resultMsg, errorMsg, errorCode },
            } = error.response.data;
            this.setState({
              errorResponse: resultMsg || resultCode || errorMsg || errorCode,
            });
          }
          let errMsg =
            (error.response &&
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.resultCode) ||
            "somethingWentWrong";
          this.props.dispatch(
            notifyError(errMsg)
          );
        });
    } else {
      this.props.dispatch(
        notifyError("Please Register your company first")
      );
    }
  };

  UNSAFE_componentWillReceiveProps(n) {
    if (n.form.id !== this.props.form.id) {
      this.setState({
        inquiryResponse: "",
        errorResponse: "",
      });
    }
  }

  handleChange = (event) => {
    this.setState({ typeName: event, open: true }, () => {
      this.props.handleChange("typeName", this.state.typeName);
    });
  };

  render() {
    const { classes } = this.props;
    const { form } = this.props;
    const { attributes } = form;
    const WASLKey = form?.attributes?.WASLKey || "";
    const { waslEnabled, waslCompanyRefKey } =
      this.props?.serversettings?.attributes || {};
    return (
      <div style={{ height: "100%", paddingRight: 4 }}>
        <SimpleModalWrapped
          visable={true}
          notShowAttributesBtn
          isButtonVisable={true}
          title="sharedVehicle"
          formSubmit={this.props.formSubmit}
          modalControle={this.props.onClose}
          buttonText={this.props.buttonText}
          btnCloseText="sharedBack"
          isVisableBtn={this.props.isVisableUserBtn}
          selectedItemId={this.props.selectedVehicle.id}
          waslFooter={
            waslEnabled ? (
              WASLKey ? (
                <>
                  <Button onClick={this.changeIMEI}>Change IMEI</Button>
                  <Button style={{ marginLeft: 10 }} onClick={this.unlinkWasl}>
                    Un-Link WASL
                  </Button>
                </>
              ) : (
                <Button onClick={this.submitWasl}>Link WASL</Button>
              )
            ) : null
          }
          content={
            <form autoComplete="off">
              <Grid container spacing={2}>
              <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ postion: "relative", zIndex: 16 }}
                >
                  <SingleSelect
                    array={this.props?.users || []}
                    // async
                    isClearable
                    id="parentId"
                    selectName="parentId"
                    label={<Translate i18nKey="selectChildUsers" />}
                    // defaultOptions={this.props?.selectedMakeTypes || []}
                    value={
                      this.props.form &&
                      this.props.form.parentId &&
                      this.props.form.parentId.id
                        ? {
                            id: this.props.form.parentId.id,
                            value: this.props.form.parentId.id,
                            label: this.props.form.parentId.label || this.props.form.parentId.name ,
                          }
                        : ""
                    }
                    // index={index}
                    handleChange={this.props.handleChange}
                    canRemove={true}
                    canAssign={true}
                  />
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}> */}
                {/* <TextField
                      id='typeName'
                      select
                      label={<Translate i18nKey='typeName' />}
                      // value={this.state.typeName||''}
                      // onChange={this.handleChange('typeName')}
                        value={this.props.form?.attributes?.typeName || ''}
                        onChange={e =>
                        this.props.handleChangeForAttributesValues(
                          'typeName',
                          e
                        )}
                      margin='dense'
                      fullWidth
                    >
                      <MenuItem value=''>
                        <em>{<Translate i18nKey='none'/>}</em>
                      </MenuItem>
                      {allTypes.map(option => ( <MenuItem key={option} value={option}>{option}  </MenuItem>))}
                    </TextField>
                  </Grid> */}
                {/* multiple select hidden */}
                {/* <Grid item md={3} sm={6} xs={12}>
                <InputLabel htmlFor="name-multiple" style={{color:"#ffffff",fontSize:"11px"}}><Translate i18nKey='sharedType' /></InputLabel>
                    <Select
                      id='label'
                      label={<Translate i18nKey='label' />}
                      multiple
                      value={this.state?.typeName || ''}
                      onChange={this.handleChange}
                      // input={<Input />}
                      // MenuProps={MenuProps}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      classes={{
                        root: classes.whiteColor,
                        icon: classes.whiteColor
                      }}
                    >
                    {allTypes.map(option => (<MenuItem key={option} value={option}>{(option)} </MenuItem>))}
                    </Select>
                </Grid> */}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="label"
                    label={<Translate i18nKey="label" />}
                    required
                    placeholder=""
                    value={this.props.form?.label || ""}
                    onChange={(e) => this.props.handleChange("label", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "96%"}}>
                      <TextField
                        error={this.props.vehicleLicensePlateError ? true : false}
                        id="vehicleLicensePlate"
                        label={this.props.translate("vehicleLicensePlate")}
                        required
                        placeholder=""
                        value={this.props.form.vehicleLicensePlate || ""}
                        onChange={(e) =>
                          this.props.handleChange("vehicleLicensePlate", e)
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        // style={{ width: "100%", marginRight: "10px" }} // Changed width to 100%
                      />
                      </div>
                      <HtmlTooltip
                        title={
                          <span style={{fontSize:"18px", fontWeight: "bold", width:"200px", height:"150px", padding:"5px 0px" }}> 
                            {this.props.translate("Format  4734AGJ")} 
                            {/* <br/> 
                            <br/> 
                            {this.props.translate("Arabic أ ج ج ٤٧٣٤")} */}
                          </span>
                        }
                        style={{ color: "green" }} // Changed the color to green
                      >
                        <InfoIcon fontSize="large"  style={{ fontSize: 24, color: "#1E72B8" , marginLeft:"5px" }} />  
                      </HtmlTooltip>
                    </div>
                    <FormHelperText 
                      style={{ fontWeight: "bold", color: "red" , fontSize:"12px"}} // Changed fontWeight and color
                      error={this.props.vehicleLicensePlateError ? true : false}
                    >
                      {this.props.translate(`Follow format 4734AGJ`)}
                    </FormHelperText>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="vehicleLisencePlateArabic"
                    label={<Translate i18nKey="vehicleLisencePlateArabic" />}
                    placeholder=""
                    value={this.props.form?.vehicleLisencePlateArabic || ""}
                    onChange={(e) =>
                      this.props.handleChange("vehicleLisencePlateArabic", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id="entitlement"
                      select
                      label={this.props.translate("Vehicle Entitlement")}
                      value={this.props.form?.entitlement || ""}
                      onChange={(e) => this.props.handleChange("entitlement", e)}
                      margin="dense"
                      fullWidth
                      // required
                    >
                      {vehicleEntitlementList?.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    id="category"
                    select
                    label={<Translate i18nKey="vehicleType" />}
                    value={this.props.form.category || ""}
                    onChange={(e) => this.props.handleChange("category", e)}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                    fullWidth
                    required
                  >
                    <MenuItem value={0}>
                      <em>{<Translate i18nKey="none" />}</em>
                    </MenuItem>
                    {this.props?.categoriesList?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {<Translate i18nKey={option} />}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="country"
                    label={<Translate i18nKey="country" />}
                    placeholder=""
                    value={this.props.form?.attributes?.country || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues("country", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="registrationNumber"
                    label={<Translate i18nKey="registrationNumber" />}
                    placeholder=""
                    value={
                      this.props.form?.attributes?.registrationNumber || ""
                    }
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues(
                        "registrationNumber",
                        e
                      )
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="variant"
                    label={<Translate i18nKey="variant" />}
                    placeholder=""
                    value={this.props.form?.attributes?.variant || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues("variant", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="wheels"
                    label={<Translate i18nKey="wheels" />}
                    placeholder=""
                    value={this.props.form?.attributes?.wheels || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues("wheels", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <SingleSelect
                    array={this.props?.trasmissionList || []}
                    selectName="trasmissionType"
                    label={<Translate i18nKey="trasmissionType" />}
                    value={
                      this.props.form &&
                      this.props.form.attributes &&
                      this.props.form.attributes.trasmissionType
                        ? {
                            id: this.props.form.attributes.trasmissionType,
                            label: this.props.form.attributes.trasmissionType,
                            value: this.props.form.attributes.trasmissionType,
                          }
                        : ""
                    }
                    handleChange={this.props.handleChangeForAttributesValues}
                    canRemove={true}
                    canAssign={true}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ postion: "relative", zIndex: 4 }}
                >
                  <SingleSelect
                    array={this.props?.allMakes || []}
                    // async
                    isClearable
                    id="make"
                    selectName="make"
                    label={<Translate i18nKey="make" />}
                    // defaultOptions={this.props?.allMakes || []}
                    value={
                      this.props.form && this.props.form.make
                        ? {
                            id: this.props.form.make.key,
                            value: this.props.form.make.label,
                            label: this.props.form.make.label,
                          }
                        : ""
                    }
                    handleChange={this.props.handleChangeForAttributesValues}
                    canRemove={true}
                    canAssign={true}
                    menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ postion: "relative", zIndex: 14 }}
                >
                  <SingleSelect
                    array={this.props?.selectedMakeTypes || []}
                    // async
                    isClearable
                    id="modelId"
                    selectName="modelId"
                    label={<Translate i18nKey="model" />}
                    // defaultOptions={this.props?.selectedMakeTypes || []}
                    value={
                      this.props.form &&
                      this.props.form.modelId &&
                      this.props.form.modelId.id
                        ? {
                            id: this.props.form.modelId.id,
                            value: this.props.form.modelId.label,
                            label: this.props.form.modelId.label,
                          }
                        : ""
                    }
                    // index={index}
                    handleChange={this.props.handleChangeForAttributesValues}
                    canRemove={true}
                    canAssign={true}
                  />
                </Grid>
                {/* 
                
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='make'
                    label={<Translate i18nKey='make' />}
                    placeholder=''
                    value={this.props.form.attributes.make || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'make',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='model'
                    label={<Translate i18nKey='model' />}
                    placeholder=''
                    value={this.props.form.model || ''}
                    onChange={e => this.props.handleChange('model', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="year"
                    label={<Translate i18nKey="year" />}
                    placeholder=""
                    value={this.props.form?.attributes?.year || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "year",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="color"
                    label={<Translate i18nKey="attributeColor" />}
                    placeholder=""
                    value={this.props.form?.attributes?.color || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "color",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12} style={{ position: 'relative' }}>
                  {/* <TextField
                    id="fuel_type"
                    label={<Translate i18nKey="fuel_type" />}
                    placeholder=""
                    value={this.props.form?.attributes?.fuel_type || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "fuel_type",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  /> */}
                    <label style={{ 
                      fontSize: '0.75em', lineHeight: '1', position: 'absolute', zIndex: '10', 
                      background: '#fff', padding: '0 5px', left: '14px'
                      }}>
                      {this.props.translate("fuel_type*")}
                    </label>
                    <SingleSelect
                      array={this.props.fuel_typeList || []}
                      // async
                      isClearable
                      id="fuel_type"
                      selectName="fuel_type"
                      // label={<Translate i18nKey="fuel_type" />}
                      value={
                        this.props.form &&
                        this.props.form.attributes &&
                        this.props.form.attributes.fuel_type
                          ? {
                              id: this.props.form.attributes.fuel_type,
                              label: this.props.form.attributes.fuel_type,
                              value: this.props.form.attributes.fuel_type,
                            }
                          : ""
                      }
                      onChange={this.props.handleChange}
                      handleChange={this.props.handleChange}
                      canRemove={true}
                      canAssign={true}
                    />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    type="number"
                    id="fuelEfficiency"
                    label={<Translate i18nKey="fuelEfficiency" />}
                    // required
                    placeholder=""
                    value={this.props.form?.fuelEfficiency || ""}
                    onChange={(e) => this.props.handleChange("fuelEfficiency", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id="totalAvailablekm"
                      label={this.props.translate("Standard Daily Mileage (km)")}
                      placeholder=""
                      value={this.props.form?.totalAvailablekm || ""}
                      onChange={(e) =>
                        this.props.handleChange("totalAvailablekm", e)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      required
                      error={this.props.form?.totalAvailablekm < 1? true : false}
                    />
                    <FormHelperText error={this.props.form?.totalAvailablekm < 1 ? true : false}>
                      Must be Greater than 0
                    </FormHelperText>
                  </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      type="number"
                      id="odometer"
                      label={<Translate i18nKey="odometer" />}
                      // required
                      placeholder=""
                      value={this.props.form?.odometer || ""}
                      onChange={(e) => this.props.handleChange("odometer", e)}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="capacity"
                    label={<Translate i18nKey="capacity" />}
                    placeholder=""
                    value={this.props.form?.attributes?.capacity || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "capacity",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="garage"
                    label={<Translate i18nKey="garage" />}
                    placeholder=""
                    value={this.props?.form?.garage || ""}
                    onChange={(e) => this.props.handleChange("garage", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ postion: "relative", zIndex: 3 }}
                >
                  <SearchDevices
                    isMulti={false}
                    api="devices"
                    isClearable
                    onChange={this.props.onChangedDevice}
                    value={
                      this.props?.vehicleTracker
                        ? {
                            id: this.props?.vehicleTracker?.value || "",
                            value: this.props?.vehicleTracker?.value || "",
                            label: this.props?.vehicleTracker?.label || "",
                          }
                        : null
                    }
                    placeholder={<Translate i18nKey="searchUnits" />}
                  />
                </Grid>

                {/* <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="vehicleType"
                    label={<Translate i18nKey="sharedType" />}
                    placeholder=""
                    value={this.props?.form?.vehicleType || ""}
                    onChange={(e) => this.props.handleChange("vehicleType", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid> */}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="vin"
                    label={<Translate i18nKey="vin *" />}
                    placeholder=""
                    value={this.props.form?.vin || ""}
                    onChange={(e) => this.props.handleChange("vin", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="chassisNumber"
                    label={<Translate i18nKey="chassisNumber" />}
                    placeholder=""
                    value={this.props.form?.attributes?.chassisNumber || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues(
                        "chassisNumber",
                        e
                      )
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                {/* start          -----------pending task from backEnd--------- Expiration Date*/}
                <Grid item md={3} sm={6} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      error={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf("day")
                      }
                      helperText={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf("day") ? (
                          <Translate i18nKey="licenseExpired" />
                        ) : (
                          ""
                        )
                      }
                      margin="dense"
                      label={<Translate i18nKey="V/LExpirationTime" />}
                      variant="outlined"
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form.expirationTime
                          ? new Date(
                              `${this.props.form.expirationTime}`
                            )
                          : null
                      }
                      onChange={this.props.handleChangeLicenseExpDate(
                        "expirationTime"
                      )}
                      InputProps={{
                        classes: {
                          root: "theme-cssOutlinedInput",
                          input: "theme-input",
                          focused: "theme-cssFocused",
                          notchedOutline: "theme-notchedOutline",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={<Translate i18nKey="noLimit" />}>
                              <IconButton
                                onClick={(e) => this.props.setExpirationTime(e)}
                                style={{ color: "inherit" }}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "theme-label",
                          focused: "theme-label-focused",
                          shrink: "theme-label-shrink",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="Fuelconsumption"
                    label={this.props.translate("averageFuelConsumption")}
                    placeholder=""
                    value={this.props.form?.attributes?.Fuelconsumption || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues(
                        "Fuelconsumption",
                        e
                      )
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid> */}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="driverCost"
                    label={<Translate i18nKey="driverCost" />}
                    placeholder=""
                    value={this.props.form?.attributes?.driverCost || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues(
                        "driverCost",
                        e
                      )
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="fines"
                    label={<Translate i18nKey="fines" />}
                    placeholder=""
                    value={this.props.form?.attributes?.fines || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues("fines", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="brandingCost"
                    label={<Translate i18nKey="brandingCost" />}
                    placeholder=""
                    value={this.props.form?.attributes?.brandingCost || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues(
                        "brandingCost",
                        e
                      )
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="telematicsCost"
                    label={<Translate i18nKey="telematicsCost" />}
                    placeholder=""
                    value={this.props.form?.attributes?.telematicsCost || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues(
                        "telematicsCost",
                        e
                      )
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="otherCost"
                    label={<Translate i18nKey="otherCost" />}
                    placeholder=""
                    value={this.props.form?.attributes?.otherCost || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues("otherCost", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="SIMNumber"
                    label={<Translate i18nKey="SIMNumber" />}
                    placeholder=""
                    value={this.props.form?.attributes?.SIMNumber || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues("SIMNumber", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>

                {checkPrivileges("area") ? (
                  <Grid item md={3} sm={6} xs={12}   style={{ postion: "relative", zIndex: 1 }}>
                    <SingleSelect
                      array={this.props?.areaList || []}
                      selectName="areaId"
                      label={<Translate i18nKey="sharedArea" />}
                      value={
                        this.props.form &&
                        this.props.form.areaId &&
                        this.props.form.areaId.key
                          ? {
                              id: this.props.form.areaId.key,
                              value: this.props.form.areaId.key,
                              label: this.props.form.areaId.name,
                              uniqueId: this.props.form.areaId.uniqueId,
                            }
                          : ""
                      }
                      // value={this.props.form.vehicleType || ''}
                      // onChange={e => this.props.handleChange('vehicleType', e)}
                      handleChange={this.props.handleChange}
                      canRemove={true}
                      canAssign={true}
                    />
                  </Grid>
                ) : null}

            {this.props?.serversettings?.contractType !== 1 ?
            <>
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ display: "flex", marginTop: 10 }}
                >
                  <Checkbox
                    checked={this.props.form?.onLease || ""}
                    onChange={(e) => this.props.handleChange("onLease", e)}
                    value="type"
                    canAssign
                    canRemove
                    label={<Translate i18nKey="leasing" />}
                  />
                </Grid>

                {/*---end--- */}

                {/* ------state-------------upload pdf file-----pending task from backEnd
                <Grid item md={3} sm={6} xs={12}>
                  <Tooltip title='Upload pdf'>
                    <Button
                      style={{ float: 'right', maxWidth: '40px',minWidth: '40px' }}
                      variant='contained'
                      margin='dense'
                      component="label"
                      onChange={e => this.uploadFile(e)}
                    >
                      <PublishIcon  style={{ color: '#fff'}}/>
                      <input
                        type="file"
                        hidden
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                ----end---- */}
                {/* <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='permittedSpeed'
                    type='number'
                    label={<Translate i18nKey='permittedSpeed' />}
                    placeholder=''
                    value={this.props.form.attributes.permittedSpeed || ''}
                    onChange={e =>
                      this.props.handleChangeForAttributesValues(
                        'permittedSpeed',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}

                {this.props.form && this.props.form.onLease ? (
                  <>
                    <Grid item xs={12}>
                      <h3
                        style={{ margin: 0 }}
                        className="with-border page-title"
                      >
                        {<Translate i18nKey="leasing" />}
                      </h3>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="purchasePrice"
                        label={<Translate i18nKey="purchasePrice" />}
                        placeholder=""
                        value={this.props.form?.purchasePrice || ""}
                        onChange={(e) =>
                          this.props.handleChange("purchasePrice", e)
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="monthlyRate"
                        label={<Translate i18nKey="monthlyRate" />}
                        placeholder=""
                        value={this.props.form?.monthlyRate || ""}
                        onChange={(e) =>
                          this.props.handleChange("monthlyRate", e)
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="poolType"
                        select
                        label={<Translate i18nKey="poolType" />}
                        value={this.props.form?.attributes?.poolType || ""}
                        // onChange={this.handleChange('typeName')}
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "poolType",
                            e
                          )
                        }
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>{<Translate i18nKey="none" />}</em>
                        </MenuItem>
                        {poolTypes?.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.name}{" "}
                          </MenuItem>
                        ))}
                      </TextField>
                      {/* <TextField
                        id='poolType'
                        label={<Translate i18nKey='poolType' />}
                        placeholder=''
                        value={this.props.form?.attributes?.poolType || ''}
                        onChange={e =>
                          this.props.handleChangeForAttributesValues(
                            'poolType',
                            e
                          )
                        }
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      /> */}
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="insurancePercentage"
                        label={<Translate i18nKey="insurancePercentage" />}
                        placeholder=""
                        value={
                          this.props.form?.attributes?.insurancePercentage || ""
                        }
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "insurancePercentage",
                            e
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="bankPercentage"
                        label={<Translate i18nKey="bankPercentage" />}
                        placeholder=""
                        value={
                          this.props.form?.attributes?.bankPercentage || ""
                        }
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "bankPercentage",
                            e
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="Maintenance"
                        label={<Translate i18nKey="maintenanceCost" />}
                        placeholder=""
                        value={this.props.form?.attributes?.Maintenance || ""}
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "Maintenance",
                            e
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="zakatPercentage"
                        label={<Translate i18nKey="zakatPercentage" />}
                        placeholder=""
                        value={
                          this.props.form?.attributes?.zakatPercentage || ""
                        }
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "zakatPercentage",
                            e
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="resalePercentage"
                        label={<Translate i18nKey="resalePercentage" />}
                        placeholder=""
                        value={
                          this.props.form?.attributes?.resalePercentage || ""
                        }
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "resalePercentage",
                            e
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="replacement"
                        label={<Translate i18nKey="replacementPercentage" />}
                        placeholder=""
                        value={this.props.form?.attributes?.replacement || ""}
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "replacement",
                            e
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="standByVehicle"
                        label={<Translate i18nKey="standByVehicle" />}
                        placeholder=""
                        value={
                          this.props.form?.attributes?.standByVehicle || ""
                        }
                        onChange={(e) =>
                          this.props.handleChangeForAttributesValues(
                            "standByVehicle",
                            e
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          // error={
                          //   this.props.form &&
                          //   this.props.form.purchaseDate &&
                          //   this.props.form.purchaseDate <
                          //     moment().startOf("day").toISOString()
                          // }
                          helperText={
                            this.props.form &&
                            this.props.form.attributes &&
                            this.props.form.attributes.purchaseDate <
                              moment().startOf("day").toISOString() ? (
                              <Translate i18nKey="purchaseDate" />
                            ) : (
                              ""
                            )
                          }
                          margin="dense"
                          label={<Translate i18nKey="purchaseDate" />}
                          variant="outlined"
                          fullWidth
                          // minDate={new Date()}
                          value={
                            this.props.form && this.props.form.purchaseDate
                              ? new Date(
                                  `${this.props.form.purchaseDate}`
                                ).toString()
                              : null
                          }
                          onChange={this.props.handleChangeLicenseExpDate(
                            "purchaseDate"
                          )}
                          InputProps={{
                            classes: {
                              root: "theme-cssOutlinedInput",
                              input: "theme-input",
                              focused: "theme-cssFocused",
                              notchedOutline: "theme-notchedOutline",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "theme-label",
                              focused: "theme-label-focused",
                              shrink: "theme-label-shrink",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          // error={
                          //   this.props.form &&
                          //   this.props.form.deliveryDate &&
                          //   this.props.form.deliveryDate <
                          //     moment().startOf("day").toISOString()
                          // }
                          // helperText={
                          //   this.props.form &&
                          //   this.props.form.deliveryDate &&
                          //   this.props.form.deliveryDate <
                          //     moment().startOf("day").toISOString() ? (
                          //     <Translate i18nKey="deliveryDate" />
                          //   ) : (
                          //     ""
                          //   )
                          // }
                          required
                          margin="dense"
                          label={<Translate i18nKey="deliveryDate" />}
                          variant="outlined"
                          fullWidth
                          // minDate={new Date()}
                          value={
                            this.props.form && this.props.form.deliveryDate
                              ? new Date(
                                  `${this.props.form.deliveryDate}`
                                ).toString()
                              : null
                          }
                          onChange={this.props.handleChangeLicenseExpDate(
                            "deliveryDate"
                          )}
                          InputProps={{
                            classes: {
                              root: "theme-cssOutlinedInput",
                              input: "theme-input",
                              focused: "theme-cssFocused",
                              notchedOutline: "theme-notchedOutline",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "theme-label",
                              focused: "theme-label-focused",
                              shrink: "theme-label-shrink",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                        required
                          format="dd/MM/yyyy"
                          // error={
                          //   this.props.form &&
                          //   this.props.form.leaseStartDate &&
                          //   this.props.form.leaseStartDate <
                          //     moment().startOf("day").toISOString()
                          // }
                          // helperText={
                          //   this.props.form &&
                          //   this.props.form.leaseStartDate &&
                          //   this.props.form.leaseStartDate <
                          //     moment().startOf("day").toISOString() ? (
                          //     <Translate i18nKey="leaseStartDate" />
                          //   ) : (
                          //     ""
                          //   )
                          // }
                          margin="dense"
                          label={<Translate i18nKey="leaseStartDate" />}
                          variant="outlined"
                          fullWidth
                          // minDate={new Date()}
                          value={
                            this.props.form && this.props.form.leaseStartDate
                              ? new Date(
                                  `${this.props.form.leaseStartDate}`
                                ).toString()
                              : null
                          }
                          onChange={this.props.handleChangeLicenseExpDate(
                            "leaseStartDate"
                          )}
                          InputProps={{
                            classes: {
                              root: "theme-cssOutlinedInput",
                              input: "theme-input",
                              focused: "theme-cssFocused",
                              notchedOutline: "theme-notchedOutline",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "theme-label",
                              focused: "theme-label-focused",
                              shrink: "theme-label-shrink",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          error={
                            this.props.form &&
                            this.props.form.leaseEndDate &&
                            this.props.form.leaseEndDate <
                              moment().startOf("day").toISOString()
                          }
                          required
                          // helperText={
                          //   this.props.form &&
                          //   this.props.form.leaseEndDate &&
                          //   this.props.form.leaseEndDate <
                          //     moment().startOf("day").toISOString() ? (
                          //     <Translate i18nKey="leaseEndDate" />
                          //   ) : (
                          //     ""
                          //   )
                          // }
                          margin="dense"
                          label={<Translate i18nKey="leaseEndDate" />}
                          variant="outlined"
                          fullWidth
                          // minDate={new Date()}
                          value={
                            this.props.form && this.props.form.leaseEndDate
                              ? new Date(
                                  `${this.props.form.leaseEndDate}`
                                ).toString()
                              : null
                          }
                          onChange={this.props.handleChangeLicenseExpDate(
                            "leaseEndDate"
                          )}
                          InputProps={{
                            classes: {
                              root: "theme-cssOutlinedInput",
                              input: "theme-input",
                              focused: "theme-cssFocused",
                              notchedOutline: "theme-notchedOutline",
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "theme-label",
                              focused: "theme-label-focused",
                              shrink: "theme-label-shrink",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="leasePeriod"
                        label={<Translate i18nKey="leasePeriod" />}
                        placeholder=""
                        value={this.props.form?.leasePeriod || ""}
                        onChange={(e) =>
                          this.props.handleChange("leasePeriod", e)
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                  </>
                ) : null}

                </>:""}

                <Grid item xs={12}>
                  <h3 style={{ margin: 0 }} className="with-border page-title">
                    {<Translate i18nKey="tags" />}
                  </h3>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  {this.props.form?.attributes?.tags || ""}
                  <TextField
                    id="tag_1"
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt1 ? (
                        this.props.logInUsers.attributes.vt1
                      ) : (
                        <Translate i18nKey="tag_1" />
                      )
                    }
                    placeholder=""
                    value={this.props.form?.attributes?.tag_1 || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "tag_1",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="tag_2"
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt2 ? (
                        this.props.logInUsers.attributes.vt2
                      ) : (
                        <Translate i18nKey="tag_2" />
                      )
                    }
                    placeholder=""
                    value={this.props.form?.attributes?.tag_2 || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "tag_2",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="tag_3"
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt3 ? (
                        this.props.logInUsers.attributes.vt3
                      ) : (
                        <Translate i18nKey="tag_3" />
                      )
                    }
                    placeholder=""
                    value={this.props.form?.attributes?.tag_3 || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "tag_3",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="tag_4"
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt4 ? (
                        this.props.logInUsers.attributes.vt4
                      ) : (
                        <Translate i18nKey="tag_4" />
                      )
                    }
                    placeholder=""
                    value={this.props.form?.attributes?.tag_4 || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "tag_4",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="tag_5"
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt5 ? (
                        this.props.logInUsers.attributes.vt5
                      ) : (
                        <Translate i18nKey="tag_5" />
                      )
                    }
                    placeholder=""
                    value={this.props.form?.attributes?.tag_5 || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "tag_5",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <h3 style={{ margin: 0 }} className="with-border page-title">
                    {<Translate i18nKey="integration" />}
                  </h3>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <label style={{ position: "static" }}>
                    {<Translate i18nKey="sequenceNumber" />}
                  </label>
                  <TextField
                    id="sequenceNumber"
                    label={<Translate i18nKey="sequenceNumber" />}
                    placeholder=""
                    value={this.props.form?.attributes?.sequenceNumber || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "sequenceNumber",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  <small>
                    <em>{this.state.inquiryResponse}</em>
                  </small>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <label
                    style={{
                      position: "static",
                      marginBottom: 8,
                      display: "block",
                    }}
                  >
                    &nbsp;
                  </label>
                  {waslEnabled && waslCompanyRefKey ? (
                    <Button onClick={this.verify}>
                      Check already registered
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <h4 style={{ marginBottom: 0 }}>
                    {<Translate i18nKey="vehicleLicensePlate" />}
                  </h4>
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 8 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{<Translate i18nKey="plateType" />}</label>
                  <Selector
                    key="plateType"
                    array={plateTypes}
                    multi={
                      this.props.form?.attributes?.plateType &&
                      plateTypes.filter(
                        (e) => this.props.form.attributes.plateType === e.id
                      )
                    }
                    onChange={(e) => this.handleChange("plateType", e)}
                    canAssign={true}
                    canRemove={true}
                  />
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 6 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{<Translate i18nKey="leftLetter" />}</label>
                  <TextField
                    id="leftLetter"
                    label={null}
                    placeholder=""
                    value={this.props.form?.attributes?.leftLetter || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "leftLetter",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 4 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{<Translate i18nKey="middleLetter" />}</label>
                  <TextField
                    id="middleLetter"
                    label={null}
                    placeholder=""
                    value={this.props.form?.attributes?.middleLetter || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "middleLetter",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 2 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{<Translate i18nKey="rightLetter" />}</label>
                  <TextField
                    id="rightLetter"
                    label={null}
                    placeholder=""
                    value={this.props.form?.attributes?.rightLetter || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "rightLetter",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <label>{<Translate i18nKey="number" />}</label>
                  <TextField
                    id="number"
                    label={null}
                    placeholder=""
                    value={this.props.form?.attributes?.number || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "number",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
              {this.state.errorResponse ? (
                <p>WASL ERROR: {this.state.errorResponse}</p>
              ) : null}
              {this.props.vehicleStatus ? (
                <ul className="process-list">
                  <li>Vehicle Status: {this.props?.vehicleStatus || ""}</li>
                </ul>
              ) : null}
            </form>
          }
        />
      </div>
    );
  }
}
const AddVehicle = withStyles(styles)(withTranslationWrapper(addVehicle));

export default AddVehicle;
