import React, { Fragment, PureComponent } from 'react'
import { Trans as Translate} from 'react-i18next';
import { connect } from 'react-redux'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '../../Components/common/Button'
import Menu from '../../Components/Menu/CommonMenu'

class ExtraMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  
  render () {
    const { anchorEl } = this.state

    return (
      <Fragment>
        <Button
          aria-owns={anchorEl ? 'extra-menu' : undefined}
          aria-haspopup='true'
          className="button-narrow theme-green text-capitalize"
          onClick={this.handleClick}
        >
          <Translate id="more" />
          <span style={{ background: this.props.themecolors.backgroundColor, color: this.props.themecolors.textColor, borderRadius: 20 }}><MoreHorizIcon style={{ fontSize: 26 }} /></span>
        </Button>
        <Menu
          id='extra-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          themecolors={this.props.themecolors}
          className='extra-menu'
        >
          {this.props.children}
        </Menu>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  themecolors: state.themeColors
})

export default connect(mapStateToProps)((ExtraMenu))
