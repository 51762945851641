import React, { Component } from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@mui/material/Grid'
import Autocomplete from '../../../Components/common/Autocomplete'
import { connect } from 'react-redux'
//import CloseIcon from '@mui/icons-material/Close';
//import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import Checkbox from '../../../Components/common/CheckboxPermissions'
import Tooltip from '../../../Components/common/Tooltip'
import parse from 'html-react-parser';import InputAdornment from '@mui/material/InputAdornment'
import instance from '../../../axios'
import { errorHandler } from '../../../Helpers'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { notifySuccess } from '../../../Utils/CustomNotifcations'

class Sensors extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.computedAttributes_Selection = this.computedAttributes_Selection.bind(
      this
    )
  }

  handleChange (name, event) {
    this.props.handleChange(name, event)
  }
  changeAttribute (name, value) {
    this.props.changeAttribute(name, value)
  }

  computedAttributes_Selection (deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId
    }

    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions/`,
      method: 'POST',
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
          //this.props.dispatch(addComputedAttributeDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess("attributeAssignedSuccessfully")
          )
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error,this.props.dispatch)}) 
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       alert(err)
      //     })
      //   }
      // })
  }

  render () {
    const {attribute} = this.props.form
    const disceteSensorsTypes = [
      {
        label: this.props.translate('sensorsTranslation.ignition'),
        value: 'ignition'
      },
      {
        label: this.props.translate('sensorsTranslation.panicButton'),
        value: 'panicButton'
      },
      {
        label: this.props.translate('sensorsTranslation.externalPower'),
        value: 'externalPower'
      },
      {
        label: this.props.translate('sensorsTranslation.enginStatus'),
        value: 'enginStatus'
      },
      {
        label: this.props.translate('sensorsTranslation.carAlarm'),
        value: 'carAlarm'
      },
      {
        label: this.props.translate('sensorsTranslation.doors'),
        value: 'doors'
      },
      {
        label: this.props.translate('sensorsTranslation.custom'),
        value: 'customDiscrete'
      },
      {
        label: this.props.translate('sensorsTranslation.workMode'),
        value: 'workMode'
      },
      {
        label: this.props.translate('sensorsTranslation.devicePowerCut'),
        value: 'devicePowerCut'
      }
    ]
    // let fitlerDisceteSensorsTypes = []
    // if (this.props.computedAttributesDeviceWise.length) {
    //   disceteSensorsTypes.map(item => {
    //     let prsntvalue = false
    //     this.props.computedAttributesDeviceWise.map(item1 => {
    //       if (item.value === item1.attribute) {
    //         prsntvalue = true
    //       }
    //     })
    //     if (!prsntvalue) {
    //       fitlerDisceteSensorsTypes.push(item)
    //     }
    //   })
    // } else {
    //   fitlerDisceteSensorsTypes = disceteSensorsTypes
    // }
    return (
      <div style={{ minHeight: 300 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id='description'
              label={this.props.translate('sharedDescription')}
              type='text'
              value={parse(this.props.form.description) || ''}
              onChange={e => this.handleChange('description', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id='attribute'
              label={this.props.translate('positionTooltip.sensorsType')}
              type='text'
              // value={this.props.form.attribute ? this.props.form.attribute : ''}
              value={(attribute&&attribute.includes("customDiscrete")) ? 'customDiscrete':attribute ? attribute : ''}
              onChange={e => this.handleChange('attribute', e)}
              variant='outlined'
              margin='dense'
              fullWidth
              select
            >
              {disceteSensorsTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {this.props.form&&this.props.form.attribute ==='customDiscrete' && (
            <Grid item xs={12} md={6} lg={4}>
                <TextField
                  id='index'
                  label={this.props.translate('index')}
                  type='number'
                  value={this.props.form.index ? this.props.form.index : ''}
                  onChange={e => this.changeAttribute('index', e.target.value)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        disableTypography
                        position='end'
                        // style={{
                        //   color: this.props.themecolors.textColor,
                        //   fontSize: 10
                        // }}
                      >
                        {this.props.form.sensorType
                          ? this.props.form.sensorType +
                            '' 
                            (this.props.form.index || '')
                          : ''}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

          )}
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              translate={this.props.translate}
              value={this.props.form.input || ''}
              suggestions={this.props.suggestions}
              handleChange={this.changeAttribute}
              fieldName='input'
              placeholder={this.props.translate('sensorInput')}
              label={this.props.translate('sensorInput')}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id='minThreshold'
              label={this.props.translate('threshold')}
              type='number'
              value={this.props.form.minThreshold}
              onChange={e =>
                this.changeAttribute('minThreshold', e.target.value)
              }
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              classes={{
                popper: 'menu-popper',
                tooltip: 'menu-popper-tooltip'
              }}
              title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
            >
              <Checkbox
                canAssign
                canRemove
                translate={this.props.translate}
                checked={this.props.form.copyFromLast}
                onChange={e =>
                  this.changeAttribute('copyFromLast', e.target.checked)
                }
                label={this.props.translate('copyFromLast')}
              />
            </Tooltip>
          </Grid>
          {this.props.form.attribute === "devicePowerCut" ?
              <>
                <Grid item xs={12} md={6} lg={4} style = {{display:'flex'}}>
                    <Checkbox
                      canAssign
                      canRemove
                      translate={this.props.translate}
                      checked={this.props.form&&this.props.form.applyInverse}
                      onChange={e =>
                        this.changeAttribute('applyInverse', e.target.checked)
                      }
                      label={this.props.translate('applyInverse')}
                    />
                    <div style={{paddingLeft:50}}>
                    <Tooltip 
                        classes={{
                          popper: 'menu-popper',
                          tooltip: 'menu-popper-tooltip'
                        }}
                        title={this.props.translate('applyInverseText')}>
                          <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
                        </Tooltip></div>
                </Grid>
              </>
                : null }
        </Grid>
      </div>
    )
  }
}

const mapState = state => ({
  positions: state.positions,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise
})
const mapStateToProps = connect(mapState)
export default mapStateToProps((Sensors))
