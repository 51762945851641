import React, { Component } from 'react'
import SimpleModalWrapped from '../common/Modal'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Attributes from '../Attributes/attributesModal'
import Grid from '@mui/material/Grid'
;
import { timeZonesTypes } from './../Attributes/timezone'
import { connect } from 'react-redux'
import SingleSelect from '../common/SingleSelect'
import Checkbox from '../common/Checkbox'
import SearchGroups from './../Devices/searchItems'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors,
    groups: state.groups
  }
}

const styles = theme => ({})

class addUserModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      at_modal_visable: false
    }
    this.addAttributes = this.addAttributes.bind(this)
    this.searchFromZoneList = this.searchFromZoneList.bind(this)
  }

  addAttributes () {
    this.setState({
      at_modal_visable: !this.state.at_modal_visable
    })
  }

  async searchFromZoneList (inputValue) {
    if (!inputValue) {
      return []
    }

    let result = timeZonesTypes.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    return result.map(item => {
      return { id: item.key, key: item.key, label: item.label, value: item.key }
    })
  }

  render () {
    const defaultZones = []
    timeZonesTypes.map((item, i) => {
      if (i <= 9) {
        defaultZones.push({
          id: item.key,
          key: item.key,
          label: item.label,
          value: item.key
        })
      }

      return null
    })
    let name = null
    if (
      this.props.form.groupId &&
      this.props.groups &&
      this.props.groups.length
    ) {
      const g = this.props.groups.find(e => e.id === this.props.form.groupId)
      if (g) {
        name = g.name
      }
    }

    return (
      <div>
        <SimpleModalWrapped
          visable={true}
          isButtonVisable={true}
          title={'resources'}
          formSubmit={this.props.formSubmit}
          //addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          btnCloseText='sharedBack'
          btnCancelNotShow
          isVisableBtn={this.props.isVisableUserBtn}
          notShowAttributesBtn
          //attributeChangesMessage={this.props.attributeChangesMessage}
          elevation={this.props.viewScreen === 'edit' ? 0 : 2}
          content={
            <div>
              <Grid container spacing={1}>
                <Grid item xl={3} md={4} sm={6} xs={6}>
                  <TextField
                    id='name'
                    label={this.props.translate('sharedName')}
                    type='text'
                    required
                    placeholder=''
                    value={this.props.form.name || ''}
                    onChange={this.props.handleChange('name')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {/* <Grid
                  item
                  xl={3}
                  md={4}
                  sm={6}
                  xs={12}
                  style={{ position: 'relative', zIndex: 2 }}
                > */}
                  {/* <SearchGroups
                    isMulti={false}
                    api='groups'
                    isClearable
                    name='groupId'
                    onChange={this.props.handleChange2}
                    value={
                      name
                        ? {
                            value: this.props.form.groupId,
                            label: name
                          }
                        : null
                    }
                    placeholder={this.props.translate('sharedSearch')}
                    exclude={[this.props.form.id]}
                  /> */}
                                      {/* <TextField
                                        id="groupId"
                                        select
                                        label="Group"
                                        value={this.props.form.groupId || ""}
                                        variant="outlined"
                                        onChange={this.props.handleChange('groupId')}
                                        margin='dense'
                                        inputProps={{ className: classes.input }}
                                        InputLabelProps={{ classes: { root: classes.label, focused: classes.focused, shrink: classes.shrink } }}
                                        fullWidth
                                      >
                                        <MenuItem value={0}>
                                            <em>None</em>
                                        </MenuItem>
                                        {this.props.groups.map(option => (
                                            <MenuItem key={option.id} value={option.id} className={classes.MenuItem}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                      </TextField> */}
                {/* </Grid> */}
              </Grid>
{/* temprary comment for release 3.2.1 */}
            {/* <h4
                style={{ margin: '10px 0', color: 'inherit' }}
                className='page-title'
              >
                {this.props.translate('sharedAttributes')}
              </h4>
              <Grid container spacing={1}>
                <Grid item xl={3} md={4} sm={6} xs={12}>
                  <SingleSelect
                    array={[]}
                    async
                    selectName='decoder.timezone'
                    isClearable
                    defaultOptions={defaultZones}
                    loadOptions={this.searchFromZoneList}
                    label={this.props.translate('sharedTimezone')}
                    value={
                      this.props.itemAttributes &&
                      this.props.itemAttributes['decoder.timezone']
                        ? timeZonesTypes.find(t => t.key === this.props.itemAttributes['decoder.timezone'])
                        : ''
                    }
                    handleChange={this.props.timezone_handleChange}
                    canAssign={true}
                  />
                </Grid>
                <Grid item xl={3} md={4} sm={6} xs={12}>
                  <TextField
                    id='attributeSpeedLimit'
                    label={
                      this.props.translate('attributeSpeedLimit') + '(Km/h)'
                    }
                    placeholder=''
                    value={
                      (this.props.itemAttributes &&
                        this.props.itemAttributes.speedLimit) ||
                      ''
                    }
                    onChange={e => this.props.changeAttribute2('speedLimit', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Checkbox
                    checked={
                      this.props.itemAttributes['report.ignoreOdometer'] ||
                      false
                    }
                    onChange={e =>
                      this.props.changeAttribute2('report.ignoreOdometer', e)
                    }
                    value={
                      this.props.itemAttributes['report.ignoreOdometer'] ||
                      false
                    }
                    label={this.props.translate(
                      'attributeReportIgnoreOdometer'
                    )}
                  />
                </Grid>
              </Grid>
              */}
            </div>
          }
        />
        {this.state.at_modal_visable ? (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.props.itemAttributes}
            changeAttribute={this.props.changeAttribute}
            formSubmit={this.ud_submit}
            componentType={'group'}
            isHaveAccess={this.props.isHaveAccess}
          />
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps)(
  (withStyles(styles)(addUserModal))
)
