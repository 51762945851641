import React from 'react'
// import classNames from 'classnames'
import clsx from 'clsx';

import PropTypes from 'prop-types'
;
import { withStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Checkbox from './Checkbox'
import Tooltip from './Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { lighten } from '@mui/material/styles'
import isEqual from 'react-fast-compare'
import styled from 'styled-components'
import { connect } from 'react-redux'
import TextField from './SearchField'
import Style from 'style-it'
import { setAttributeFormat } from '../../Helpers'
import moment from 'moment'

let themeColors
const mapStateToProps = (state, ownProps) => {
  themeColors = state.themeColors
  return { themecolors: state.themeColors }
}

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function searchFilter (array, value, colums) {
  var dev = array.filter(item => {
    let exits = false
    colums.map(c => {
      if (
        item[c.id] &&
        typeof item[c.id] !== 'boolean' &&
        typeof item[c.id] !== 'number' &&
        typeof item[c.id] !== 'object' &&
        item[c.id].toLowerCase().indexOf(value) !== -1
      ) {
        exits = true
      }
      return null
    })
    return exits
  })
  return dev
}

function stableSort (array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting (order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

/*const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
];*/

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render () {
    const { order, orderBy, classes, isEditable } = this.props
    return (
      <TableHead>
        <TableRow
          className={classes.tableRow}
          style={{ background: this.props.themecolors.themeLightColor + '80' }}
        >
           {this.props.setSerialNo && <TableCell   
              style={{
                borderColor: this.props.themecolors.textColor,
                color: this.props.themecolors.themeLightInverse,
                padding: '0 10px'
              }}
              > #</TableCell>}
              
          {this.props.showCheckbox && (
            <TableCell
              style={{
                borderColor: this.props.themecolors.textColor,
                color: this.props.themecolors.themeLightInverse,
                padding: '0 10px'
              }}
            />
          )}
          {this.props.rowDefinition.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  borderColor: this.props.themecolors.textColor,
                  borderLeft: '1px solid ' + this.props.themecolors.themeLightColor,
                  padding: '0 10px',
                  color: this.props.themecolors.themeLightInverse,
                }}
              >
                <Tooltip
                  title='Sort'
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
          {isEditable && (
            <TableCell
              key={-5}
              width={60}
              style={{
                padding: '0 10px',
                borderColor: this.props.themecolors.textColor,
                borderLeft: '1px solid ' + this.props.themecolors.themeLightColor,
                color: 'inherit'
              }}
            />
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const toolbarStyles = theme => ({
  root: {
    //paddingRight: theme.spacing(1),
    minHeight: '44px',
    //paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1)
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 auto'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    fontFamily: 'inherit',
    flex: '0 0 auto',
    fontSize: 18,
    fontWeight: 500
  }
})

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    className,
    themecolors,
    column,
    selected,
    actionInHead
  } = props

  const ThemeIconButton = styled(IconButton)`
    background-color: ${props => themecolors.themeLightColor};
    border-radius: 0;
    color: ${props => themecolors.textColor};
    &:hover {
      cursor: pointer;
      background-color: ${props => themecolors.themeLightColor};
    }
  `

  return (
    <Toolbar
      className={
        clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        }) +
        ' ' +
        className
      }
    >
      <div>
        {
          <Typography className={classes.title} id='tableTitle' color='inherit'>
            {props.title}{' '}
            {numSelected > 0 ? '(' + selected[0][column] + ')' : ''}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      {actionInHead && (
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <Tooltip title='Delete'>
              <ThemeIconButton
                aria-label='Delete'
                color='inherit'
                onClick={props.onDelete}
              >
                <DeleteIcon fontSize='small' />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
          {numSelected === 1 ? (
            <Tooltip title='Edit'>
              <ThemeIconButton
                aria-label='Edit'
                onClick={props.onEdit}
                color='inherit'
              >
                <EditIcon fontSize='small' />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
        </div>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)
const styles = theme => ({
  root: {
    width: '100%',
    paddingTop: 44,
    boxShadow: 'none'
  },
  table: {
    minWidth: '100%'
  },
  tableWrapper: {
    overflow: 'auto'
  },
  tableRow: {
    height: 36,
    '&:hover .actionButton': {
      opacity: 1,
      '&:hover': {
        fill: themeColors.themeLightColor
      }
    },
    '& .actionButton': {
      fill: 'currentColor',
      cursor: 'pointer',
      marginLeft: 5,
      opacity: 0
    }
  },
  toolbar: {
    root: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '44px',
      minHeight: 'inherit'
    },
    toolbar: {
      height: '44px',
      minHeight: 'inherit'
    }
  }
})

const TablePaginationStyles = theme => ({
  root: {
    height: '44px',
    minHeight: 'inherit',
    overflow: 'hidden'
    //borderTop: "1px solid rgba(224, 224, 224, 1)"
  },
  actions: {
    color: 'inherit'
  },
  selectIcon: {
    color: 'inherit'
  },
  toolbar: {
    height: '44px',
    minHeight: 'inherit',
    paddingRight: theme.spacing(1)
  }
})

export const TablePaginationStyle = withStyles(TablePaginationStyles)(
  TablePagination
)

class EnhancedTable extends React.Component {
  constructor (props) {
    super(props)
    this.onEdit = this.onEdit.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.makeSearch = this.makeSearch.bind(this)
    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: this.props.rows,
      page: 0,
      rowsPerPage : this.props.rowsPerPage ?  this.props.rowsPerPage : this.props.rows && this.props.rows.length > 50 ? 50 : this.props.rows.length,
      searchValue: ''
    }
  }
  makeSearch (event) {
    this.setState({ searchValue: (''+event.target.value).toLowerCase() })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }))
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, item) => {
    let newSelected = []
    newSelected.push(item)
    if (this.props.onClick) {
      this.props.onClick(item)
    }
    this.setState({ selected: newSelected })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page :0 ,rowsPerPage: event.target.value })
  }

  isSelected = id =>
    (this.state.selected[0] && this.state.selected[0].id === id) || false

  onEdit () {
    this.props.onEdit(this.state.selected)
  }

  onDelete () {
    this.props.onDelete(this.state.selected)
    this.setState({ selected: [] })
  }

  onClose () {
    this.props.onClose()
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state) // equals() is your implementation
  }

  drawValue = (value, type, valueType) => {

    if (type && type === 'any') {
      if (valueType && valueType === 'boolean') {
        if (value === true) {
          return 'Yes'
        }
        return 'No'
      } else {
        return value
      }
    } else if (type && type === 'boolean') {
      if (value === true) {
        return 'Yes'
      }
      return 'No'
    } else {
      return value
    }
  }

  render () {
    const { classes } = this.props
    const { order, orderBy, selected, rowsPerPage, page } = this.state
    const { rows: dataRaw = [] } = this.props
    const data = dataRaw || []
    let serverTimeZoneName = 'Asia/Dubai'
    let vehicle = 'N/A'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone
    }
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    const EnhancedTableToolbarStyled = styled(EnhancedTableToolbar)`
      background: ${props => props.themecolors.themeLightColor};
      color: ${props => props.themecolors.textColor};
      padding-right: ${props => {
        return props.insideDialog ? '44px' : '0'
      }};
      padding-left: ${props => {
        return props.insideDialog ? '44px' : 'def'
      }};
    `

    return (
      <Style>
        {`
          .custom-table tbody th,
          .custom-table tbody td {
            border-color: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
          }
          .custom-table2 tbody th,
          .custom-table2 tbody td {
            border-color: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
          }
        `}
        <Paper
          className={classes.root}
          style={{
            paddingTop: this.props.disableHead ? 0 : null,
            background: 'none'
          }}
        >
          {this.props.disableHead === undefined && (
            <EnhancedTableToolbarStyled
              actionInHead={this.props.actionInHead}
              column={this.props.showInHead}
              selected={this.state.selected}
              insideDialog={this.props.insideDialog}
              themecolors={this.props.themecolors}
              numSelected={selected.length}
              title={this.props.title}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              onClose={this.onClose}
            />
          )}
          <div className={classes.tableWrapper}>
            {this.props.searchable && (
              <div style={{ padding: '10px 10px 0', textAlign: 'right' }}>
                <TextField
                  fullWidth
                  label={this.props.translate('sharedSearch')}
                  onChange={this.makeSearch}
                />
              </div>
            )}
            <Table
              className={classes.table + ' custom-table'}
              aria-labelledby='tableTitle'
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                setSerialNo={this.props.setSerialNo}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                isEditable={this.props.isEditable}
                rowCount={data.length}
                showCheckbox={this.props.showCheckbox}
                themecolors={this.props.themecolors}
                rowDefinition={
                  this.props.rowDefinition || [
                    {
                      id: 'name',
                      numeric: false,
                      disablePadding: true,
                      label: 'Name'
                    }
                  ]
                }
                classes={classes}
              />
              <TableBody>
                {searchFilter(
                  stableSort(data, getSorting(order, orderBy)),
                  this.state.searchValue,
                  this.props.rowDefinition
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n, syCount) => {
                    const isSelected = this.isSelected(n.id)
                    return (
                      <TableRow
                        hover
                        onClick={
                          this.props.isRowSelectionEnable
                            ? event => this.handleClick(event, n)
                            : () => void 0
                        }
                        role='checkbox'
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                        className={classes.tableRow}
                      >

                          {this.props.setSerialNo && (
                              <TableCell
                              style={{color: this.props.themecolors.textColor,}}>
                                <div>
                                  {(syCount + 1)}
                                </div>
                              </TableCell>
                            )}
                        {this.props.showCheckbox && (
                          <TableCell
                            component='th'
                            padding='checkbox'
                            width='28'
                            style={{
                              padding: 0,
                              textAlign: 'center',
                              whiteSpace: 'nowrap',
                              color: this.props.themecolors.textColor,
                              borderBottom:
                                '1px solid ' +
                                this.props.themecolors.textColor +
                                '80'
                            }}
                          >
                            <Checkbox
                              checked={n.check}
                              onChange={this.props.checkHandleChange.bind(
                                this,
                                n
                              )}
                            />
                          </TableCell>
                        )}
                        {this.props.rowDefinition.map((r, i) => {
                          return (
                            r.id==='bookingStatus' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {                          
                                n.bookingStatus===1?
                                this.props.translate('Success'):
                                n.bookingStatus===0?
                                this.props.translate('Booking'):
                                this.props.translate('Fail')
                                // ):
                            }
                         </TableCell>):
                            r.id==='vehicleLicensePlate' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                                  background: n &&n.attributes&&n.attributes.invalidList&&n.attributes?.invalidList?.includes(r.id) ? "red" : ''
                              }}
                          >
                            {n?.attributes?.vehicleLicensePlate || n?.vehicleLicensePlate || ''}
                         </TableCell>):
                            r.id==='make' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                              }}
                          >
                            {n?.attributes?.make || ''}
                         </TableCell>):
                            r.id==='model' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                              }}
                          >
                            {n?.attributes?.model || ''}
                         </TableCell>):
                            r.id==='capacity' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                              }}
                          >
                            {n?.attributes?.capacity || ''}
                         </TableCell>):
                            r.id==='vin' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                                  background: n &&n.attributes&&n.attributes.invalidList&&n.attributes?.invalidList?.includes(r.id) ? "red" : ''
                              }}
                          >
                            {n?.vin || ''}
                         </TableCell>):
                            r.id==='garage' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                              }}
                          >
                            {n?.garage || ''}
                         </TableCell>)
                         :
                         r.id==='vehicleStatus' ? (<TableCell
                         key={i}
                         component='td'
                         scope='row'
                         style={{
                          padding: '0 10px',
                          whiteSpace: 'nowrap',
                          backgroundColor:n?.attributes?.status === 'active' || 
                                          n.attributes.status === "inactive"  ? 
                                          "":"red",
                          color : n?.attributes?.status === 'active' ? "green" : 
                          n.attributes.status === "inactive"  ? "red" :"white",
                          // color: this.props.themecolors.textColor,
                          borderLeft:
                            '1px solid ' +
                            this.props.themecolors.themeLightColor +
                            '66',
                          borderBottom:
                            '1px solid ' +
                            this.props.themecolors.textColor +
                            '80',
                        }}
                       >
                        {n?.attributes?.status.charAt(0).toUpperCase() + n?.attributes?.status.slice(1)}
                         {/* {n?.attributes?.status || ''} */}
                      </TableCell>):
                            r.id==='deliveryDate' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                              }}
                          >
                            {n?.deliveryDate ? new Date(n.deliveryDate).toLocaleDateString() : ''}
                         </TableCell>):
                            r.id==='leaseStartDate' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                              }}
                          >
                            {n?.leaseStartDate ? new Date(n.leaseStartDate).toLocaleDateString() : ''}
                         </TableCell>):
                            r.id==='leaseEndDate' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                              }}
                          >
                            {n?.leaseEndDate ? new Date(n.leaseEndDate).toLocaleDateString() : ''}
                         </TableCell>):
                          r.id==='status' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {                          
                                n.status==='expired'?
                                this.props.translate('Overdue'):
                                n.status==='required'?
                                this.props.translate('Due'):
                                n.status==='completed'?
                                this.props.translate('completed'):
                                this.props.translate('Scheduled')
                                // ):
                            }
                         </TableCell>):
                            r.id==='cost' ?(<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {n.cost + ' '+(this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.currencyLabel || this.props.translate("aed"))}
                         </TableCell>):
                            r.id==='expenseCollection' ?(<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {n.expenseCollection&&n.expenseCollection.map((item,index)=>{
                              return(
                                <>
                                  {syCount === 0 ? (
                                    <TableHead>
                                       <TableRow
                                          className={classes.tableRow}
                                            style={{ background:'#009df980'}}
                                        >
                                        <TableCell
                                            key={'Service'}
                                            align={'left'}
                                            padding={ 'default'}
                                            sortDirection={false}
                                            style={{
                                            borderColor: this.props.themecolors.textColor,
                                            borderLeft: '1px solid ' + this.props.themecolors.themeLightColor,
                                            padding: '0 10px',
                                            color: this.props.themecolors.themeLightInverse,
                                            }}
                                        >
                                                {'Expense Type'}
                                            </TableCell>
                                        <TableCell
                                            key={'SubType'}
                                            align={'left'}
                                            padding={ 'default'}
                                            sortDirection={false}
                                            style={{
                                            borderColor: this.props.themecolors.textColor,
                                            borderLeft: '1px solid ' + this.props.themecolors.themeLightColor,
                                            padding: '0 10px',
                                            color: this.props.themecolors.themeLightInverse,
                                            }}
                                        >
                                                {'SubType'}
                                            </TableCell>
                                      </TableRow>
                                    </TableHead>
                                   ) : null}

                                  {item&& item.map((it,index)=>{
                                      return(
                                          <>
                                          <TableRow
                                              hover
                                              role='checkbox'
                                              aria-checked={isSelected}
                                              tabIndex={-1}
                                              key={n.id}
                                              selected={isSelected}
                                              className={classes.tableRow}
                                          >
                                          <TableCell key={index}
                                              component='td'
                                              scope='row'
                                              style={{
                                                  padding: '0 10px',
                                                  whiteSpace: 'nowrap',
                                                  color: this.props.themecolors.textColor,
                                                  borderLeft:
                                                  '1px solid ' +
                                                  this.props.themecolors.themeLightColor +
                                                  '66',
                                                  borderBottom:
                                                  '1px solid ' +
                                                  this.props.themecolors.textColor +
                                                  '80',
                                                  background: it&&it.attributes&&it?.attributes?.invalid?.includes('expenseType') ? "red" : ''                                                }} >
                                                      {it?.expenseType || ''}
                                              </TableCell>  
                                              <TableCell 
                                                      key={index}
                                                      component='td'
                                                      scope='row'   style={{
                                                      padding: '0 10px',
                                                      whiteSpace: 'nowrap',
                                                      color: this.props.themecolors.textColor,
                                                      borderLeft:
                                                      '1px solid ' +
                                                      this.props.themecolors.themeLightColor +
                                                      '66',
                                                      borderBottom:
                                                      '1px solid ' +
                                                      this.props.themecolors.textColor +
                                                      '80',
                                                      background: it&&it.attributes&&it?.attributes?.invalid?.includes('subTypeLabel') ? "red" : ''
                                                      }}>
                                                      {it?.subTypeLabel || ''}
                                                  </TableCell>  
                                          </TableRow>
                                          </>
                                      )
                                  })}
                                </>
                              )
                              })
                            }
                         </TableCell>):
                            <TableCell
                              key={i}
                              component='td'
                              scope='row'
                              style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: this.props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  this.props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  this.props.themecolors.textColor +
                                  '80',
                                  background: n &&n.attributes&&n.attributes.invalidList&&n.attributes?.invalidList?.includes(r.id) ? "red" : ''
                              }}
                            >
                            {r.id===('odometer')?( setAttributeFormat ('mileage', n.odometer)
                            ):
                            r.id===('engineHours')?((n.engineHours/(1000*3600)).toFixed(2) + ' hours'
                            ):
                            r.id === ('serviceTime') ? moment(n.serviceTime).tz(serverTimeZoneName).format('DD-MM-YYYY')
                             : this.drawValue(n[r.id], r.type, n.valueType, n.attributes)}
                            </TableCell>
                          )
                        })}
                        {this.props.isEditable && (
                          <TableCell
                            component='th'
                            scope='row'
                            style={{
                              padding: '0 10px',
                              whiteSpace: 'nowrap',
                              color: this.props.themecolors.textColor,
                              borderLeft:
                                '1px solid ' +
                                this.props.themecolors.themeLightColor +
                                '66',
                              borderBottom:
                                '1px solid ' +
                                this.props.themecolors.textColor +
                                '80'
                            }}
                          >
                            {this.props.canUpdate && (
                              <Tooltip title='Edit'>
                                <EditIcon
                                  className='actionButton'
                                  fontSize='small'
                                  onClick={e => this.props.onEdit([n])}
                                />
                              </Tooltip>
                            )}
                            {this.props.canDelete && (
                              <Tooltip title='Delete'>
                                <DeleteIcon
                                  className='actionButton'
                                  fontSize='small'
                                  onClick={e => this.props.onDelete([n])}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 36 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <TablePaginationStyle
            rowsPerPageOptions={[5, 10, 15]}
            component='div'
            style={{color: this.props.themecolors.textColor}}
            count={
              searchFilter(
                data,
                this.state.searchValue,
                this.props.rowDefinition
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                padding: 6
              }
            }}
            height={44}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                padding: 6
              }
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Style>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(
  (withStyles(styles)(EnhancedTable))
)
