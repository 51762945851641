import React from "react";
import { Trans as Translate} from 'react-i18next';
import { ReactComponent as DriverIcon } from './../../../assets/dashboard/driver.svg'
import { ReactComponent as AssignedIcon } from './../../../assets/dashboard/assigned.svg'
import { ReactComponent as UnAssignedIcon } from './../../../assets/dashboard/not-assigned.svg'
import { ReactComponent as UnknownIcon } from './../../../assets/dashboard/unknown.svg'
import { ReactComponent as NotRegistered } from './../../../assets/dashboard/not-registered.svg'
import { connect } from "react-redux";
import moment from "moment";
import Card from "../../Dashboard/components/Card/CardPremium";
import Scrollbar from "react-scrollbars-custom";
const ComponentBase = React.memo(({themecolors, gadget, drivers, positions, allComputedAttributes, handleItem})=> {
    const ids = []
    const drIds = []
    const inactive = []
    positions.map(pos => {
        if (
        pos.attributes.driverUniqueId &&
        allComputedAttributes &&
        allComputedAttributes.length &&
        allComputedAttributes.includes(pos.deviceId) &&
        pos.attributes.driverUniqueId !== '0'
        ) {
        if (
            !moment(pos.serverTime).isBefore(moment().subtract(10, 'minutes'))
        ) {
            ids.push(pos.attributes.driverUniqueId)
        } else {
            inactive.push(pos.attributes.driverUniqueId)
        }
        }
        return null
    })
    drivers.map(dr => {
        if (dr && dr.uniqueId && dr.uniqueId !== '0') {
        drIds.push(dr.uniqueId)
        }
        return null
    })

    const assigned = drIds.filter(d => ids.includes(d))
    const unassigned = drIds.filter(d => !ids.includes(d))
    const unknown = ids.filter(d => !drIds.includes(d))
    const total = assigned.length + unassigned.length + unknown.length + inactive.length

    return (<Card themecolors={themecolors}>
        <h3 className="cart-title">{gadget.name || <Translate id='sharedDrivers' />}</h3>
        <Scrollbar disableTracksWidthCompensation>
        <h3>
            <span>
            <DriverIcon style={{
                verticalAlign: 'middle',
                marginRight: 10,
                width: 40,
                display: 'inline-block'
            }} />
            <span
                style={{
                fontSize: 30,
                verticalAlign: 'middle',
                marginRight: 10,
                display: 'inline-block'
                }}
            >
                {total}
            </span>
            <span
                style={{
                display: 'inline-block',
                verticalAlign: 'middle'
                }}
            >
                <Translate id='sharedDrivers' />
            </span>
            </span>
        </h3>
        <div className='driver-state-wrap'>
            <div className='driver-state-container'>
                <div
                    className='driver-state hover'
                    onClick={e =>handleItem && handleItem(assigned,'driver','assigned')}
                >
                    <AssignedIcon width={32} height={32} fill="currentColor" />
                    <h3>{assigned.length}</h3>
                    <p><Translate id='assigned' /></p>
                </div>
                <div
                    className='driver-state hover'
                    onClick={e =>handleItem && handleItem(unassigned,'driver','unassigned')}
                >
                    <UnAssignedIcon width={32} height={32} fill="currentColor" />
                    <h3>{unassigned.length}</h3>
                    <p><Translate id='unassigned' /></p>
                </div>
                <div
                    className='driver-state hover'
                    onClick={e =>handleItem && handleItem(unknown,'driver','unknown')}
                >
                    <NotRegistered width={32} height={32} fill="currentColor" />
                    <h3>{unknown.length}</h3>
                    <p><Translate id='unknown' /></p>
                </div>
                <div
                    className='driver-state hover'
                    onClick={e =>handleItem && handleItem(inactive,'driver','nosignal')}
                >
                    <UnknownIcon width={32} height={32} fill="currentColor" />
                    <h3>{inactive.length}</h3>
                    <p><Translate id='nosignal' /></p>
                </div>
            </div>
        </div>
        </Scrollbar>
    </Card>);

});

const mapStateToProps = state => ({
    themecolors: state.themeColors,
    drivers: state.drivers,
    positions: state.positions,
    allComputedAttributes: state.allComputedAttributes
})  

export const Component = connect(mapStateToProps)(ComponentBase)
