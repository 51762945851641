import React from "react";
import Scrollbar from "react-scrollbars-custom";
import Style from "style-it";


export default function Card(props) {
  const { children, ...rest } = props;
  return (
    <Style>{`
      .c-card{
        width: 100%;
        height: 100%;
        border: 0;
        display: flex;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        flex-direction: column;
        overflow: hidden;
      }
      .c-card-label {
        font-size: 16px;
        text-align: right;
      }
      .c-card-count {
        margin-top: 0px;
        min-height: 1.5em;
        font-weight: 900;
        margin-bottom: 3px;
        text-decoration: none;
        font-size: 57px;
        text-align: right;
        
      }
      .card-footer svg {
        top: 4px;
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
      }
      .card-footer {
        margin: 20px 15px 10px;
        display: flex;
        padding: 0;
        align-items: center;
        padding-top: 10px;
        border-radius: 0;
        justify-content: space-between;
        background-color: transparent;
        border-top: 1px solid #eee;
        color: #999;
        font-size: 12px;
        line-height: 22px;
      }
      .c-card-header {
        font-size: 18px;
        padding: 14px;
      }
    `}
    <div className="c-card" {...rest}>
      {children}
    </div>
    </Style>
  );
}

