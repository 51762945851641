import React, { Component } from "react";
import styles from "./invoice-style.scss";
import { Grid } from "@mui/material";
import instance from "../../axios";
import moment from "moment";
import Loader from "../../Layout/Loader";

let selectedInvoice = false;
let serverTimeZoneName = "";
 
class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      data1: {},
      name: "",
      loader: true,
    };
  }
  componentWillMount() {
    var baseUrl = window.location.origin;
    const queryString = window.location.search;

    var res = queryString.split("?&token=");
    let invoiceId = res[1].split("&leasing=");
    let sz = invoiceId[1].split("&timezone=");
    selectedInvoice = sz[0] === "true" ? "leaseinvoices" : "invoices";
    serverTimeZoneName = sz[1]

    // api/invoices/4121?contractNumber=PE150
    this.setState(
      {
        loader: true,
      },
      () => {
    instance({
      method: "GET",
      url: `/api/deliverynotes/${invoiceId[0]}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.setState({ data1: response , loader: false}, () => {
             
          if (selectedInvoice == "leaseinvoices" && this.state.data1?.invoiceType === 2) {
            let Taxable = 0;
            let VAT = 0;
            let Amount = 0;

            // saleItemPrice , 15, saleVATAmount , saleTotalAmount
            response.items &&
              response.items.map((item, index) => {
                Taxable = Taxable + item.saleItemPrice;
                VAT = VAT + item.saleVATAmount;
              });

            this.setState({
              leasingInvoiceSummary: {
                noOfInvoices:
                  response.items && response.items.length
                    ? response.items.length
                    : 0,
                TaxableAMT: response?.saleInvoicePrice || 0,
                VAT: response?.saleVATAmount || 0,
                totalAmount: response?.saleNetAmount || 0,
              },
            });
          }
        });
      })
      .catch((error) => {
        console.log("error ====", error);
      });
    })
  }

   replaceAmpersands(obj) {
    for (const prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].replace(/amp;/g, '');
      } else if (typeof obj[prop] === 'object') {
        this.replaceAmpersands(obj[prop]);
      }
    }
  }

  render() {
    const { data1, leasingInvoiceSummary } = this.state;
    let data = data1
    this.replaceAmpersands(data);
    
   
    if (data) {
      const Item = data && data.items;
      let packagesLength = 0;
      let servicesLength = 0;
      let ItemsLength = 0;
      Item &&
        Item.map((item) => {
          if (item.categoryName === "packages")
            packagesLength = packagesLength + 1;
          else if (item.categoryName === "services")
            servicesLength = servicesLength + 1;
          else if (item.categoryName === "items") 
          ItemsLength = ItemsLength + 1;
          else ItemsLength = ItemsLength + 1;
        });

      let packageVATAmount = 0;
      let packageNetPrice = 0;

      let serviceVATAmount = 0;
      let serviceNetPrice = 0;

      let itemsVATAmount = 0;
      let itemsNetPrice = 0;
      let globalIndex = 1;
      
      let serviceIndex = 1;
      let packageIndex = 1;
      let itemsIndex = 1;
    
      const decodedHtml = data&&data.attributes&&data.attributes.c_accountName&&data.attributes.c_accountName
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");

      return (
        <Grid id="invoiceholder">
          {this.state.loader ? <Loader/> : 
          <Grid id="invoice" class="effect2">
            <Grid container spacing={1} style={{ alignItems: "center" }}>
              <Grid item md={5}>
                <img style={{ width: "200px" }} src="/logo.png" />
              </Grid>
              <Grid item md={7} style={{ paddingRight: 20 }}>
                <h5 class="head3" style={{marginLeft:"20px"}}>  إشعار إنجاز</h5>
                <h5 class="head3" >
                   DELIVERY NOTE
                </h5>
              </Grid>
            </Grid>
            <Grid class="invoice-mid " style={{ border: "1px solid black" }}>
              <Grid container>
                <Grid item md={6} style={{ padding: "12px 0 0 12px" }}>
                  <p class="ptag">
                    <strong>National Transportation Solutions Company </strong>
                  </p>
                  <p class="ptag">P.O. BOX 1432, Al Mohammadiyyah</p>
                  <p class="ptag">Jeddah, 21431, Kingdom of Saudi Arabia</p>
                  <p class="ptag">VAT number of Supplier: 300000603210003</p>
                  <p class="ptag">Phone No: +966 92000 8206</p>
                  <p class="ptag">Email Address: info@ntsc.sa</p>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ textAlign: "right", padding: "12px 12px 0 0" }}
                >
                  <p class="ptag">
                    <strong>شركة الوطنية لحلول النقل المحدودة </strong>
                  </p>
                  <p class="ptag">ص.ب.1432 المحمدية</p>
                  <p class="ptag">جدة ,21431 ,المملكة العربية السعودية</p>
                  <p class="ptag">الرقم الضريبي للمنشأة: 300000603210003 </p>
                  <p class="ptag"> +966 92000 8206 : رقم الهاتف</p>
                  <p class="ptag">info@ntsc.sa : البريد الإلكتروني</p>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  border: "1px solid black",
                  margin: "10px 0 0 0",
                  padding: 1,
                }}
              >
                <Grid item md={6} style={{ padding: 10 }}>
                  <strong class="titleHead">
                    CUSTOMER AND VEHICLE DETAILS
                  </strong>
                </Grid>
                <Grid item md={6} style={{ padding: 6, textAlign: "end" }}>
                  <strong class="titleHead"> بيانات العميل والمركبة</strong>
                </Grid>
              </Grid>
               
              {selectedInvoice === "leaseinvoices" && data?.invoiceType === 2 ? (
                <>
                  <Grid class="row m-0 p-1">
                    <table style={{ fontSize: 20 }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td >Name/ Company Name :</td>
                              <td class="text-center" 
                              // dangerouslySetInnerHTML={{ __html: decodedHtml }}
                              >
                                {decodedHtml || []}
                                {/* {data?.attributes?.c_accountName || ""} */}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_nameArabic || ""}
                              </td>
                              <td class="text-right">: الاسم / اسم الشركة</td>
                            </tr>
                            <tr>
                              <td>Phone No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_contact || ""}
                              </td>
                              <td class="text-right">رقم الهاتف</td>
                            </tr>
                            <tr>
                              <td>Email Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_email || ""}
                              </td>
                              <td class="text-right"> البريد الإلكتروني </td>
                            </tr>
                            <tr>
                              <td>Customer Location :</td>
                              <td class="text-center">
                                {data?.attributes?.c_Location || ""}
                              </td>
                              <td class="text-right">: موقع العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Tax Number :</td>
                              <td class="text-center">
                                {data?.attributes?.c_taxNumber || ""}
                              </td>
                              <td class="text-right">: الرقم الضريبي للعميل</td>
                            </tr>
                            <tr>
                              <td>Customer Building No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_buildingNo || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_buildingNoArabic || ""}
                              </td>
                              <td class="text-right">: رقم بناء العميل </td>
                            </tr>
                          </table>
                        </td>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td>Customer City :</td>
                              <td class="text-center">
                                {data?.attributes?.c_city || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                  {data?.attributes?.c_cityArabic}
                              </td>
                              <td class="text-right">: المدينة</td>
                            </tr>
                            <tr>
                              <td>Customer Country :</td>
                              <td class="text-center">
                                {data?.attributes?.c_country || "Saudi Arabia"}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_countryArabic}
                              </td>
                              <td class="text-right">: الدولة </td>
                            </tr>
                            <tr>
                              <td>Customer Postal Code :</td>
                              <td class="text-center">
                                {data?.attributes?.c_postalCode || ""}
                              </td>
                              <td class="text-right">: الرمز البريدي للعميل</td>
                            </tr>
                            <tr>
                              <td>Customer Additional No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_additionalNo || ""}
                              </td>
                              <td class="text-right">: رقم العميل الإضافي </td>
                            </tr>
                            <tr>
                              <td>Customer Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_address || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_addressArabic}
                              </td>
                              <td class="text-right">: عنوان العميل</td>
                            </tr>
                            <tr>
                              <td>Customer Street Name :</td>
                              <td class="text-center">
                                {data?.attributes?.c_streetName || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_streetNameArabic}
                              </td>
                              <td class="text-right">: الشارع </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid class="row m-0 p-1">
                    <table style={{ fontSize: 20 }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td>Name/ Company Name :</td>
                              <td class="text-center">
                                {data?.attributes?.c_accountName || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_nameArabic || ""}
                              </td>
                              <td class="text-right">: الاسم / اسم الشركة</td>
                            </tr>
                            <tr>
                              <td>Phone No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_contact || ""}
                              </td>
                              <td class="text-right">: رقم الهاتف</td>
                            </tr>
                            <tr>
                              <td>Email Address:</td>
                              <td class="text-center">
                                {data?.attributes?.c_email || ""}
                              </td>
                              <td class="text-right">: البريد الإلكتروني</td>
                            </tr>
                            <tr>
                              <td>Customer Location :</td>
                              <td class="text-center">
                                {data?.attributes?.c_Location || ""}
                              </td>
                              <td class="text-right">: موقع العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Tax Number :</td>
                              <td class="text-center">
                                {data?.attributes?.c_taxNumber || ""}
                              </td>
                              <td class="text-right">
                                : الرقم الضريبي للعميل{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Customer Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_address || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_addressArabic}
                              </td>
                              <td class="text-right">: عنوان العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Building No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_buildingNo || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_buildingNoArabic || ""}
                              </td>
                              <td class="text-right">: رقم بناء العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Street Name :</td>
                              <td class="text-center">
                                {data?.attributes?.c_streetName || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_streetNameArabic}
                              </td>
                              <td class="text-right">: الشارع </td>
                            </tr>
                          </table>
                        </td>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td>Customer City :</td>
                              <td class="text-center">
                                {data?.attributes?.c_city || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                  {data?.attributes?.c_cityArabic}
                              </td>
                              <td class="text-right">: المدينة</td>
                            </tr>
                            <tr>
                              <td>Customer Country :</td>
                              <td class="text-center">
                                {data?.attributes?.c_country || "Saudi Arabia"}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_countryArabic}
                              </td>
                              <td class="text-right">: الدولة</td>
                            </tr>
                            <tr>
                              <td>Customer Postal Code :</td>
                              <td class="text-center">
                                {data?.attributes?.c_postalCode || ""}
                              </td>
                              <td class="text-right">: الرمز البريدي للعميل</td>
                            </tr>
                            <tr>
                              <td>Customer Additional No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_additionalNo || ""}
                              </td>
                              <td class="text-right">: رقم العميل الإضافي</td>
                            </tr>
                            <tr>
                              <td>Plate Number :</td>
                              <td class="text-center">
                                {data?.plateNumber || ""}
                              </td>
                              <td class="text-right">:رقم اللوحة </td>
                            </tr>
                            <tr>
                              <td>Model :</td>
                              <td class="text-center">
                                {data?.attributes?.model || ""}
                              </td>
                              <td class="text-right">: موديل</td>
                            </tr>
                            <tr>
                              <td>VIN :</td>
                              <td class="text-center">
                                {data?.attributes?.vin || ""}
                              </td>
                              <td class="text-right">: رقم الشاسيه</td>
                            </tr>
                            <tr>
                              <td>Mileage Reading :</td>
                              <td class="text-center">
                                {data?.odometerReading || 0}
                              </td>
                              <td class="text-right">: قراءة العداد</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              )}

              <Grid
                container
                style={{
                  border: "1px solid black",
                  margin: "10px 0 0 0",
                  padding: 1,
                }}
              >
                <Grid item md={2}>
                  <img
                    class="logo"
                    src={data?.qrCodeUrl || ""}
                    style={{ width: 130 }}
                  />
                </Grid>
                <Grid item md={10} style={{ padding: 1, marginTop: 15 }}>
                  <table>
                    <tr>
                      <td class="w-25">DeliveryNote No:</td>
                      <td class="text-center w-25">
                        {data?.deliveryNoteNumber || ""}
                      </td>
                      <td
                        class="text-right w-25"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        : رقم الفاتورة
                      </td>
                    </tr>
                    {/* {selectedInvoice === "leaseinvoices" ? ( */}
                    <tr>
                      <td>DeliveryNote Date:</td>
                      {selectedInvoice === "leaseinvoices"  ? (
                        <td class="text-center">
                          {/* {data?.invoiceDate || ""} */}

                          {data && data.invoiceDate
                            ? 
                            moment.utc(data.invoiceDate)
                            .tz(serverTimeZoneName)
                            .format("YYYY-MM-DD HH:mm:ss")
                            // moment(data.invoiceDate)
                            // // .tz(serverTimeZoneName)
                            // .format("DD-MM-YYYY HH:mm:ss")
                            : ""}
                        </td>
                      ) : (
                        <td class="text-center w-25">
                          {/* {data?.serviceDate || ""} */}
                          {data && data.serviceDate
                            ? moment.utc(data.serviceDate)
                                .tz(serverTimeZoneName)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : ""}
                        </td>
                      )}
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        : تاريخ الفاتورة
                      </td>
                    </tr>

                    <tr>
                      <td>Contract name:</td>
                      <td class="text-center">
                        {data?.attributes?.c_contractName || ""}
                      </td>
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        : اسم العقد/ معرف المرجع
                      </td>
                    </tr>
                    <tr>
                      <td>Contract No:</td>
                      <td class="text-center">{data?.contractNumber?.toUpperCase() || ""}</td>
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        رقم العقد
                      </td>
                    </tr>
                    {selectedInvoice !== "leaseinvoices" ||   data?.invoiceType === 1 ? (
                      <>
                        <tr>
                          <td>Booking ID / Customer Ref #</td>
                          <td class="text-center">
                            {data?.serviceId  ? (
                              <>
                                {data?.serviceId || ""}/{" "}
                                {data?.serviceRefNumber}
                              </>
                            ) : (
                              <>{data?.serviceRefNumber || ""}</>
                            )}
                          </td>

                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            معرف الحجز / الرقم المرجعي العملاء
                          </td>
                        </tr>
                        <tr>
                          <td>DeliveryNote Ref #: </td>
                          <td class="text-center">
                            {data?.deliveryNoteRefNumber || ""}
                          </td>

                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            :  رقم المرجعي-المحطة
                          </td>
                        </tr>
                        <tr>
                          <td>WorkOrder #: </td>
                          <td class="text-center">
                            {data?.workOrderNumber || ""}
                          </td>

                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            :  # أمر العمل 
                          </td>
                        </tr>
                      </>
                    ) : null}

                    <tr>
                      <td>Credit Period:</td>
                      <td class="text-center">
                        {data?.attributes?.c_creditPeriod || 0}
                      </td>
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        فترة الائتمان
                      </td>
                    </tr>
                    {selectedInvoice !== "leaseinvoices"  ||  data?.invoiceType === 1 ? (
                      <>
                        {" "}
                        <tr>
                          <td>Station Id:</td>
                          <td class="text-center">{data?.stationCode || ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            معرف المحطة
                          </td>
                        </tr>
                        <tr>
                          <td>Station Name:</td>
                          <td class="text-center">{data?.stationName || ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            اسم المحطة
                          </td>
                        </tr>
                        <tr>
                          <td>Station Address:</td>
                          <td class="text-center">{data?.stationCity || ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            عنوان المحطة
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </table>
                </Grid>
              </Grid>
              {selectedInvoice === "leaseinvoices"  &&  data?.invoiceType === 2 ? (
                <>
                  <Grid container style={{ marginBottom: 5 }}>
                    <table>
                      <tr
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td class="tableData">
                          <strong>رقم التسلسلي </strong>
                          <br />
                          <strong> SL NO</strong>
                        </td>
                        <td class="tableData">
                          <strong>عدد الفواتير </strong>
                          <br />
                          <strong> Number of vehicles</strong>
                        </td>
                        <td class="tableData">
                          <strong> المبلغ الخاضع للضريبة </strong>
                          <br />
                          <strong>Taxable AMT </strong>
                        </td>
                        <td class="tableData">
                          <strong>معدل الضريبة </strong>
                          <br />
                          <strong>Tax Rate</strong>
                        </td>
                        <td class="tableData">
                          <strong>نسبة القيمه المضافه </strong>
                          <br />
                          <strong>VAT% </strong>
                        </td>
                        <td class="tableData">
                          <strong> (ريال سعودي) المبلغ </strong>
                          <br />
                          <strong>Amount (SAR)</strong>
                        </td>
                      </tr>

                      <tr>
                        <td class="tableData" style={{ padding: 2 }}>
                          {1}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.noOfInvoices
                            ? leasingInvoiceSummary.noOfInvoices.toFixed(2)
                            : 0}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.TaxableAMT
                            ? leasingInvoiceSummary.TaxableAMT.toFixed(2)
                            : ""}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {15}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary && leasingInvoiceSummary.VAT
                            ? leasingInvoiceSummary.VAT.toFixed(2)
                            : ""}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.totalAmount
                            ? leasingInvoiceSummary.totalAmount.toFixed(2)
                            : ""}
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : null}
   
              {selectedInvoice === "leaseinvoices" &&    data?.invoiceType === 2 &&
              data.items &&
              data.items.length ? (
                <>
                  <Grid container>
                    <table>
                      <tr
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td class="tableData">
                          <strong>رقم التسلسلي </strong>
                          <br />
                          <strong>SL NO</strong>
                        </td>
                        <td class="tableData">
                          <strong> نوع المركبة/رقم</strong>
                          <br />
                          <strong>VEHICLE NUMBER</strong>
                        </td>
                        <td class="tableData">
                          <strong>الخدمة المقدمة </strong>
                          <br />
                          <strong>DESCRIPTION</strong>
                        </td>
                        <td class="tableData">
                          <strong>موديل </strong>
                          <br />
                          <strong>MODEL</strong>
                        </td>
                        <td class="tableData">
                          <strong>عدد</strong>
                          <br />
                          <strong># OF DAYS</strong>
                        </td>
                        <td class="tableData">
                          <strong>الإيجار الشهري </strong>
                          <br />
                          <strong>MONTHLY RENTAL</strong>
                        </td>
                        <td class="tableData">
                          <strong>لمبلغ الخاضع للضريبة </strong>
                          <br />
                          <strong>TAXABLE AMOUNT</strong>
                        </td>
                        <td class="tableData">
                          <strong>معدل الضريبة</strong>
                          <br />
                          <strong>TAX RATE</strong>
                        </td>
                        <td class="tableData">
                          <strong>ضريبه القيمه المضافه </strong>
                          <br />
                          <strong> VAT%</strong>
                        </td>
                        <td class="tableData">
                          <strong>مجموع </strong>
                          <br />
                          <strong> TOTAL </strong>
                        </td>
                      </tr>
                      {data.items &&
                        data.items.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {index + 1}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.plateNumber || ""}
                                </td>
                                <td class="tableData" style={{ width: 160 }}>
                                  {item?.itemDescription ||
                                    "Monthly fleet lease  الإيجار الشهري"}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.model || ""}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.noOfDays || 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleItemPrice
                                    ? item.saleItemPrice.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleTotalAmount
                                    ? item.saleTotalAmount.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {"15%"}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleVATAmount
                                    ? item.saleVATAmount.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleTotalAmount
                                    ? item.saleNetAmount.toFixed(2)
                                    : 0}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </Grid>
                </>
              ) : null}


              {Item?.length > 0 ? (
                <>
                  {packagesLength ? (
                    <Grid container>
                      <table>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <td class="tableData">
                            <strong>رقم التسلسلي </strong>
                            <br />
                            <strong> SL NO</strong>
                          </td>
                          <td class="tableData">
                            <strong> تفاصيل الحزمة </strong>
                            <br />
                            <strong>PARTICULARS OF PACKAGES</strong>
                          </td>
                          <td class="tableData">
                            <strong>الكمية </strong>
                            <br />
                            <strong> QTY</strong>
                          </td>
                          <td class="tableData">
                            <strong>سعر الوحدة </strong>
                            <br />
                            <strong> UNIT PRICE</strong>
                          </td>
                          <td class="tableData">
                            <strong>المبلغ الخاضع للضريبة</strong>
                            <br />
                            <strong>TOTAL PRICE EXCL. VAT</strong>
                          </td>
                          <td class="tableData">
                            <strong> نسبة القيمه المضافه </strong>
                            <br />
                            <strong>VAT%</strong>
                          </td>
                          <td class="tableData">
                            <strong> قيمة الضريبة   </strong>
                            <br />
                            <strong> VAT AMOUNT </strong>
                          </td>
                          <td class="tableData">
                            <strong>تخفيض </strong>
                            <br />
                            <strong> DISCOUNT</strong>
                          </td>
                          <td class="tableData">
                            <strong> (ريال سعودي) المبلغ </strong>
                            <br />
                            <strong> AMOUNT (SAR)</strong>
                          </td>
                        </tr>
                        {Item &&
                          Item.map((item, index) => {
                            if (item.categoryName === "packages") {
                              packageNetPrice =
                                packageNetPrice + item.saleTotalAmount;
                              packageVATAmount =
                                packageVATAmount + item.saleVATAmount;
                            }

                            return (
                              <>
                                {item?.categoryName?.toLowerCase() ===
                                "packages" ? (
                                  <tr key={index}> 
                                    <td class="tableData">{packageIndex ++}</td>
                                    <td class="tableData">
                                      {item?.itemName || ""}
                                      {" - "}
                                      {item?.itemCode || ""}
                                    </td>
                                    <td class="tableData">
                                      {item?.itemQty || 1}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleItemPrice.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleTotalAmount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {/* {data?.contractNumber === "pfmc1670"? "0%": "15%"} */}
                                      {/* {`${item.vatPercentage} %` || `0 %` } */}
                                    {data?.contractNumber === "pfmc1670"? "0%": `${item.vatPercentage} %` || `0 %`}

                                      </td>
                                    <td class="tableData">
                                      {item?.saleVATAmount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.itemDiscount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleNetAmount.toFixed(2) || 0}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>SUB TOTAL المجموع الفرعي</strong>
                          </td>
                          <td class="tableData">
                            <strong>{packageNetPrice.toFixed(2) || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>VAT AMOUNT قيمة الضريبة  </strong>
                          </td>
                          <td class="tableData">
                            <strong>{packageVATAmount.toFixed(2) || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>NET AMOUNT المبلغ الصافي</strong>
                          </td>
                          <td class="tableData">
                            <strong>
                              {(packageNetPrice + packageVATAmount).toFixed(
                                2
                              ) || "0"}
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </Grid>
                  ) : null}
                  {servicesLength ? (
                    <Grid container>
                      <table>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <td class="tableData">
                            <strong>رقم التسلسلي </strong>
                            <br />
                            <strong> SL NO</strong>
                          </td>

                          <td class="tableData">
                            <strong> تفاصيل الخدمات</strong>
                            <br />
                            <strong>PARTICULARS OF SERVICES </strong>
                          </td>
                          <td class="tableData">
                            <strong>الكمية </strong>
                            <br />
                            <strong> QTY</strong>
                          </td>
                        
                          <td class="tableData">
                            <strong>سعر الوحدة </strong>
                            <br />
                            <strong> UNIT PRICE</strong>
                          </td>
                          <td class="tableData">
                            <strong>المبلغ الخاضع للضريبة</strong>
                            <br />
                            <strong> TOTAL PRICE EXCL. VAT</strong>
                          </td>
                          <td class="tableData">
                            <strong> نسبة القيمه المضافه </strong>
                            <br />
                            <strong>VAT%</strong>
                          </td>

                          <td class="tableData">
                            <strong>قيمة الضريبة   </strong>
                            <br />
                            <strong>VAT AMOUNT</strong>
                          </td>
                          <td class="tableData">
                            <strong>تخفيض </strong>
                            <br />
                            <strong> DISCOUNT</strong>
                          </td>
                          <td class="tableData">
                            <strong> (ريال سعودي) المبلغ </strong>
                            <br />
                            <strong> AMOUNT (SAR)</strong>
                          </td>
                        </tr>
                        {Item &&
                          Item.map((item, index) => {

                            if (item.categoryName === "services") {
                              serviceNetPrice =
                                serviceNetPrice + item.saleTotalAmount;
                              serviceVATAmount =
                                serviceVATAmount + item.saleVATAmount;
                            }
                            
                            return (
                              <>

                                {item?.categoryName?.toLowerCase() ===
                                "services" ? (
                                  <tr key={index}> 
                                    <td class="tableData">{serviceIndex ++}</td>
                                    <td class="tableData">
                                      {item?.itemName || ""}
                                      {" - "}
                                      {item?.itemCode || ""}
                                    </td>
                                    <td class="tableData">
                                      {item?.itemQty || 1}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleItemPrice.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleTotalAmount.toFixed(2) || 0}
                                    </td>
                                    
                                    <td class="tableData">
                                    {data?.contractNumber === "pfmc1670"? "0%": `${item.vatPercentage} %` || `0 %`}

                                    {/* {data?.contractNumber === "pfmc1670"? "0%": "15%"} */}
                                    {/* {`${item.vatPercentage} %` || `0 %` } */}
                                      </td>
                                    <td class="tableData">
                                      {item?.saleVATAmount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.itemDiscount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleNetAmount.toFixed(2) || 0}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}

                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>SUB TOTAL المجموع الفرعي</strong>
                          </td>
                          <td class="tableData">
                            <strong>{serviceNetPrice.toFixed(2) || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>VAT AMOUNT قيمة الضريبة</strong>
                          </td>
                          <td class="tableData">
                            <strong>{serviceVATAmount.toFixed(2) || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>NET AMOUNT المبلغ الصافي</strong>
                          </td>
                          <td class="tableData">
                            <strong>
                              {(serviceNetPrice + serviceVATAmount).toFixed(
                                2
                              ) || 0}
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </Grid>
                  ) : null}

                  {selectedInvoice === "invoices" || data?.invoiceType === 1 ? (
                    <>
                      {ItemsLength ? (
                        <Grid container>
                          <table>
                            <tr
                              style={{
                                borderTop: "1px solid black",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <td class="tableData">
                                <strong>رقم التسلسلي </strong>
                                <br />
                                <strong>SL NO</strong>
                              </td>
                              <td class="tableData">
                                <strong>تفاصيل قطع الغيار</strong>
                                <br />
                                <strong>PARTICULARS OF ITEM</strong>
                              </td>
                              <td class="tableData">
                                <strong>الكمية </strong> <br />
                                <strong>QTY</strong>
                              </td>
                              <td class="tableData">
                                <strong>سعر الوحدة </strong> <br />
                                <strong>UNIT PRICE</strong>
                              </td>
                              <td class="tableData">
                                <strong>المبلغ الخاضع للضريبة</strong> <br />
                                <strong>TOTAL PRICE EXCL. VAT</strong>
                              </td>
                              <td class="tableData">
                                <strong>نسبة القيمه المضافه </strong> <br />
                                <strong>VAT%</strong>
                              </td>
                              <td class="tableData">
                                <strong>قيمة الضريبة   </strong> <br />
                                <strong>VAT AMOUNT</strong>
                              </td>
                              <td class="tableData">
                                <strong>تخفيض </strong> <br />
                                <strong>DISCOUNT</strong>
                              </td>
                              <td class="tableData">
                                <strong> (ريال سعودي) المبلغ </strong>
                                <br />
                                <strong> AMOUNT (SAR)</strong>
                              </td>
                            </tr>
                            
                            {Item &&
                              Item.map((item, index) => {

                                if (item.categoryName === "items") {
                                  itemsNetPrice =
                                    itemsNetPrice + item.saleTotalAmount;
                                  itemsVATAmount =
                                    itemsVATAmount + item.saleVATAmount;
                                }
                                 else if (item.categoryName !== "items" && item.categoryName !== "packages" && item.categoryName !== "services") {
                                  itemsNetPrice =
                                    itemsNetPrice + item.saleTotalAmount;
                                  itemsVATAmount =
                                    itemsVATAmount + item.saleVATAmount;
                                }
                                return (
                                  <>
                                 
                                    {item?.categoryName.toLowerCase() !==
                                      "services" &&
                                    item?.categoryName.toLowerCase() !==
                                      "packages" ? (
                                      <tr key={index}>
                                        <td class="tableData">{itemsIndex ++}</td>
                                        <td class="tableData">
                                          {item?.itemName || ""}
                                          {" - "}
                                          {item?.itemCode || ""}
                                        </td>
                                        <td class="tableData">
                                          {item?.itemQty || 1}
                                        </td>
                                        <td class="tableData">
                                          {item?.saleItemPrice.toFixed(2) || 0}
                                        </td>
                                        <td class="tableData">
                                          {item?.saleTotalAmount.toFixed(2) ||
                                            0}
                                        </td>
                                        <td class="tableData">
                                        {data?.contractNumber === "pfmc1670"? "0%": `${item.vatPercentage} %` || `0 %`}
                                        {/* {`${item.vatPercentage} %` || `0 %` } */}
                                          </td>
                                        <td class="tableData">
                                          {item?.saleVATAmount.toFixed(2) || 0}
                                        </td>
                                        <td class="tableData">
                                          {item?.itemDiscount.toFixed(2) || 0}
                                        </td>
                                        <td class="tableData">
                                          {item?.saleNetAmount.toFixed(2) || 0}
                                        </td>
                                      </tr>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            <tr class="border-bottom">
                              <td colspan="8" class="text-right">
                                <strong>SUB TOTAL المجموع الفرعي</strong>
                              </td>
                              <td class="tableData text-right">
                                <strong>{itemsNetPrice.toFixed(2) || 0}</strong>
                              </td>
                            </tr>
                            <tr class="border-bottom">
                              <td colspan="8" class="text-right">
                                <strong>VAT AMOUNT  قيمة الضريبة  </strong>
                              </td>
                              <td class="tableData text-right">
                                <strong>
                                  {itemsVATAmount.toFixed(2) || 0}
                                </strong>
                              </td>
                            </tr>
                            <tr class="border-bottom">
                              <td colspan="8" class="text-right">
                                <strong>NET AMOUNT المبلغ الصافي</strong>
                              </td>
                              <td class="tableData text-right">
                                <strong>
                                  {(itemsNetPrice + itemsVATAmount).toFixed(
                                    2
                                  ) || 0}
                                </strong>
                              </td>
                            </tr>
                          </table>
                        </Grid>
                      ) : null}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {selectedInvoice === "invoices"  ||  data?.invoiceType === 1 ? (
                <>
                  <Grid
                    container
                    style={{ padding: 1, marginTop: 3, marginBottom: 3 }}
                  >
                    <table>
                      <tr class="border-bottom">
                        <td colspan="10" class="text-right">
                          <strong>SUB TOTAL المجموع الفرعي </strong>
                        </td>
                        <td class="tableData text-right">
                          <strong>
                            {data && data.saleTotalAmount
                              ? data.saleTotalAmount.toFixed(2)
                              : "0"}
                          </strong>
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="10" class="text-right">
                          <strong>VAT AMOUNT   قيمة الضريبة</strong>
                        </td>
                        <td class="tableData text-right">
                          <strong>
                            {data && data.saleVATAmount
                              ? data.saleVATAmount.toFixed(2)
                              : "0"}
                          </strong>
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="10" class="text-right">
                          <strong>TOTAL (SAR)المجموع الكلي</strong>
                        </td>
                        <td class="tableData text-right">
                          <strong>
                            {data && data.saleNetAmount
                              ? data.saleNetAmount.toFixed(2)
                              : "0"}
                          </strong>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : null}
            
              <Grid
                container
                style={{ padding: 6, marginTop: 10, marginBottom: 3 }}
              >
                {selectedInvoice === "leaseinvoices" &&  data?.invoicetype === 2 ? (
                  <>
                    <Grid item md={6}>
                      <p class="ptag">
                        <strong>Terms & Conditions</strong>
                      </p>
                      {data?.setting?.attributes?.termsEnglishLeasing ? (
                        <>
                          <p class="ptag">
                            {data?.setting?.attributes?.termsEnglishLeasing ||
                              ""}
                          </p>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: "right", padding: "12px 2px 0 0" }}
                    >
                      <p class="ptag">
                        <strong> الشروط والأحكام </strong>
                      </p>
                      {data?.setting?.attributes?.termsArabicLeasing ? (
                        <>
                          <p class="ptag">
                            {data?.setting?.attributes?.termsArabicLeasing ||
                              ""}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid item md={6}>
                      <p class="ptag">
                        <strong>Terms & Conditions</strong>
                      </p>
                      {data?.setting?.attributes?.termsEnglishMaintenance ? (
                        <>
                          <p class="ptag" style={{ whiteSpace: "pre-wrap" }}>
                            {data?.setting?.attributes
                              ?.termsEnglishMaintenance || ""}
                          </p>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: "right", padding: "12px 2px 0 0" }}
                    >
                      <p class="ptag">
                        <strong> الشروط والأحكام </strong>
                      </p>
                      {data?.setting?.attributes?.termsArabicMaintenance ? (
                        <>
                          <p
                            class="ptag"
                            style={{ whiteSpace: "pre-wrap", direction: "rtl" }}
                          >
                            {data?.setting?.attributes
                              ?.termsArabicMaintenance || ""}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid
                container
                style={{ padding: 1, marginTop: 3, marginBottom: 3 }}
              >
                <Grid item md={6}>
                  <p class="ptag">
                    This is a system generated email and needs no signatures.{" "}
                  </p>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ textAlign: "right", padding: "12px 12px 0 0" }}
                >
                  <p class="ptag">
                    فاتورة تم إنشاؤها بواسطة النظام ولا تتطلب توقيعا{" "}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <footer class="text-center">
              <Grid class="legalcopy clearfix">
                <p class="text-center">
                  شكرا لزيارتك <br /> THANK YOU FOR YOUR VISIT{" "}
                </p>
              </Grid>
            </footer>
          </Grid>
          }
        </Grid>
      );
    }
  }
}

export default Invoice;
