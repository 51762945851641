import React, { Component, Fragment } from 'react'
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { connect } from 'react-redux'
;
import isEqual from 'react-fast-compare'
import { checkPrivileges, convertSpeedUnits, errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'
import instance from '../../../axios'
import ExportImportMenu from '../../../Components/Maps/ExportImportMenu'
import { notifySuccess } from '../../../Utils/CustomNotifcations';
class Make extends Component {
  constructor (props) {
    super(props)
    this.state = { searchRecently: '' }
  }

  search (e) {
    this.props.searchItems(e.target.value)
  }

  render () {
    const { modelData } = this.props
    const Make1 = modelData&&modelData.map(g => (
      <ListRow
        key={g.id}
        item={g}
        editModelForm={this.props.editModelForm}
        modeEmpty={this.props.modeEmpty}
        onEditType={this.props.onEditType}
        fetchData={this.props.fetchData}
        modelForm={this.props.modelForm}
      />
    ))

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('model') && (
            <div>
              <div className='section-head clearfix section-head-filter' style={{ paddingTop: 0 }}>
                <ul className='filter-row clearfix pull-right'>
                  <li style={{ flex: 1 }} className="sidebar-icon-gap">
                    <SearchField
                      label={this.props.translate('searchModels')}
                      fullWidth
                      onChange={e => this.search(e)}
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {checkPrivileges('modelCreate') && (
                      <Button
                        size='small'
                        onClick={e => this.props.openCreateFrom()}
                      >
                        {this.props.translate('sharedCreate')}
                      </Button>
                    )}
                  </li>
                  {/* <li style={{paddingTop:8, paddingBottom:4}}>
                      <ExportImportMenu
                            fileName = 'category'
                            checkPrivilege = 'serviceCreate'
                            data = {this.state?.expense || []}
                            themecolors = {this.props.themecolors}
                            expensetypeId={this.state?.serviceForm?.id ||''}
                          />
                  </li> */}
                </ul>
              </div>
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {this.props.itemPagination && this.props.itemPagination.items ? (
                    <InfiniteScroll
                      dataLength={this.props.itemPagination.items.length}
                      next={this.props.fetchMoreItems}
                      hasMore={this.props.itemPagination.hasNext}
                      // loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget='scrollableDiv'
                      endMessage={this.props.endMessage}
                    >
                      {Make1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

class ListRow1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      onDeleteConfirmation: false
    }
  }

  onDelete = () => {
    if (this.props.item.id) {
      instance({
        url: `api/models/${this.props.item.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
          this.props.dispatch(
            // Notifications.success({
            //   message: this.props.translate('modelDeleted'),
            //   autoDismiss: 10
            // })
            notifySuccess("modelDeleted")
          )
          this.setState({ item: {} }, ()=>{this.props.fetchData(true,1,50)})
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  onRemovedItem = () => {
    this.setState({
      onDeleteConfirmation: true
    })
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }

  render () {
    return (
      <li 
      className={
        'clearfix list-row ' +
          (parseInt(this.props.modelForm&&this.props.modelForm.id) ===
          parseInt(this.props.item.id)
          ? 'active'
          : '')
        }  key={this.props.item.id}>
        <label
          className='checkbox'
          onClick={e => checkPrivileges('modelUpdate') ? this.props.editModelForm(this.props.item) : null}
        >
          <span className='unit-name' style={{ fontSize: 12 }}>
            {this.props.item.makeName}-{this.props.item.modelName}
          </span>
        </label>
        <div className='callout-actions'>
          {checkPrivileges('modelDelete') &&  (
            <span className='action' onClick={this.onRemovedItem}>
              <DeleteIcon className='material-icons'/>
            </span>
          )}
        </div>
        <div className='callout-actions'>
          { (
            <span className='action' onClick={()=>this.props.editModelForm(this.props.item)}>
              <EditIcon className='material-icons'/>
            </span>
          )}
        </div>

        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.onDelete}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.item.description}
          />
        )}
      </li>
    )
  }
}

const mapState = state => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps((ListRow1))
export default mapStateToProps(Make)
