import React  from "react"
import moment from 'moment'
// import ical from 'ical-generator'
import {
  driverDeviceWiseSelectedList,
  trailerDeviceWiseSelectedList,
  dashboardDeviceWiseSelectedList,
  vehicleDeviceWiseSelectedList,
  geofenceDeviceWiseSelectedList,
  notificationDeviceWiseSelectedList,
  groupDeviceWiseSelectedList,
  deviceUserWiseSelectedList,
  userDeviceWiseSelectedList,
  templateUserWiseSelectedList,
  widgetDeviceWiseSelectedList
} from '../Actions/Notifications'
import { computedAttributeDeviceWiseSelectedList } from '../Actions/ComputedAttributes'
import { commandsDeviceWiseSelectedList } from '../Actions/Commands'
import { maintenanceDeviceWiseSelectedList } from '../Actions/Maintenance'
import { garageUsersWiseSelectedList } from '../Actions/Garages'
import { areaUsersWiseSelectedList } from '../Actions/Areas'
import axios from 'axios'
import { useDispatch } from 'react-redux'
// import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { Tooltip } from '@mui/material'
import { Trans as Translate} from 'react-i18next';

import instance from '../axios'
import { serviceUsersWiseSelectedList } from '../Actions/Services'
import { notifyError, notifySuccess } from '../Utils/CustomNotifcations'
export const defaultDevicesLength = 300
export const storeTime = 30
export const storeTime2 = 10
export function checkPrivileges (type) {
  const userPrivileges = JSON.parse(localStorage.getItem('frontPrivileges'))
  const info = JSON.parse(localStorage.getItem('userInfoFront'))
  JSON.parse(localStorage.getItem('userInfoFront'))
  if(userPrivileges){
    if (  info && info.id) {
      return Object.values(userPrivileges).indexOf(type) > -1
    } else {
      // window.location.assign('/logout')
      return false
    }
  }else{
    window.location.assign('/login')
  }
  
}
export function getValue(o, k) {
  return k.split('.').reduce((r, e) => {
    if (!r) return r;
    else return r[e] || undefined;
  }, o);
}

export const plateLetters = [
{label: "ا", id: "1"},
{label: "ب", id: "2"},
{label: "ح", id: "3"},
{label: "د", id: "4"},
{label: "ر", id: "5"},
{label: "س", id: "6"},
{label: "ص", id: "7"},
{label: "ط", id: "8"},
{label: "ع", id: "9"},
{label: "ق", id: "10"},
{label: "ك", id: "11"},
{label: "ل", id: "12"},
{label: "م", id: "13"},
{label: "ن", id: "14"},
{label: "هـ", id: "15"},
{label: "و", id: "16"},
{label: "ى", id: "17"},
]

export const plateTypes = [
  {id: "1", label: <Translate i18nKey="privateCar" />},
  {id: "2", label: <Translate i18nKey="publicTransport" />},
  {id: "3", label: <Translate i18nKey="privateTransport" />},
  {id: "4", label: <Translate i18nKey="publicMinibus" />},
  {id: "5", label: <Translate i18nKey="privateMinibus" />},
  {id: "6", label: <Translate i18nKey="taxi" />},
  {id: "7", label: <Translate i18nKey="heavyEquipment" />},
  {id: "8", label: <Translate i18nKey="export" />},
  {id: "9", label: <Translate i18nKey="diplomatic" />},
  {id: "10", label: <Translate i18nKey="motorcycle" />},
  {id: "11", label: <Translate i18nKey="temporary" />}
]
export const getTimeFormat = () => {
  const cookiee = localStorage.getItem('userInfoFront');
  let timeFormat = "HH:mm";
  if(cookiee) {
    const userInfo = JSON.parse(cookiee);
    timeFormat = userInfo && userInfo.twelveHourFormat ? "hh:mm A" : "HH:mm"
  }
  return timeFormat
}

export const getDateFormat = () => {
  return "YYYY-MM-DD";
}

export const prepareStreeView = (lat, lng) => {
  return "http://maps.google.com/maps?q=&layer=c&cbll="+lat+","+lng;
}

export const getDateTimeFormat = () => {
  const cookiee = localStorage.getItem('userInfoFront');
  let timeFormat = "YYYY-MM-DD HH:mm:ss";
  if(cookiee) {
    const userInfo = JSON.parse(cookiee);
    timeFormat = userInfo && userInfo.twelveHourFormat ? "YYYY-MM-DD hh:mm:ss A" : "YYYY-MM-DD HH:mm:ss"
  }
  return timeFormat
}
export const intervals = (startString, endString) => {
  const start = moment(startString)
  const end = moment(endString)

  // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
  // note that 59 will round up to 60, and moment.js handles that correctly
  start.minutes(Math.ceil(start.minutes() / 15) * 15)

  const result = []

  const current = moment(start)

  while (current <= end) {
    result.push({
      key: current.format('YYYY-MM-DD HH:mm'),
      value: current.format('hh:mm a')
    })
    current.add(15, 'minutes')
  }

  return result
}

export function isOwner (logInUser) {
  let query = ''
  if (logInUser.userType === -1) {
    query = 'all=true'
  } else {
    query = 'userId=' + logInUser.id
  }
  return query
}
export function displayErrorMessages (err) {
  let errormgs = ''
  if (err.includes('Admin')) {
    errormgs = 'youRequiredAdministrationAccess'
  } else if (err.includes('Unauthorized')) {
    errormgs = 'yourEmailOrPasswordIsWrong'
  } else if (err.includes('Account has expired')) {
    errormgs = 'yourAccountHasExpired'
  } else if (err.includes('Account is disabled')) {
    errormgs = 'accountIsDisabled'
  } else if (err.includes('Invalid Current Password')) {
    errormgs = 'wrongCurrentPassword'
  } else if (err.includes('Invalid User. Please try again with other user')) {
    errormgs = 'invalidUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('UnAuthorize User')) {
    errormgs = 'UnAuthorizeUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('Unknown account')) {
    errormgs = 'Account doesnot exists'
  } else if (err.includes('Not enough privileges')) {
    errormgs = 'somthingWentWrongMessage1'
  } else if (err.includes('bad request (HINT: Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.)')) {
    errormgs = 'Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.'
  } 
  else {
    errormgs = 'serverIsDown'
  }
  return errormgs
}

export const arrDiff = (a1, a2) => {
  const a = []
  const diff = []

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]]
    } else {
      a[a2[i]] = true
    }
  }

  for (const k in a) {
    diff.push(k)
  }

  return diff
}
export const findDuplicates = arr => {
  const sortedArr = arr.slice().sort() // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  const results = []
  for (let i = 0; i < sortedArr.length - 1; i++) {
    if (parseInt(sortedArr[i + 1]) === parseInt(sortedArr[i])) {
      results.push(sortedArr[i])
    }
  }
  return results
}

export function isAuthenticated (props) {
  const info = JSON.parse(localStorage.getItem('userInfoFront'))
  if (info && info.id) {
    return info.id
  } else {
    if (props && props.history) {
      return props.history.push('/logout')
    }
  }
}
export function isOwnerOrDealer (type) {
  if (type === -1 || type === 5) {
    return true
  } else {
    return false
  }
}

export function findObj (col, val, array) {
  return array.find(row => row[col] === val)
}

export function DistanceUnits (value, unit) {
  const data = [
    {
      key: 'km',
      name: 'km',
      factor: 0.001
    },
    {
      key: 'mi',
      name: 'mi',
      factor: 0.000621371
    },
    {
      key: 'nmi',
      name: 'nmi',
      factor: 0.000539957
    }
  ]

  if (!unit) {
    unit = 'km'
  }
  const model = findObj('key', unit, data)
  return model && model.key
    ? parseFloat(value * model.factor).toFixed(2) + ' ' + model.name
    : parseFloat(value || 0).toFixed(2) + ' ' + unit
}

export function convertSpeedUnits (value, fromUnit, toUnit, notToFixed) {
  const data = [
    {
      key: 'kn',
      name: 'kn',
      factor: 1
    },
    {
      key: 'kmh',
      name: 'km/h',
      factor: 1.852
    },
    {
      key: 'mph',
      name: 'mph',
      factor: 1.15078
    }
  ]
  const model = findObj('key', fromUnit, data)
  if (model && model.key) {
    const resetValue = value / model.factor
    const model2 = findObj('key', toUnit, data)
    if (model2 && model2.key) {
      if (notToFixed) {
        return toUnit !== 'kn'
          ? resetValue * model2.factor
          : resetValue * model2.factor
      } else {
        return toUnit !== 'kn'
          ? parseFloat(resetValue * model2.factor).toFixed(0)
          : resetValue * model2.factor
      }
    } else {
      return parseFloat(value || 0).toFixed(0)
    }
  } else return parseFloat(value || 0).toFixed(0)
}

export function SpeedUnits (value, unit) {
  const data = [
    {
      key: 'kn',
      name: 'kn',
      factor: 1
    },
    {
      key: 'kmh',
      name: 'km/h',
      factor: 1.852
    },
    {
      key: 'mph',
      name: 'mph',
      factor: 1.15078
    }
  ]

  if (!unit) {
    unit = 'kn'
  }
  const model = findObj('key', unit, data)
  return model && model.key
    ? parseFloat(value * model.factor).toFixed(0) + ' ' + model.name
    : parseFloat(value || 0).toFixed(0) + ' ' + unit
}

export function VolumeUnits (value, unit) {
  const data = [
    {
      key: 'ltr',
      name: 'l',
      factor: 1
    },
    {
      key: 'impGal',
      name: 'ukGal',
      factor: 4.546
    },
    {
      key: 'usGal',
      name: 'usGal',
      factor: 3.785
    },
    {
      key: 'ukGal',
      name: 'ukGal',
      factor: 4.546
    }   //uk gallon added, Jawad
  ]

  if (!unit) {
    unit = 'ltr'
  }
  const model = findObj('key', unit, data)
  return model && model.key
    ? parseFloat(value / model.factor || 0).toFixed(1) + ' ' + model.name
    : parseFloat(value || 0).toFixed(1) + ' ' + unit
}

export function HoursUnits (miliseconds) {
  return moment.duration(miliseconds).format('Y[yrs] M[mo] D[d] h[h] m[m] s[s]')
}

export function CoordinateFormats (key, value, unit) {  
  if (value) {
    let hemisphere, degrees, minutes, seconds

    if (key === 'latitude') {
      hemisphere = value >= 0 ? 'N' : 'S'
    } else {
      hemisphere = value >= 0 ? 'E' : 'W'
    }

    switch (unit) {
      case 'ddm':
        value = Math.abs(value)
        degrees = Math.floor(value)
        minutes = (value - degrees) * 60
        return (
          degrees + '° ' + parseFloat(minutes).toFixed(8) + "' " + hemisphere
        )
      case 'dms':
        value = Math.abs(value)
        degrees = Math.floor(value)
        minutes = Math.floor((value - degrees) * 60)
        seconds = Math.round((value - degrees - minutes / 60) * 3600)
        return degrees + '° ' + minutes + "' " + seconds + '" ' + hemisphere
      default:
        return parseFloat(value || 0).toFixed(8) + '°'
    }
  } else {
    return value
  }
}
export function CourseFormats (value) {
  const data = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
  return data[Math.floor(value / 45)]
}

export function numberFormatter (value, precision, suffix) {
  if (value !== undefined) {
    return Number(parseFloat(value || 0).toFixed(precision)) + ' ' + suffix
  }
  return null
}

export function defaultFormatter (value, unit) {
  if (typeof value === 'number') {
    return Number(parseFloat(value || 0).toFixed(2))
  } else if (typeof value === 'boolean') {
    return value ? 'YES' : 'NO'
  } else if (value instanceof Date) {
    
    return moment(value).format(getDateTimeFormat())
    
  } else {
    return value + ' ' + (unit || '')
  }
}


export function setAttributeFormat (key, value, unit, unknown, category) {
  const cookiee = localStorage.getItem('userInfoFront');
  let userInfo = {};
  let coordinateFormat = "";
  let distanceUnit = "";
  let speedUnit = "";
  let volumeUnit = "";
  if(cookiee) {
    userInfo = JSON.parse(cookiee);
    coordinateFormat = userInfo && userInfo.coordinateFormat || ""
    distanceUnit = userInfo && userInfo.attributes && userInfo.attributes.distanceUnit || ""
    speedUnit = userInfo && userInfo.attributes && userInfo.attributes.speedUnit || ""
    volumeUnit = userInfo && userInfo.attributes && userInfo.attributes.volumeUnit || ""
  }
  

  if (unknown && unknown.length && unknown.indexOf(key) !== -1) {
    return 'unknown'
  } else {
    switch (key) {
      case 'totalDistance':
      case 'mileage':
      case 'distance':
        return DistanceUnits(value, distanceUnit)
      case 'speed':
        return SpeedUnits(value, speedUnit)
      /* case 'driverUniqueId':
              return this.driverUniqueIdFormatter; */
      case 'longitude':
      case 'latitude':
        return CoordinateFormats(key, value, coordinateFormat)
      case 'power':
      case 'battery':
      case 'voltage':
      case 'boardPower':
        return numberFormatter(value, 2, 'V')
      case 'batteryLevel':
      case 'percentage':
        return numberFormatter(value, 0, '%')
      case 'temperature':
        return numberFormatter(value, 2, unit || '°C')        
      case 'fuel':
      case 'volume':
        return VolumeUnits(value, volumeUnit)
      case 'idlTime':
      case 'stopTime':
      case 'hours':
        return HoursUnits(value || 0)
      case 'doors':
      case 'carAlarm':
      case 'enginStatus':
      case 'externalPower':
      case 'panicButton':
      case 'customDiscrete':
      case 'ignition':
      case 'devicePowerCut':
        return value ? 'On' : 'Off'
      case 'custom':
        if (category === 1) {
          return value ? 'On' : 'Off'
        } else {
          return defaultFormatter(value, unit)
        }
      case 'altitude':
        return value ? value + 'm' : '0m'
      case 'course':
        return CourseFormats(value)
      case 'instantFuelConsumption':
      case 'averagFuelConsumption':
      case 'consumption':
        return numberFormatter(value, 2, unit || 'l/h')
      default:
        return defaultFormatter(value, unit)
    }
  }
}

export function convertToBinary (str, protocol) {
  if (str === undefined) {
    return null
  }

  const raw = '0000000000000000' + str.toString(2)
  const last16 = raw.substr(raw.length - 16)
  const arr = last16.split('')

  if (protocol === 'galileo') {
    const p = { binaryValue: last16, decimalValue: str }

    if (parseInt(arr['15']) === 1) {
      p['Driving (set by AccSens command).'] = parseInt(arr['15'])
    } else {
      p['Vibration level corresponds parking'] = parseInt(arr['15'])
    }

    if (parseInt(arr['14']) === 1) {
      p['Incline level exceeds the allowable one'] = parseInt(arr['14'])
    } else {
      p['Incline angle does not exceed the allowable one'] = parseInt(arr['14'])
    }

    if (parseInt(arr['12']) === 1) {
      p['GSM modem cant determine SIM-card.'] = parseInt(arr['12'])
    } else {
      p['No SIM card'] = parseInt(arr['12'])
    }

    if (parseInt(arr['10']) === 1) {
      p['Lower than 3.7 V'] = parseInt(arr['10'])
    } else {
      p['Voltage of internal source is normal'] = parseInt(arr['10'])
    }

    if (parseInt(arr['9']) === 1) {
      p.Disconnected = parseInt(arr['9'])
    } else {
      p['GPS aerial is connected'] = parseInt(arr['9'])
    }

    if (parseInt(arr['8']) === 1) {
      p['Declined from normal.'] = parseInt(arr['8'])
    } else {
      p['Voltage of internal terminal supply bus is normal'] = parseInt(
        arr['8']
      )
    }

    if (parseInt(arr['7']) === 1) {
      p['Declined from normal.'] = parseInt(arr['7'])
    } else {
      p['External supply voltage is normal'] = parseInt(arr['7'])
    }

    if (parseInt(arr['6']) === 1) {
      p['Vehicle is started (adjusted by mhours command)'] = parseInt(arr['6'])
    } else {
      p['Vehicle is stopped'] = parseInt(arr['6'])
    }

    if (parseInt(arr['5']) === 1) {
      p['Vibration level corresponds normal movement'] = parseInt(arr['5'])
    } else {
      p['Vibration level corresponds shock.'] = parseInt(arr['5'])
    }

    p[
      'Signal quality, range: [0-3]. The less value, the worse communication.'
    ] = parseInt(arr['3'] + arr['2'], 2)

    return p
  } else if (protocol === 'bce') {
    return {
      binaryValue: last16,
      decimalValue: str,
      Motion: parseInt(arr['15']),
      'Physical input 1': parseInt(arr['14']),
      'Physical input 2': parseInt(arr['13']),
      'Physical input 3': parseInt(arr['12']),
      'Physical input 4': parseInt(arr['11']),
      'Voltage threshold on any ADC input': parseInt(arr['10']),
      'Status of engine': parseInt(arr['9']),
      'Main power supply of device status': parseInt(arr['8']),
      'GSM jamming': parseInt(arr['7']),
      'Activity status of physical Out1': parseInt(arr['6']),
      'Activity status of physical Out2': parseInt(arr['5']),
      'Activity status of virtual Out3': parseInt(arr['4']),
      'Overturn fixed by accelerometer': parseInt(arr['3']),
      'Shock detection': parseInt(arr['2']),
      'Accident fixed by crash sensor': parseInt(arr['1']),
      'Status of GPS antenna connection': parseInt(arr['0'])
    }
  }
}
// convertStatus(14849);
export function setCookie (cname, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '= ;' + expires + ';path=/'
}

export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const toggleEntity = async (type, method, entity, property, param1, param2) => {
  let url, requestData;

  if (type === 3) {
    url = method ? `/api/vehicles/assignall?assignFrom=${property}&assignTo=${param1}&all=true&unlink=false` : `/api/vehicles/assignall?assignFrom=${property}&assignTo=${param1}&all=true&unlink=true`;
    requestData = null;
  } else {
    url = type === 1 ? `/api/permissions/multiproperty` : `/api/permissions/multientity`;
    requestData = {
      [entity]: type === 2 ? param2 : param1,
      [property]: type === 2 ? param1 : param2
    };
  }

  const requestConfig = {
    url,
    method: type === 3 ? 'GET' : method,
    data: requestData
  };

  return await instance(requestConfig);
};


export const selectAll = (
  check,
  entity,
  prop,
  id,
  arr,
  base,
  dispatch,
  type,
  translate,
  callback,
  handleLoaderCallback
) => {
  const checked = check
  let data = []
  // console.log("arr=====", arr);

  // if(type !== 'vehicle'){
  if (checked) {
    arr.map(u => {
      if (type === 'attribute') {
        if (u.category === 0 && !u.check) {
          data.push(u)
        }
      } else if (!u.check) {
        data.push(u)
      }
      return null
    })
  } else {
    if (type === 'attribute') {
      arr.map(u => {
        if (u.category === 0) {
          data.push(u)
        }
        return null
      })
    } else {
      data = arr
    }
  }
// }

  toggleEntity(
    type === 'access' || type === 'deviceAlt' ? 2 : type ==="vehicle"? 3 : 1,
    // checked ? 'POST' : 'DELETE',
    (type === 'vehicle') ? checked : (checked ? 'POST' : 'DELETE'),
    entity,
     prop,
     (type === 'vehicle') ? id : parseInt(id),
    data.map(d => d.id)
  ).then(res => {
    // const res = response
   if (type !== 'vehicle') {
    if(callback) {
      callback(res)
    }
   }else{
    if(handleLoaderCallback)
    handleLoaderCallback(res)
   }
  
    // if (
    //   response.status === 200 &&
    //   (res.status === 'success' || res.status === 'ambiguous')
    // ) {
      if (type === 'driver') {
        const message = checked ? 'alldriversAssgined' : 'alldriversUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          driverDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'binder') {
        const message = checked ? 'alltrailersAssgined' : 'alltrailersUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          trailerDeviceWiseSelectedList,
          translate(message),
          res
        )
      }else if (type === 'dashboard') {
        const message = checked ? 'alldashboardsAssgined' : 'alldashboardsUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          dashboardDeviceWiseSelectedList,
          translate(message),
          res
        )
      }else if (type === 'widget') {
        const message = checked ? 'allwidgetsAssgined' : 'allwidgetsUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          widgetDeviceWiseSelectedList,
          translate(message),
          res
        )
      }else if (type === 'vehicle') {
        const message = checked ? 'allvehiclesAssgined' : 'allvehiclesUnassgined'
        updateData(
          checked,
          arr,
          dispatch,
          vehicleDeviceWiseSelectedList,
          translate(message),
          res
        )
      }else if (type === 'geofence') {
        const message = checked
          ? 'allgeofencesAssgined'
          : 'allgeofencesUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          geofenceDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'notification') {
        const message = checked
          ? 'allnotificationsAssgined'
          : 'allnotificationsUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          notificationDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'command') {
        const message = checked
          ? 'allcommandsAssgined'
          : 'allcommandsUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          commandsDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'maintenance') {
        const message = checked
          ? 'allmaintenancesAssgined'
          : 'allmaintenancesUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          maintenanceDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'group') {
        const message = checked ? 'allgroupsAssgined' : 'allgroupsUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          groupDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'device' || type === 'deviceAlt') {
        const message = checked ? 'alldevicesAssgined' : 'alldevicesUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          deviceUserWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'attribute') {
        const message = checked
          ? 'allattributesAssgined'
          : 'allattributesUnassgined'
        updateData(
          checked,
          base,
          dispatch,
          computedAttributeDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'access') {
        const message = checked ? (entity === 'userId' ? 'allusersAssgined' : entity === 'deviceId' ? 'alldevicesAssgined' :  'allgroupsAssgined' ) : (entity === 'userId' ? 'allusersUnassgined' : entity === 'deviceId' ? 'alldevicesUnassgined' :  'allgroupsUnassgined')
        updateData(
          checked,
          base,
          dispatch,
          userDeviceWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'template') {
        const message = checked ? (entity === 'userId' ? 'alltemplatesAssgined' : entity === 'deviceId' ? 'alldevicesAssgined' :  'allgroupsAssgined' ) : (entity === 'userId' ? 'alltemplatesUnassgined' : entity === 'deviceId' ? 'alldevicesUnassgined' :  'allgroupsUnassgined')
        updateData(
          checked,
          arr,
          dispatch,
          templateUserWiseSelectedList,
          translate(message),
          res
        )
      } else if (type === 'garage') {
        const message = checked ? ('allGaragesAssgined') : ('allGaragesUnassgined')
        updateData(
          checked,
          arr,
          dispatch,
          garageUsersWiseSelectedList,
          translate(message),
          res,
        )
      }
      else if (type === 'area') {
        const message = checked ? ('allAreasAssgined') : ('allAreasUnassgined')
        updateData(
          checked,
          arr,
          dispatch,
          areaUsersWiseSelectedList,
          translate(message),
          res,
        )
      }
      else if (type === 'service') {
        const message = checked ? ('allServicesAssgined') : ('allServicesUnassgined')
        updateData(
          checked,
          arr,
          dispatch,
          serviceUsersWiseSelectedList,
          translate(message),
          res,
        )
      }
    // }
  })
  .catch(error => {
    console.log('eror ==', error)
    errorHandler(error,dispatch, callback )})
    // errorHandler(error,dispatch )})
}

const updateData = (check, base, dispatch, func, message, res) => {
  const allData = base.map(n => {
    if (res.status === 'ambiguous') {
      if (res && res.data && res.data.length && !res.data.includes(n.id)) {
        n.check = check
      }
    } else {
      n.check = check
    }
    return n
  })
  dispatch(
    notifySuccess(message)
  )
    dispatch(func(allData))
}

export const PaginationConfig = {
  page: 1,
  pagesize: 10,
  itemSearch: '',
  hasNext: true
}
export function checkedDevelopmentType () {
  let result = `${window.location.host}`
  const serverType = window.location.host.includes('localhost')
  if (serverType) {
    result = ''
  }
  return result
}

export const validEmailPattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,30}(?:\.[a-z]{2})?)$/i
export const validNumberPattern = /^\(?(\d{4})\)?[- ]?(\d{3})[- ]?(\d{7})$/

export const selectStyles = themecolors => ({
  option: (base, state) => ({
    ...base,
    padding: '10px 14px',
    color: (state.isFocused || state.isSelected) ? themecolors.themeLightInverse : themecolors.textColor
  }),
  noOptionsMessage: base => ({
    ...base,
    padding: '10px 14px'
  }),
  multiValue: base => ({
    ...base,
    margin: '2px',
    background: themecolors.themeLightColor,
    color: themecolors.themeInverse
  }),
  multiValueLabel: base => ({
    ...base,
    color: themecolors.textColor
  }),
  placeholder: base => ({
    ...base,
    color: themecolors.textColor,
    opacity: 0.5
  }),
  dropdownIndicator: base => ({
    ...base,
    color: themecolors.textColor,
    padding: 4
  }),
  clearIndicator: base => ({
    ...base,
    color: themecolors.textColor,
    padding: 4
  }),
  indicatorsContainer: base => ({
    ...base,
    color: themecolors.textColor
  }),
  input: base => ({
    ...base,
    color: themecolors.textColor
  }),
  container: base => ({
    ...base,
    margin: '8px 0 4px'
  }),
  control: base => ({
    ...base,
    boxShadow:
      '0px 2px 4px 0px rgba(20, 20, 20, 0.15), 0px 2px 4px 0px rgba(20, 20, 20, 0.15) inset',
    background: 'none',
    borderColor: themecolors.themeLightColor,
    minHeight: 26,
    '&:hover': {
      borderColor: themecolors.themeLightColor
    }
  }),
  menuList: base => ({
    ...base,
    background: themecolors.backgroundColor,
    color: themecolors.textColor
  }),
  singleValue: base => ({
    ...base,
    color: themecolors.textColor
  })
})

export const ReportColumns = {
  // latlng
  startLat: { width: 120 },
  startLon: { width: 120 },
  endLat: { width: 120 },
  endLon: { width: 120 },
  latitude: { width: 120 },
  longitude: { width: 120 },
  entranceLat: { width: 120 },
  entranceLon: { width: 120 },
  exitLat: { width: 120 },
  exitLon: { width: 120 },

  // time
  startTime: { width: 180 },
  endTime: { width: 180 },
  serverTime: { width: 180 },
  eventTime: { width: 180 },
  entranceTime: { width: 180 },
  exitTime: { width: 180 },

  // duration
  duration: { width: 110 },
  engineHours: { width: 110 },
  movementDuration: { width: 110 },
  idleDuration: { width: 110 },
  date: { width: 110 },
  startOdometer: { width: 110 },
  endOdometer: { width: 110 },
  type: { width: 110 },
  distance: { width: 110 },
  

  // other numeric columns
  tripCount: { width: 80 },
  stopCount: { width: 80 },
  fillCount: { width: 80 },
  drainCount: { width: 80 },
  idleDurationPercentage: { width: 80 },
  movementDurationPercentage: { width: 80 },
  fillVolume: { width: 80 },
  drainVolume: { width: 80 },
  startFuelLevel: { width: 80 },
  endFuelLevel: { width: 80 },
  fuelConsumption: { width: 80 },
  spentFuel: { width: 80 },
  penalty: { width: 80 },
  periodCount: { width: 80 },
  eventCount: { width: 80 },

  // event summery columns
  deviceMoving: { width: 100 },
  deviceOffline: { width: 100 },
  deviceOnline: { width: 100 },
  deviceStopped: { width: 100 },
  deviceUnknown: { width: 100 },
  ignitionOff: { width: 100 },
  ignitionOn: { width: 100 },

  averageSpeed: { width: 140 },
  maxSpeed: { width: 140 },
  // address
  coordinate: { width: 250 },
  startAddress: { width: 250 },
  endAddress: { width: 250 },
  address: { width: 250 },
  entranceAddress: { width: 250 },
  exitAddress: { width: 250 },

  name: { width: 200 },
  driverName: { width: 200 },
  geofenceName: { width: 200 },
  maintenanceName: { width: 200 },
  geofenceId: { width: 200 },
  visitCount: { width: 200 },
  comments: { width: 200 },
  violationCount: { width: 200 }
}

// Alarm
export const alarmList = [
  'alarmMovement',
  'alarmOverspeed',
  'alarmFallDown',
  'alarmLowBattery',
  'alarmLowPower',
  'alarmFault',
  'alarmPowerOff',
  'alarmPowerOn',
  'alarmDoor',
  'alarmGeofence',
  'alarmGeofenceEnter',
  'alarmGeofenceExit',
  'alarmGpsAntennaCut',
  'alarmAccident',
  'alarmTow',
  'alarmIdle',
  'alarmHardAcceleration',
  'alarmHardBraking',
  'alarmFatigueDriving',
  'alarmPowerCut',
  'alarmPowerRestored',
  'alarmJamming',
  'alarmTemperature',
  'alarmParking',
  'alarmShock',
  'alarmBonnet',
  'alarmFootBrake',
  'alarmFuelLeak',
  'alarmTampering',
  'alarmRemoving'
]


export const fonts = {
  'ff-1': 'Poppins',
  'ff-2': 'PT Sans',
  'ff-3': 'Raleway',
  'ff-4': 'Roboto',
  'ff-5': 'Nunito'
}
const fontIds = Object.keys(fonts);
const fontFamilyArr = [];
fontIds.map(k => fontFamilyArr.push({value: k, label: fonts[k]}));
export const fontFamilies = fontFamilyArr;

export const postionAttributes = [
  'date',
  'index',
  'hdop',
  'vdop',
  'pdop',
  'sat',
  'satVisible',
  'rssi',
  'gps',
  'roaming',
  'event',
  'alarm',
  'status',
  'odometer',
  'serviceOdometer',
  'tripOdometer',
  'hours',
  'stopTime',
  'steps',
  'power',
  'input',
  'output',
  'battery',
  'batteryLevel',
  'fuel',
  'fuelConsumption',
  'approximate',
  'rpm',
  'throttle',
  'armed',
  'acceleration',
  'deviceTemp',
  'obdSpeed',
  'obdOdometer',
  'distance',
  'totalDistance',
  'commandResult',
  'dtcs',
  'blocked',
  'operator',
  'deviceCommand',
  'sharedGeofences',
  'selectedGeofence',
  'motion',
  'vin',
  'deviceTotalDistance',
  'rfid',
  'versionFw',
  'versionHw',
  'ignition',
  'flags',
  'charge',
  'ip',
  'archive',
  'sharedType',
  'type',
  'driverUniqueId',
  'trailerUniqueId',
  'raw',
  'geofence',
  'mileageAccuracy',
  'parking',
  'custom',
  'boardPower',
  'altitude',
  'direction',
  'location',
  'trackerUpdated',
  'driver',
  'id',
  'gpsUpdated',
  'accel',
  'accelBrake',
  'accelTurn',
  'accelBump',
  'fuelTotal',
  'lastUpdated',
  'binUniqueId',
  'passengerUniqueId'

]
// this postion att user for attribute type comparasion
export const postionAttributesTypeComp = [
  {
    key: 'raw',
    valueType: 'string'
  },
  {
    key: 'index',
    valueType: 'number'
  },
  {
    key: 'hdop',
    valueType: 'number'
  },
  {
    key: 'vdop',
    valueType: 'number'
  },
  {
    key: 'pdop',
    valueType: 'number'
  },
  {
    key: 'sat',
    valueType: 'number'
  },
  {
    key: 'satVisible',
    valueType: 'number'
  },
  {
    key: 'rssi',
    valueType: 'number'
  },
  {
    key: 'gps',
    valueType: 'number'
  },
  {
    key: 'roaming',
    valueType: 'boolean'
  },
  {
    key: 'event',
    valueType: 'string'
  },
  {
    key: 'alarm',
    valueType: 'string'
  },
  {
    key: 'status',
    valueType: 'string'
  },
  {
    key: 'odometer',
    valueType: 'number',
    dataType: 'distance'
  },
  {
    key: 'serviceOdometer',
    valueType: 'number',
    dataType: 'distance'
  },
  {
    key: 'tripOdometer',
    valueType: 'number',
    dataType: 'distance'
  },
  {
    key: 'hours',
    valueType: 'number',
    dataType: 'hours'
  },
  {
    key: 'steps',
    valueType: 'number'
  },
  {
    key: 'input',
    valueType: 'string'
  },
  {
    key: 'output',
    valueType: 'string'
  },
  {
    key: 'power',
    valueType: 'number',
    dataType: 'voltage'
  },
  {
    key: 'battery',
    valueType: 'number',
    dataType: 'voltage'
  },
  {
    key: 'batteryLevel',
    valueType: 'number',
    dataType: 'percentage'
  },
  {
    key: 'fuel',
    valueType: 'number',
    dataType: 'volume'
  },
  {
    key: 'fuelConsumption',
    valueType: 'number',
    dataType: 'consumption'
  },
  {
    key: 'versionFw',
    valueType: 'string'
  },
  {
    key: 'versionHw',
    valueType: 'string'
  },
  {
    key: 'type',
    valueType: 'string'
  },
  /* {
    key: 'ignition',
    valueType: 'boolean'
  }, */ {
    key: 'flags',
    valueType: 'string'
  },
  {
    key: 'charge',
    valueType: 'boolean'
  },
  {
    key: 'ip',
    valueType: 'string'
  },
  {
    key: 'archive',
    valueType: 'boolean'
  },
  {
    key: 'distance',
    valueType: 'number',
    dataType: 'distance'
  },
  {
    key: 'totalDistance',
    valueType: 'number',
    dataType: 'distance'
  },
  {
    key: 'rpm',
    valueType: 'number'
  },
  {
    key: 'vin',
    valueType: 'string'
  },
  {
    key: 'approximate',
    valueType: 'boolean'
  },
  {
    key: 'throttle',
    valueType: 'number'
  },
  {
    key: 'motion',
    valueType: 'boolean'
  },
  {
    key: 'armed',
    valueType: 'number'
  },
  {
    key: 'geofence',
    valueType: 'string'
  },
  {
    key: 'acceleration',
    valueType: 'number'
  },
  {
    key: 'deviceTemp',
    valueType: 'number',
    dataType: 'temperature'
  },
  {
    key: 'operator',
    valueType: 'string'
  },
  {
    key: 'command',
    valueType: 'string'
  },
  {
    key: 'blocked',
    valueType: 'boolean'
  },
  {
    key: 'dtcs',
    valueType: 'string'
  },
  {
    key: 'obdSpeed',
    valueType: 'number',
    dataType: 'speed'
  },
  {
    key: 'obdOdometer',
    valueType: 'number',
    dataType: 'distance'
  },
  {
    key: 'result',
    valueType: 'string'
  },
  {
    key: 'driverUniqueId',
    valueType: 'string',
    dataType: 'driverUniqueId'
  }
]

export const updateNotifications = ({ devices, positions }) => {
  let idling = []
  let moving = []
  const overSpeed = []
  let towing = []
  let online = []
  let offline = []
  let statusParking = []
  let lowBattery = []
  let stop = []
  let gpsNotUpdated = []
  let notRegistered = []

  if (devices && devices.length) {
    devices.map(device => {
      const id = device.id

      const position = positions[id] ? positions[id] : null

      if (
        position &&
        !moment(device.lastUpdate).isBefore(
          moment().subtract(
            parseInt(device.attributes.storeTime || storeTime),
            'minutes'
          )
        )
      ) {
        if (
          position &&
          !moment(position.serverTime).isBefore(
            moment().subtract(
              parseInt(device.attributes.storeTime || storeTime),
              'minutes'
            )
          )
        ) {
          online = [...new Set([...online, id])]
        } else {
          gpsNotUpdated = [...new Set([...gpsNotUpdated, id])]
        }
      } else if (
        position &&
        moment(device.lastUpdate).isBefore(
          moment().subtract(
            parseInt(device.attributes.storeTime || storeTime),
            'minutes'
          )
        )
      ) {
        offline = [...new Set([...offline, id])]
      } else {
        notRegistered = [...new Set([...notRegistered, id])]
      }

      if (
        position &&
        position.attributes &&
        position.attributes.batteryLevel &&
        position.attributes.batteryLevel <= 15
      ) {
        lowBattery = [...new Set([...lowBattery, id])]
      }

      if (
        position &&
        !moment(position.serverTime).isBefore(
          moment().subtract(
            parseInt(device.attributes.storeTime || storeTime),
            'minutes'
          )
        ) &&
        device
      ) {
        const { ignition, motion, parking, stopTime } = position.attributes
        // idling
        
        if (ignition && device && device.attributes && parseInt(stopTime) >
        (parseInt(device.attributes.minimalParkingDuration) * 1000)) {
          idling = [...new Set([...idling, id])]
        } else if (
          (ignition && motion) ||
          (position.protocol === 'osmand' && motion)
        ) {
          // moving
          moving = [...new Set([...moving, id])].sort()
        } else if (ignition !== true && motion) {
          // towing
          towing = [...new Set([...towing, id])].sort()
        } else if (parking) {
          statusParking = [...new Set([...statusParking, id])].sort()
        } else if (device && device.attributes && parseInt(stopTime) <
        (parseInt(device.attributes.minimalParkingDuration) * 1000)) {
         
          stop = [...new Set([...stop, id])].sort()
        }
      }
      return null
    })
  }

  return {
    overSpeed: overSpeed,
    Idling: idling,
    harshBreak: [],
    Moving: moving,
    Towing: towing,
    statusOnline: online,
    statusOffline: offline,
    statusParking: statusParking,
    lowBattery: lowBattery,
    gpsNotUpdated: gpsNotUpdated,
    notRegistered: notRegistered,
    stop: stop
  }
}


export const PrepareAddressHTML = React.memo(({copyToClipboard, lat, lng, value, index}) => <Tooltip classes={{
    popper: 'menu-popper',
    tooltip: 'menu-popper-tooltip'
  }} placement="top-start" title="Right Click for more">
    <div>
    {/* <ContextMenuTrigger id={"menuitem"+index}>
      <Link
        target='_blank'
        to={'/maps/' + lat + '/' + lng}
      >
        {value ? parse(value) : null}
      </Link>
    </ContextMenuTrigger>
    <ContextMenu id={"menuitem"+index} style={{zIndex: 9}}>
      <MenuItem onClick={e => window.open('/maps/' + lat + '/' + lng, '_blank')}>View on Map</MenuItem>
      <MenuItem onClick={e => copyToClipboard({text: lat+','+ lng})}>Copy coordinates</MenuItem>
      <MenuItem onClick={e => copyToClipboard({text: parse(value)})}>Copy Address</MenuItem>
    </ContextMenu> */}
  </div>
</Tooltip>
);


export const PrepareAddress = props => {

  const dispatch = useDispatch();
  
  const copyToClipboard = obj => {
    const el = document.createElement('textarea')
    el.value = obj.text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    dispatch(
    notifySuccess("Copied to clipboard!")
    )
  }
  const { columnName, start, end, original, index, alternate } = props;
    let row = {}
    const p = original; 
    p.map(obj => {
      row[obj.f] = obj
      return null
    })
      
  if (row[start] && row[end]) {
    return <PrepareAddressHTML copyToClipboard={copyToClipboard} lat={row[start].v} lng={row[end].v} value={row[columnName].v} index={columnName+"_"+index} />
  }
  else if (row[alternate]) {

    const val =  (""+row[alternate].v).split(',') || [];

    if(val && val.length > 1)
    
    return <PrepareAddressHTML copyToClipboard={copyToClipboard} lat={val[0]} lng={val[1]} value={row[columnName].v} index={columnName+"_"+index} />
  } else {
    return null;
  }
  return null;
}
export const prepareCalendar = data => {
  const {
    name,
    slots,
    weeklySlots,
    after,
    interval,
    allDay,
    freq,
    count,
    until,
    byDay,
    byMonth,
    byMonthDay
  } = data

  const cal = cal({
    name
  })

  if (freq === 'YEARLY' && byMonth.length && byMonthDay.length) {
    byMonth.sort((a, b) => a - b)
    byMonthDay.sort((a, b) => a - b)

    slots.map(slot => {
      const startDate = moment.utc(
        moment().format('Y') + '-' + byMonth[0] + '-' + byMonthDay[0],
        'Y-M-D'
      )
      const startTime =
        startDate.format('YYYY-MM-DD') +
        ' ' +
        moment(slot.startTime, 'YYYY-MM-DD HH:mm').format('HH:mm')

      const endDate = moment.utc(
        moment().format('Y') + '-' + byMonth[0] + '-' + byMonthDay[0],
        'Y-M-D'
      )

      const endTime =
        endDate.format('YYYY-MM-DD') +
        ' ' +
        moment(slot.endTime, 'YYYY-MM-DD HH:mm').format('HH:mm')

      const e = cal.createEvent({
        summary: name,
        start: allDay
          ? startDate.startOf('day')
          : moment(startTime, 'YYYY-MM-DD HH:mm'),
        end: allDay
          ? endDate.add(1, 'day').startOf('day')
          : moment(endTime, 'YYYY-MM-DD HH:mm'),
        allDay
      })

      const settings = {
        freq,
        count,
        interval
      }

      if (after === 'ON') settings.until = until
      settings.byMonth = byMonth
      settings.byMonthDay = byMonthDay

      e.repeating(settings)
      return null
    })
  } else if (freq === 'WEEKLY') {
    byDay.map(d => {
      const startTime =
        moment
          .utc()
          .day(d)
          .startOf('day')
          .format('YYYY-MM-DD') +
        ' ' +
        moment.utc(weeklySlots[d].startTime, 'YYYY-MM-DD HH:mm').format('HH:mm')
      const endTime =
        moment
          .utc()
          .day(d)
          .startOf('day')
          .format('YYYY-MM-DD') +
        ' ' +
        moment.utc(weeklySlots[d].endTime, 'YYYY-MM-DD HH:mm').format('HH:mm')

      const e = cal.createEvent({
        summary: name,
        start: weeklySlots[d].allDay
          ? moment
              .utc()
              .day(d)
              .startOf('day')
          : moment(startTime).toISOString(),
        end: weeklySlots[d].allDay
          ? moment
              .utc()
              .day(d)
              .startOf('day')
              .add(1, 'day')
          : moment(endTime).toISOString(),
        allDay: weeklySlots[d].allDay
      })
      const settings = {
        freq
        // count,
        // interval
      }

      if (after === 'ON') settings.until = until
      settings.byDay = [d]

      e.repeating(settings)
      return null
    })
  }
  return cal.toString()
}

export const errorHandler = (error, dispatch, callback) => {
  // console.log('err in helper =====', error)
  if(error?.message?.includes("HINT")){
    let message = error.message.split(":")[1]
    if(message.includes("Device already in used")){
      // dispatch(
        notifyError('Device is already attached')
      // )
    }
    else if(message.includes("Data truncation")){
      // dispatch(
        notifyError('Data is  too long')
      // )
    }
    else if(message.includes("Duplicate entry")){
      // dispatch(
        notifyError('Duplicate entry')
      // )
    }
    else if(error?.statusCode === '501'){
      let message = error.message.split(":")[1]
      // dispatch(
        notifyError(message.replace(')',''))
      // )
    }
    else{
      // dispatch(
        notifyError(message.replace(')',''))
      // )
    }
}else if(error?.statusCode === '440'){
  // dispatch(
    notifyError(error?.message)
  // )
  window.location.replace('/logout')
}
else if(error?.statusCode === '501'){
  let message = error.message.split(":")[1]
  // dispatch(
    notifyError(message.replace(')',''))
  // )
}
else {
  if(!axios.isCancel(error)){
    // dispatch(
    notifyError("somthingWentWrongMessage")
    // )
  }
}
  callback && typeof callback === 'function' && callback(error)
}

export const categoriesList = [
  'default',
  'arrow',
  'car',
  'pickup',
  'doublecabinpickup',
  'threetonpickup',
  'van',
  'suv',
  'offroad',
  'minibus',
  'smallbus',
  'bus',
  'train',
  'tram',
  'trolleybus',
  'truck',
  'forklift',
  'tractor',
  'dumper',
  'roadroller',
  'excavator',
  'boomloader',
  'crane',
  'dumptruck',
  'tanker',
  'flatbedtruck',
  'container',
  'mixer',
  'bulldozer',
  'skidloader',
  'plane',
  'helicopter',
  'boat',
  'ship',
  'bicycle',
  'motorcycle',
  'scooter',
  'person',
  // 'shovel',
  'biggenerator',
  'generator',
  'animal',
  'tricycle'
]

export const widgetCategories = {
  "1": <Translate id="statistics" />,
  "2": <Translate id="graphs" />,
  "3": <Translate id="sensors" />,
}

export const Api = 'http://185.230.208.33:3003'

export const  nFormatter =(num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}