import React, { Component } from "react";
import { Trans as Translate} from 'react-i18next';
import { connect } from "react-redux";
import { checkPrivileges, errorHandler } from "../../Helpers";
import Layout from "./../../Layout";
import L from 'leaflet'
import TextField from "../../Components/common/TextField";
import Button from "../../Components/common/Button";
import { addGarages, getGarages } from "../../Actions/Garages";

import Grid from "@mui/material/Grid";
import instance from "../../axios";
import EditIcon from "@mui/icons-material/Place";
import IconButton from "@mui/material/IconButton";
import CustomDialog from "../../Components/common/Dialog";
import MarkerMap from "../../Components/common/Maps";
import Tooltip from "../../Components/common/Tooltip";
import { removedGarages } from "../../Actions/Garages";
import axios from "axios";
import moment from 'moment'
import {
  MapContainer as Map,

  TileLayer,
  ZoomControl,
  LayersControl,
  Marker,
  Popup
} from 'react-leaflet'
// import CustomMarker from "../../Components/Maps/CustomMarker";
import { MapTooltip } from "../../Components/Maps/MapTooltip";
import { MapTileLayers } from "../../Reducers/Maps";
import SingleSelect from '../../Components/common/SingleSelect'
import { notifySuccess } from "../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../HOC/withTranslation";
// import SingleSelect from "../common/SingleSelect";


const GarageTypes = [
  {
    key: "pe",
    name: "PE",
  },
  {
    key: "pcvc",
    name: "PCVC",
  },
  {
    key: "pac",
    name: "PAC",
  },
  {
    key: "pcvcExpress",
    name: "PCVC Express",
  },
  {
    key: "tristar",
    name: "Tristar",
  },

];
const CancelToken = axios.CancelToken;
let source;

class Garage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      garageForm: {
        map: "osm",
        latitude: "",
        longitude: "",
      },
      setGarageForm: {},
      mode: "",
      isVisableSubmitBtn: true,
      openMap: false,
      model: false,
      assignMode: "",
      currentCommand: "",
      entityId: 0,
      allGaragesLocation: [],
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      searchText: "",
      cityText: "",
      typeText: "",
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 1,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
        cityText: "",
        typeText: "",
      },
      deleteForm: {},
      garagesS: [],
      isMapModal: false,
      isVisableAddlocBtn: false,
      localPostionObj: "",
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      intialFetch: true,
      showSingleGarage: true,
      singleGarage: ""
    };
    this.mapReference = this.mapReference.bind(this)
  }

  componentWillUnmount() {
    this.setState({
      garageForm: {},
      mode: "",
      model: false,
      assignMode: "",
      currentCommand: "",
      entityId: 0,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 1,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
        cityText: "",
        typeText: "",
      },
      intialFetch: true,
      checkEmpty: false
    });
  }

  componentWillMount() {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
          // this.fetchTypes()
        }
      );
    }
  }

  componentWillReceiveProps(n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
          // this.fetchTypes()
        }
      );
    }
  }

  handleChangeLicenseExpDate = (name) => (event) => {
    if (name === "created") {
      this.setState(
        {
          garageForm: {
            ...this.state.garageForm,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  fetchMoreItems = (a, b, c) => {
    let pagination;
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize,
      (pagination = true)
    );
  };

  searchItems = (text, check, name) => {
    let pagination;
    if (source) {
      source.cancel();
    }
    if (name === "searchText" || name === "cityText" || name === "typeText") {
      this.setState(
        {
          [name]: text,
        },
        () => {
          this.fetchData(this.props.logInUser, 1, 50, (pagination = true), check);
        }
      );
    }
  };

  fetchGarages = (text, garages) => {
    const u =
      garages &&
      garages.data.filter((u) =>
        (u.id + u.name).toLowerCase().includes(text.toLowerCase())
      );
    this.setState({
      garagesS: u,
    });
  };
  fetchData = (logInUser, page, perPage, pagination, search) => {
    source = CancelToken.source();
    let items = [...this.state.itemPagination.items]; // Make a copy of the items array

    instance({
      method: "GET",
      url: `/api/garages/get?userId=${logInUser.id}&page=${page}&limit=${perPage}&all=${true}&search=${this.state.searchText}&garageCity=${this.state.cityText}&garageType=${this.state.typeText}`,
      cancelToken: source.token,
    })
      .then((res) => {

        this.setState(
          (prevState) => ({
            itemPagination: {
              ...res,
              items: pagination && !search ? [...prevState.itemPagination.items, ...res.data] : res.data, // Concatenate the new data with the previous items
            },
            currentPage: res.hasNext ? res.page + 1 : res.page,
            garagesS: pagination ? [...prevState.garagesS, ...res.data] : res.data,
            checkEmpty: res.data && res.data.length ? false : true,
            allGaragesLocation: res.data,
            showSingleGarage: true,
          }),
          () => {
            this.props.dispatch(getGarages(res));
            if (
              this.state.intialFetch &&
              res.data &&
              res.data[0]
            ) {
              this.onOpenMap(res.data[0]);
            }
          }
        );
      })
      .catch((error) => {
        // Handle the error
      });
  };


  // fetchData = (logInUser, page, perPage, pagination) => {
  //   source = CancelToken.source();
  //   let items = this.state.itemPagination && this.state.itemPagination.items;
  //   instance({
  //     method: "GET",
  //     url: `/api/garages/get?userId=${
  //       logInUser.id
  //     }&page=${page}&limit=${perPage}&all=${true}&search=${
  //       this.state.searchText
  //     }`,
  //     cancelToken: source.token,
  //   })
  //     .then((res) => {
  //       this.setState(
  //         {
  //           itemPagination: {
  //             ...res,
  //             items: items.concat(res.data),
  //           },
  //           currentPage: res.hasNext ? res.page + 1 : res.page,
  //           garagesS: pagination ? items.concat(res.data) : res.data,
  //           checkEmpty:res.data && res.data.length ? false:true,
  //           allGaragesLocation:res.data
  //         },
  //         () => {
  //           this.props.dispatch(getGarages(res));
  //           if(this.state.intialFetch && (res.data&&res.data[0])) this.onOpenMap(res.data[0])
  //         }
  //       );
  //     })
  //     .catch((error) => {
  //       // errorHandler(error, this.props.dispatch)
  //     });
  // };

  openCreateFrom = () => {
    this.setState({ mode: "", garageForm: {}, openMap: false, }, () => {
      this.setState({
        mode: "create",
        garageForm: {
          attributes: {},
          created: " ",
          modified: null,
          parentId: 1,
          // directAccess: true,
          // groupAccess: true,
        },
        checkEmpty: false
      });
    });
  };

  editGarageForm = (garageForm) => {
    this.setState({ mode: "", garageForm: {} }, () => {
      this.setState({
        mode: "update",
        openMap: false,
        garageForm: {
          ...garageForm,
          attributes: { ...garageForm.attributes },
        },
      });
    });
  };

  closeCreateFrom = () => {
    this.setState(
      {
        mode: "",
        garageForm: "",
        openMap: true
      },
      () => this.checkRequiredFields()
    );
  };


  checkRequiredFields = () => {
    // let { name,description,email,phone,garageArea,garageManager,garageNumber, latitude,longitude,address } = this.state.garageForm
    // if (name&&description&&email&&phone&&garageArea&&garageManager&&garageNumber&& latitude&&longitude&&address) {
    let { name } = this.state.garageForm;
    if (name) {
      this.setState({
        isVisableSubmitBtn: false,
      });
    } else {
      this.setState({
        isVisableSubmitBtn: true,
      });
    }
  };

  submitForm = () => {
    let { attributes, id, description } = this.state.garageForm;
    let obj = { ...this.state.garageForm };

    let params = "";
    if (this.state.mode === "update") {
      params = id;
    }
    this.setState({
      isVisableSubmitBtn: true,
    });
    if (obj) {
      instance({
        url: `/api/garages/${params}`,
        method: `${this.state.mode === "update" ? "PUT" : "POST"}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((garage) => {
          if (this.state.mode === "update") {
            this.props.dispatch(addGarages(garage));
            this.props.dispatch(
             notifySuccess("garageIsUpdated")
            );
            this.closeCreateFrom();
          } else {
            this.props.dispatch(addGarages(garage));
            this.props.dispatch(
             notifySuccess("garageIsCreated")
            );
            this.editGarageForm(garage);
          }
          this.fetchData(this.props.logInUser, 1, 50);
        })
        .catch((error) => {
          console.log("error ===", error);
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  onDelete = (id) => {
    if (id) {
      instance({
        url: `api/garages/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.props.dispatch(removedGarages({ id: id }));
          this.props.dispatch(
            notifySuccess("GaragesIsDeleted")
          );
          // this.props.modeEmpty()
          // this.setState({ item: {} })
          this.fetchData(this.props.logInUser, 1, 50);
          this.closeCreateFrom();
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  onRemovedItem = (item) => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true,
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false,
    });
  };

  //loc
  getPostion = (latlng) => {
    if (latlng.lat && latlng.lng) {
      this.setState({
        localPostionObj: { latlng },
        isVisableAddlocBtn: true,
      });
    }
  };
  setSelectedLocation = () => {
    const { localPostionObj } = this.state;
    if (localPostionObj) {
      this.setState(
        {
          garageForm: {
            ...this.state.garageForm,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
          },
          isMapModal: false,
        },
        () => this.checkRequiredFields()
      );
    }
  };
  onCloseModal = () => {
    this.setState({
      isMapModal: false,
    });
  };

  onOpenMap = (item) => {
    this.setState({
      openMap: true,
      setGarageForm: item,
      showSingleGarage: !this.state.showSingleGarage,
      zoom: 7,
      singleGarage: item
    }, () => {
      if (this.map) {
        const { latitude, longitude } = item;
        const zoom = this.state.zoom;
        this.map.setView([latitude, longitude], zoom);
      }
    })
  }

  handleChange = (name, value) => {
    this.setState(
      {
        garageForm: {
          ...this.state.garageForm,
          attributes: {},
          [name]: value,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange1 = (name, event) => {
    // if(name==="garageType"){
    this.setState(
      {
        garageForm: {
          ...this.state.garageForm,
          attributes: {},
          [name]: event.id,
        },
      },
      () => this.checkRequiredFields()
    );

  }

  mapReference(el) {
    if (el) {
      this.map = el
    }
  }

  render() {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          -- {this.props.translate("end")} --
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {this.props.translate("notFound")}
        </p>
      );
    let crs = {}
    if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }
    const body = [
      <>
        <ZoomControl position={'bottomright'} />
      </>
    ]
    const position = [this.state.setGarageForm.latitude, this.state.setGarageForm.longitude]

    const thisMap = [
      <Map
        key={1}
        ref={this.mapReference}
        onZoomAnim={this.checkZoom}
        zoomControl={false}
        bounds={
          this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
        boundsOptions={this.setBoundOptions}
        style={{ height: this.props.height, width: this.props.width }}
        center={

          position
        }
        zoom={this.state.zoom}
        minZoom={this.state.minZoom}
        maxZoom={this.props.mapLayer.maxZoom}
        maxNativeZoom={this.props.mapLayer.maxZoom}
        {...crs}
      >
        {this.state.pointer}
        {body}

        {this.state.showSingleGarage ? <>
          <>
            <Marker
              key={this.state.singleGarage.id}
              position={{ lat: this.state.singleGarage.latitude, lng: this.state.singleGarage.longitude, updated: moment(this.state.garageForm.created) }}
              rotationAngle={0}
              rotationOrigin='center'
              animationTime={this.state.animCount > 0 && this.state.applied === true & this.props.trackId === this.state.singleGarage.id ? this.state.singleGarage.animationTime : 0}
              icon={L.divIcon({
                iconUrl: '/assets/category/default/garagetop.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 25],
                tooltipAnchor: [0, -20],
                className: 'custom-marker',
                html: `<img  src='/assets/category/default/garagetop.svg' alt='' />`
              })}
              iconSize={[50, 50]}
              category={"garage"}
            >
              <Popup>
                <MapTooltip
                  themecolors={this.props.themecolors}
                  position={this.state.singleGarage}
                  device={this.state.singleGarage}
                  garage
                  trailer={this.state.singleGarage.garageArea}
                  // trailer2={this.state.selecteditem}
                  logInUser={this.props.logInUser}
                  translate={this.props.translate}
                  className="actionButton"
                  fontSize="small"
                />
              </Popup>
            </Marker> </>

        </> :
          <>

            {this.state.allGaragesLocation
              && Object.keys(this.state.allGaragesLocation).length ?
              Object.values(this.state.allGaragesLocation).map(row =>
                <>
                  <Marker
                    key={row.id}
                    position={{ lat: row.latitude, lng: row.longitude, updated: moment(this.state.garageForm.created) }}
                    rotationAngle={0}
                    rotationOrigin='center'
                    animationTime={this.state.animCount > 0 && this.state.applied === true & this.props.trackId === row.id ? row.animationTime : 0}
                    icon={L.divIcon({
                      iconUrl: '/assets/category/default/garagetop.svg',
                      iconSize: [50, 50],
                      iconAnchor: [25, 25],
                      tooltipAnchor: [0, -20],
                      className: 'custom-marker',
                      html: `<img  src='/assets/category/default/garagetop.svg' alt='' />`
                    })}
                    iconSize={[50, 50]}
                    category={"garage"}
                  >
                    <Popup>
                      <MapTooltip
                        themecolors={this.props.themecolors}
                        position={row}
                        device={row}
                        garage
                        trailer={row.garageArea}
                        // trailer2={this.state.selecteditem}
                        logInUser={this.props.logInUser}
                        translate={this.props.translate}
                        className="actionButton"
                        fontSize="small"
                      />
                    </Popup>
                  </Marker> </>)
              : null}
          </>}



        <LayersControl position="bottomright">
          {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === this.props.mapLayer.id} key={layer.id} name={layer.name}>
            <TileLayer {...layer} minZoom={this.state.minZoom} />
          </LayersControl.BaseLayer>)}
        </LayersControl>
      </Map>
    ]



    //     const thisMap = [
    // <Map  
    //         key={1}
    //         ref={this.mapReference}
    //         onZoomAnim={this.checkZoom}
    //         zoomControl={false}
    //         bounds={
    //           this.state.bounds && this.state.bounds.length
    //             ? this.state.bounds
    //             : null
    //         }
    //         boundsOptions={this.setBoundOptions}
    //         style={{ height: this.props.height, width: this.props.width }}
    //         center={position}
    //         zoom={this.state.zoom}
    //         minZoom={this.state.minZoom}
    //         maxZoom={this.props.mapLayer.maxZoom}
    //         maxNativeZoom={this.props.mapLayer.maxZoom}
    //         {...crs}
    //         iconSize={[50, 50]}
    //       // center={position}
    //       //  zoom={this.ZoomControl}
    //       >
    //        <LayersControl position="bottomright">
    //              {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === this.props.mapLayer.id} key={layer.id} name={layer.name}>
    //                <TileLayer {...layer} minZoom={this.state.minZoom} />    
    //              </LayersControl.BaseLayer>)}
    //            </LayersControl>
    //   {this.state.allGaragesLocation.map((garage) => (
    //     <Marker
    //       key={garage.id}
    //       icon={L.divIcon({
    //         iconUrl: '/assets/category/default/garagetop.svg',
    //         iconSize: [50, 50],
    //         iconAnchor: [25, 25],
    //         tooltipAnchor: [0, -20],
    //         className: 'custom-marker',
    //         html: `<img  src='/assets/category/default/garagetop.svg' alt='' />`
    //       })} 

    //       position={[garage.latitude, garage.longitude]}
    //     >
    //       <Popup>
    //       <MapTooltip
    //          garage
    //         themecolors={this.props.themecolors}
    //         position={garage}
    //         device={garage}
    //         // trailer={garage.garageArea}
    //         // trailer2={this.state.selecteditem}
    //         logInUser={this.props.logInUser}
    //         translate={this.props.translate}
    //         className="actionButton"
    //         fontSize="small"
    //       />
    //       </Popup>
    //     </Marker>
    //   ))}
    // </Map>

    //     ]

    if (checkPrivileges("garage")) {
      const pp = {
        ...this.props,
        garagesS: this.state.garagesS,
      };

      const myStyle = {
        backgroundImage: "url(/assets/category/default/emptyfolder.svg)",
        height: '150px',
        width: '150px',
        marginLeft: "200px"
      }

      return (
        <div>
          <Layout
            {...pp}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            editGarageForm={this.editGarageForm}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            deleteItem={this.onDelete}
            onOpenMap={this.onOpenMap}
            {...this.state}
          >
            {!this.state.checkEmpty ?
              <div className="main-content-page">
                {this.state.openMap ? <div>{['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
                  {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
                  {['gccStreet'].includes(this.props.mapLayer.id) ? thisMap : null}
                  {['googleTerrain'].includes(this.props.mapLayer.id)
                    ? thisMap
                    : null}
                  {['googleSatellite'].includes(this.props.mapLayer.id)
                    ? thisMap
                    : null}
                  {['googleHybrid'].includes(this.props.mapLayer.id) ? thisMap : null}
                  {['googleRoad'].includes(this.props.mapLayer.id) ? thisMap : null}
                  {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
                  {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)
                    ? thisMap
                    : null}
                </div> : null}

                {/* {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removeItem}
                title={this.props.translate('areYouWantToDelete')}
                children={this.state.deleteForm.name || this.state.deleteForm.description}
                />
                */}
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    paddingTop: 10,
                  }}
                >
                  {(this.state.mode === "create" ||
                    this.state.mode === "update") && (!this.state.openMap) ? (
                    <CreateGarageForm
                      submitForm={this.submitForm}
                      mode={this.state.mode}
                      closeCreateFrom={this.closeCreateFrom}
                      form={this.state.garageForm}
                      handleChange={this.handleChange}
                      handleChange1={this.handleChange1}
                      handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
                      isVisableSubmitBtn={this.state.isVisableSubmitBtn}
                      translate={this.props.translate}
                      handleClickShowPostion={this.handleClickShowPostion}
                      setSelectedLocation={this.setSelectedLocation}
                      getPostion={this.getPostion}
                      onCloseModal={this.onCloseModal}
                      isVisableAddlocBtn={this.state.isVisableAddlocBtn}
                      isMapModal={this.state.isMapModal}
                      themeColors={this.props.themecolors}
                    />
                  ) : null}
                  {/* {this.state.mode === "" ? (
                  <EmptyState text={this.props.translate("noGargeSelected")} />
                ) : null} */}
                </div>
              </div> :
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                }}
              >
                <div style={myStyle}></div>
              </div>}
          </Layout>
        </div>
      );
    } else {
      return null;
    }
  }
}

const CreateGarageForm = ({
  form,
  handleChange,
  translate,
  handleChangeLicenseExpDate,
  handleClickShowPostion,
  submitForm,
  setSelectedLocation,
  getPostion,
  onCloseModal,
  themeColors,
  isVisableAddlocBtn,
  isMapModal,
  closeCreateFrom,
  mode,
  isVisableSubmitBtn,
  handleChange1
}) => {
  return (
    <div>
      <h4 className="page-title">{translate("workShops")}</h4>
      {isMapModal && (
        <CustomDialog
          title={translate("setLocation")}
          themecolors={themeColors}
          visable={true}
          onClose={() => onCloseModal()}
          isVisableBtn={true}
          draggable={true}
          CancelText={translate("sharedBack")}
          headerActions={
            <Button
              size="small"
              variant="contained"
              color="secondary"
              disabled={!isVisableAddlocBtn}
              onClick={() => setSelectedLocation()}
            >
              {" "}
              {translate("sharedAdd")}
            </Button>
          }
          bodyPadding={0}
        >
          <MarkerMap
            getPostion={(latlng) => getPostion(latlng)}
            minHeight={500}
            zoom={form.zoom || 14}
            lat={form.latitude || 0}
            lng={form.longitude || 0}
            height={"100%"}
          />
        </CustomDialog>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            required
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="description"
            margin="dense"
            label={translate("sharedDescription")}
            variant="outlined"
            fullWidth
            value={form.description}
            onChange={(e) => handleChange("description", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="garageNumber"
            type="number"
            margin="dense"
            label={translate("garageNumber")}
            variant="outlined"
            fullWidth
            value={form.garageNumber}
            onChange={(e) => handleChange("garageNumber", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="garageArea"
            margin="dense"
            label={translate("garageArea")}
            variant="outlined"
            fullWidth
            value={form.garageArea}
            onChange={(e) => handleChange("garageArea", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="garageManager"
            margin="dense"
            label={translate("garageManager")}
            variant="outlined"
            fullWidth
            value={form.garageManager}
            onChange={(e) => handleChange("garageManager", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="email"
            margin="dense"
            label={translate("userEmail")}
            variant="outlined"
            fullWidth
            value={form.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="phone"
            margin="dense"
            label={translate("sharedPhone")}
            variant="outlined"
            fullWidth
            value={form.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="address"
            margin="dense"
            label={translate("address")}
            variant="outlined"
            fullWidth
            value={form.address}
            onChange={(e) => handleChange("address", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SingleSelect
            array={GarageTypes}
            // async
            selectName="garageType"
            // isClearable
            label={translate("garageType")}
            value={
              form && form?.garageType
                ? {
                  id: form?.garageType.key || form?.garageType,
                  value: form?.garageType.name || form?.garageType,
                  label: form?.garageType.name || form?.garageType,
                }
                : ""
            }
            // value={this.props.form.reportNumber || ""}
            handleChange={handleChange1}
            canAssign={true}
          />
          {/* <TextField
            id="garageType"
            margin="dense"
            label={translate("garageType")}
            variant="outlined"
            fullWidth
            value={form.garageType}
            onChange={(e) => handleChange("garageType", e.target.value)}
          /> */}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="latitude"
            type="number"
            margin="dense"
            label={translate("positionLatitude")}
            placeholder=""
            variant="outlined"
            fullWidth
            value={form.latitude ? form.latitude : ""}
            onChange={(e) => handleChange("latitude", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="longitude"
            type="number"
            margin="dense"
            label={translate("positionLongitude")}
            placeholder=""
            variant="outlined"
            fullWidth
            value={form.longitude ? form.longitude : ""}
            onChange={(e) => handleChange("longitude", e.target.value)}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Tooltip title={translate("addLocation")}>
            <IconButton onClick={() => handleClickShowPostion()}>
              {<EditIcon style={{ color: "currentColor" }} />}
            </IconButton>
          </Tooltip>
        </Grid>
        {/* <Tooltip title={this.props.translate('addLocation')}>
              <IconButton onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip> */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
           <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                margin='dense'
                label={translate('created')}
                variant='outlined'
                fullWidth
                value={form.created? new Date( `${form.created}` ).toString() : null}
                onChange={handleChangeLicenseExpDate('created')}
                InputProps={{
                  classes: {
                    root: 'theme-cssOutlinedInput',
                    input: 'theme-input',
                    focused: 'theme-cssFocused',
                    notchedOutline: 'theme-notchedOutline'
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: 'theme-label',
                    focused: 'theme-label-focused',
                    shrink: 'theme-label-shrink'
                  }
                }}
              />
          </LocalizationProvider>
        </Grid> */}

        {/* <Grid item xs={12}>
            <Checkbox
              checked={form.attributes.enable}
              onChange={e => handleChangeAttributes('archive', e.target.checked)}
              color='primary'
              label={translate('archive')}
              name='enable'
            />
          </Grid> */}
      </Grid>

      <div style={{ display: "flex", marginTop: 10 }}>
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeCreateFrom}
        >
          {translate("sharedCancel")}{" "}
        </Button>
        {checkPrivileges("garageCreate") && (
          <Button
            size="small"
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === "create" ? "sharedCreate" : "update")}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selecteduser: state.selecteduser,
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  garages: state.garages,
  mapLayer: state.mapLayer
});

export default connect(mapStateToProps)(withTranslationWrapper(Garage))
