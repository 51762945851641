  import React, { Component } from "react";
import { Trans as Translate} from 'react-i18next';

  import { connect } from "react-redux";
  import axios from "axios";
  import { checkPrivileges, errorHandler } from "../../Helpers";
  import Layout from "../../Layout";
  import TextField from "../../Components/common/TextField";
  import Button from "../../Components/common/Button";
  
  import Grid from "@mui/material/Grid";
  import instance from "../../axios";
  import Table from "../../Components/common/tableWithBackEndPagination";
  import Loader from "../../Layout/Loader";
  import ExportImportMenu from "../../Components/Maps/ExportImportMenu";
  import CustomDialog from "../../Components/common/Dialog";
import { notifyError } from "../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../HOC/withTranslation";
  // import { CreateInvoice } from './CreateInvoice'
  const CancelToken = axios.CancelToken;
  let source = "";
  // const packageItem = {
  //   itemName:'',
  //   cost:'',
  //   quantity:''
  // }
  // const packageService = {
  //   serviceName:'',
  //   cost:'',
  //   quantity:''
  // }

  // const defaultServices = {
  //   serviceName:"",
  //   serviceTotalAmount:"",
  //   serviceVATAmount:"",

  // }
  // const packageDefaultForm={
  //   packageCode:'',
  //   packageName:'',
  //   packageTotalAmount:'',
  //   PackageDiscount:'',
  //   PackageVATAmount:'',
  //   PackageNetAmount:'',
  //   service:[packageService],
  //   items:[packageItem]
  // }

  // const defaultItems={
  //   itemName:"",
  //   itemQuantity:"",
  //   itemVATAmount:"",
  //   itemPrice:"",
  // }
  class Invoice extends Component {
    constructor(props) {
      super(props);
      this.state = {
        initFetch: false,
        currentPage: 1,
        pagesize: 50,
        showStats: false,
        selectedAreaId: "",
        selectedvehicleId: "",
        to: "",
        invoiceStatus:"",
        from: "",
        loader: false,
        openCreateForm: false,
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: this.props.deviceId,
          hasNext: true,
          searchText: "",
        },
        showImportedInvoiceError: false,
        importedInvoiceData: {},
        deliveryNoteNumber:"", 
        deliveryNoteRefNumber:"", 
        plateNumber: "",
        stationCity: ""
        // invoiceForm:{
        //   plateNumber:"",
        //   customer:{},
        //   Invoice:[],
        //   Services:[defaultServices],
        //   items:[defaultItems]
        // },
        // Package:packageDefaultForm,
      };
    }

    componentWillUnmount() {
      this.setState({
        assignMode: "",
        currentPage: 1,
        pagesize: 50,
        selectedvehicleId: "",
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: this.props.deviceId,
          hasNext: true,
          searchText: "",
        },
      });
    }

    componentWillMount() {
      if (
        this.props.logInUser &&
        this.props.logInUser.id &&
        this.state.initFetch === false
      ) {
        this.setState(
          {
            initFetch: true,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    }

    // componentWillReceiveProps(n) {
    //   if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
    //     this.setState(
    //       {
    //         initFetch: true,
    //       },
    //       () => {
    //         this.fetchData(
    //           this.props.logInUser,
    //           this.state.currentPage,
    //           this.state.pagesize
    //         );
    //       }
    //     );
    //   }
    // }

    fetchMoreItems = (a, b, c) => {
      let pagination = true;
      this.fetchData(
        this.props.logInUser,
        this.state.currentPage,
        this.state.pagesize,
        pagination
      );
    };

    searchItems = (text) => {
      this.setState(
        {
          searchText: text,
        },
        () => {
          this.fetchData(this.props.logInUser, 1, this.state.pagesize);
        }
      );
    };
    // fetchInvoices = (text, allInvoices) => {
    //   const u = allInvoices&&allInvoices.filter(u =>
    //     (u.id + u.name).toLowerCase().includes(text.toLowerCase())
    //   )
    //   this.setState(
    //     {
    //       invoices:u
    //     }
    //   )
    // }

    setPagination = (user) => {
      const { itemPagination } = this.state;
      if (itemPagination && itemPagination.items && itemPagination.items.length) {
        let nUser = { ...user };
        let lastPage = nUser.total / this.state.pagesize;
        let IsFloate = this.checkFloteNumber(lastPage);
        delete nUser.data;
        nUser.pageSize = this.state.pagesize;
        nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
        return nUser;
      }
      return {};
    };

    handleChangeRowsPerPage = (value, mode) => {
      if (source) {
        source.cancel();
      }
      if (mode === "deliverynotes") {
        this.setState(
          {
            pagesize: value,
            currentPage: 1,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    };

    handleChangePage = (value, mode) => {
      if (source) {
        source.cancel();
      }
      if (mode === "deliverynotes") {
        this.setState(
          {
            currentPage: value,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    };

    checkFloteNumber(n) {
      return Number(n) === n && n % 1 !== 0;
    }

    
    SearchItem = (searchValue, mode) => {
      // this.state.deliveryNoteNumber || this.state.deliveryNoteRefNumber
      if (source) {
        source.cancel();
      }
      this.setState(
        {
          
          [mode]: searchValue,
          currentPage: 1,
        },
        // () => {
        //   if (mode === "deliveryNoteNumber"||mode === "deliveryNoteRefNumber"||mode === "plateNumber"||mode === "stationCity") {
        //     this.fetchData(
        //       this.props.logInUser,
        //       this.state.currentPage,
        //       this.state.pagesize
        //     );
        //   }
        // }
      );
    };

   

    toggleFilterForm = () => {
      this.setState({
        showStats: !this.state.showStats,
      });
    };

    submitSideBarSearch = (data, type) => {
      const queryString = data.garageVerticals.map(item => `&dnTypes=${item}`).join('');

      this.setState(
        {
          loader: true,
          to: `&to=${data.to}`,
          from: `&from=${data.from}`,
          invoiceStatus:`&invoiceStatus=${data.invoiceStatus}`,
          selectedAreaId: data.selectedAreaId,
          selectedvehicleId: data.selectedvehicleId,
          verticals:queryString || '',
          stationCity:data.area || '',
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
    };
    
    fetchData = (logInUser, page, perPage, pagi, reset) => {
      const { contractType } = this.props.ServerSetting;
      source = CancelToken.source();
      let url = "";
      let searchText = this.state.searchText;
      if (searchText) {
        searchText = "&search=" + searchText;
      } else {
        searchText = "";
      }

      let items = this.state.itemPagination.items;
      if (reset) {
        items = [];
      }
      let invoice = contractType === 2 ? "deliverynotes" : "deliverynotes";
      // fetch(`/api/commands/get?userId=${logInUser.id}&all=true&page=${page}&limit=${perPage}${searchText}`)
      //http://localhost:8090/api/services/invoice/get?page=1&limit=20&search=&vehicleId=1&vehicleId=2&from=2020-06-14T00:00:00.000Z&to=2022-06-14T00:00:00.000Z
      url = this.state.deliveryNoteNumber || this.state.deliveryNoteRefNumber || this.state.plateNumber || this.state.stationCity
        ? `/api/${invoice}/get?page=${page}&limit=${perPage}&deliveryNoteNumber=${this.state.deliveryNoteNumber}&deliveryNoteRefNumber=${this.state.deliveryNoteRefNumber}&plateNumber=${this.state.plateNumber}&stationCity=${this.state.stationCity}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`
        : `/api/${invoice}/get?page=${page}&limit=${perPage}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`;


        if (checkPrivileges("deliverynoteInvalidView")) {
          if (this.state.invoiceStatus !== undefined) {
            url += `${this.state.invoiceStatus}`;
          }
        } else {
          // If the user does not have the privilege, restrict invoiceStatus to 1 only if it has a value
          if (this.state.invoiceStatus !== undefined) {
            url += `${this.state.invoiceStatus}`;
          }
        }

        if(this.state.verticals){
          url += `${this.state.verticals}`;
        }
        if(this.state.stationCity){
          url += `&stationCity=${this.state.stationCity}`;
        }

        this.setState({ isDataRecieved: false},()=>{
      instance({
        url: url,
        method: "GET",
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({
            loader: false,
            invoices: pagi ? items.concat(res.data) : res.data,
            isDataRecieved: true,
            itemPagination: {
              ...res,
              items: pagi ? items.concat(res.data) : res.data,
            },
            currentPage: res.hasNext ? res.page  : this.state.currentPage,
          });
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
      })
    };

    downloadInvoice = (data, type) => {
      const queryString = data.garageVerticals.map(item => `&dnTypes=${item}`).join('');
         
      this.setState(
        {
          loader: true,
          to: `to=${data.to}`,
          from: `&from=${data.from}`,
          invoiceStatus:`&invoiceStatus=${data.invoiceStatus}`,
          verticals:queryString || '',
          stationCity:data.area || '',
          selectedAreaId: data.selectedAreaId,
          selectedvehicleId: data.selectedvehicleId,
        },
        () => {
          this.downloadInvoices(type);
        }
      );
    };

    downloadInvoices = (type) => {
      let accept;
      if (type === "downloadPdf") {
        accept = "application/pdf";
      } else {
        accept =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }
      let header;

      // let apiUrl =  `/api/deliverynotes/export?to=${to}&from=${from}${account}${vehcile}${area}`;
      let apiUrl =  `/api/deliverynotes/export?${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`;

      if (checkPrivileges("deliverynoteInvalidView")) {
        if (this.state.invoiceStatus !== undefined) {
          apiUrl += `${this.state.invoiceStatus}`;
        }
      } else {
        // If the user does not have the privilege, restrict invoiceStatus to 1 only if it has a value
        if (this.state.invoiceStatus !== undefined) {
          apiUrl += `${this.state.invoiceStatus}`;
        }
      }

        if(this.state.verticals){
          apiUrl += `${this.state.verticals}`;
        }
        if(this.state.stationCity){
          apiUrl += `&stationCity=${this.state.stationCity}`;
        }
        // `/api/deliverynotes/export?${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`,

      header = {
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: accept,
        }),
        method: "GET",
      };
      fetch(
        apiUrl,
        { ...header }
      )
        .then((response1) => {
          if (response1.status === 200) {
            console.log("typee", type);
            if (type === "downloadPdf") {
              response1.blob().then((response) => {
                this.setState(
                  {
                    selectedAreaId: "",
                    selectedvehicleId: "",
                    to: "",
                    from: "",
                    loader: false,
                  },
                  () => {
                    this.saveData(response, "DeliveryNotes.pdf");
                  }
                );
              });
            } else {
              response1.blob().then((response) => {
                this.setState(
                  {
                    selectedAreaId: "",
                    selectedvehicleId: "",
                    to: "",
                    from: "",
                    loader: false,
                  },
                  () => {
                    this.saveData(response, "DeliveryNotes.xlsx");
                  }
                );
              });
            }
          } else {
            throw response1;
          }
        })

        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    };

    saveData = (blob, filename) => {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          // this.setState({  : false })
        }, 0);
      }
      // this.setState({  : false })
    };


    downloadInvoicePdf = (item) => {
      let invoice =
        this.props.ServerSetting && this.props.ServerSetting.contractType === 1
          ? "deliverynotes"
          : this.props.ServerSetting.contractType === 2
          ? "deliverynotes"
          : "deliverynotes";

      let Accept = "application/pdf";
      let api = `/api/${invoice}/export/${item[0].id}`;
      axios({
        method: "GET",
        url: api,
        headers: {
          Accept: Accept,
        },
        responseType: "blob",
      })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          a.setAttribute("download", `deliverynotes.pdf`);
          document.body.appendChild(a);
          a.href = url;
          a.click();
        })
        .catch((error) => {
          console.log("er =", error);
          this.props.dispatch(
            notifyError("somethingWentWrong")
          );
        });
    };

    openImportTable = (item) => {
      this.setState({
        showImportedInvoiceError: true,
        importedInvoiceData: item,
      });
    };

    onCloseImportedModal = () => {
      this.setState({ showImportedInvoiceError: false });
    };

    render() {
      const endMessage =
        this.state.itemPagination && this.state.itemPagination.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            -- {this.props.translate("end")} --
          </p>
        ) : (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {this.props.translate("notFound")}
          </p>
        );
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            modeEmpty={this.modeEmpty}
            toggleFilterForm={this.toggleFilterForm}
            submitSideBarSearch={this.submitSideBarSearch}
            downloadInvoice={this.downloadInvoice}
            {...this.state}
          >
            <div className="main-content-page">
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                }}
              >
                {
                  <Grid container style={{ height: "100%" }}>
                    <Grid item sm={12} xs={12}>
                      {this.state.isDataRecieved ? (
                        <Table
                          rows={
                            (this.state.itemPagination &&
                              this.state.itemPagination.items) ||
                            []
                          }
                          pagination={this.setPagination(
                            this.state.itemPagination && this.state.itemPagination
                          )}
                          handleChangeRowsPerPage={(n) =>
                            this.handleChangeRowsPerPage(n, "deliverynotes")
                          }
                          handleChangePage={(n) =>
                            this.handleChangePage(n, "deliverynotes")
                          }
                          // deliveryNoteNumber
                          // deliveryNoteRefNumber
                          SearchItem={(n) => this.SearchItem(n, "deliveryNoteNumber")}
                          SearchItem1={(n) => this.SearchItem(n, "deliveryNoteRefNumber")}
                          SearchItem2={(n) => this.SearchItem(n, "plateNumber")}
                          SearchItem3={(n) => this.SearchItem(n, "stationCity")}
                          logInUser={this.props.logInUser}
                          themecolors={this.props.themecolors}
                          translate={this.props.translate}
                          vehicles={this.props?.vehicles || []}
                          viewInvoice
                          deliveryNotesLabel
                          searchable
                          searchableDN
                          invoiceModule
                          ServerSetting={this.props.ServerSetting}
                          invoiceRow
                          downloadInvoicePdf={this.downloadInvoicePdf}
                          createButton={
                            <>
                              <div style={{ marginLeft: 10 }}>
                                <Button 
                                onClick={()=>this.fetchData(this.props.logInUser,
                                                              this.state.currentPage,
                                                              this.state.pagesize)}
                                                  >
                                  {this.props.translate('sharedSearch')}
                                </Button>
                              </div>
                              </>
                          }
                          isDownload={
                            <ExportImportMenu
                              fileName="deliverynotes"
                              checkPrivilege
                              isInvoices
                              data={[]}
                              themecolors={this.props.themecolors}
                              fetchData={this.fetchData}
                              openImportTable={this.openImportTable}
                            />
                          }
                          rowDefinition={
                            this.props.ServerSetting.contractType !== 1
                              ? [
                                {
                                  id: "deliveryNoteNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("deliveryNoteNo"),
                                },
                                {
                                  id: "deliveryNoteRefNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("deliveryNoteRefNumber"),
                                },
                                {
                                  id: "serviceId",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("bookingID"),
                                },
                                {
                                  id: "workOrderNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("workOrderNumber"),
                                },
                                {
                                  id: "serviceRefNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("customerRefNum"),
                                },
                                {
                                  id: "plateNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("vehicleLicensePlate"),
                                },
                                {
                                  id: "serviceDate",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("deliverynoteDate"),
                                },
                                {
                                  id: "saleTotalAmount",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("amount"),
                                },
                                {
                                  id: "saleVATAmount",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("VAT Amount"),
                                },
                                {
                                  id: "saleNetAmount",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("totalAmount"),
                                },
                                {
                                  id: "valid",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("status"),
                                  hide: checkPrivileges("invoiceInvalidView")
                                    ? false
                                    : true,
                                },
                                {
                                  id: "viewDeliveryNote",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("viewDeliveryNote"),
                                  hide: false,
                                },
                                                                 ]
                              : [
                                {
                                  id: "deliveryNoteNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("deliveryNoteNo"),
                                },
                                {
                                  id: "deliveryNoteRefNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("deliveryNoteRefNumber"),
                                },
                                {
                                  id: "serviceId",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("bookingID"),
                                },
                                {
                                  id: "workOrderNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("showworkOrderNumber"),
                                },
                                {
                                  id: "serviceRefNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("customerRefNum"),
                                },
                                {
                                  id: "plateNumber",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("vehicleLicensePlate"),
                                },
                                {
                                  id: "serviceDate",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("deliverynoteDate"),
                                },
                                {
                                  id: "saleTotalAmount",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("amount"),
                                },
                                {
                                  id: "saleVATAmount",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("VAT Amount"),
                                },
                                {
                                  id: "saleNetAmount",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("totalAmount"),
                                },
                                {
                                  id: "valid",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("status"),
                                  hide: checkPrivileges("invoiceInvalidView")
                                    ? false
                                    : true,
                                },
                                {
                                  id: "viewDeliveryNote",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("viewDeliveryNote"),
                                  hide: false,
                                },
                        ]
                          }
                        />
                      ) : (
                        <Loader />
                      )}
                    </Grid>
                  </Grid>
                }
                {/* {this.state.openCreateForm ?(
                  <>
                    <CreateInvoice
                        invoiceForm={this.state.invoiceForm}
                        Package={this.state.Package}
                        themecolors={this.props.themecolors}
                        translate={this.props.translate}
                        logInUser={this.props.logInUser}
                        closeCreateFrom={this.closeCreateFrom}
                        handleChange={this.handleChange}
                        handlePackChange={this.handlePackChange}
                        addMore={this.addMore}
                        deleteRow={this.deleteRow}
                        handleChangeInvoice={this.handleChangeInvoice}
                      />
                  </>
                ) :
                null}  */}
              </div>
            </div>
            {this.state.showImportedInvoiceError && (
              <CustomDialog
                title="Imported Delivery Notes"
                visable={true}
                onClose={this.onCloseImportedModal}
                bodyPadding={10}
                hideDragIcon
                fullWidth
                isVisableBtn
                noFullScreen
              >
                <div>
                  <table>
                    <tr
                      style={{
                        borderBottom: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <td>
                        <strong>Delivery Note no</strong>
                      </td>
                      <td>
                        <strong>Error</strong>
                      </td>
                    </tr>
                    {Object.entries(this.state.importedInvoiceData).map(
                      (item, i) => {
                        return (
                          <tr
                            style={{
                              borderBottom: "1px solid black",
                              paddingTop: 2,
                            }}
                          >
                            <td style={{ padding: 10 }}>
                              <strong>{item[0]}</strong>
                            </td>
                            <td style={{ padding: 10 }}>{item[1]}</td>
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div>
              </CustomDialog>
            )}
          </Layout>
        </div>
      );
    }
  }

  const mapStateToProps = (state) => ({
    themecolors: state.themeColors,
    logInUser: state.logInUsers,
    ServerSetting: state.ServerSetting,
    vehicles: state.vehicles,
  });

  export default connect(mapStateToProps)(withTranslationWrapper(Invoice))
